import React, { useEffect } from "react"
import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Progress } from "reactstrap";
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

const UiProgressBar = (props) => {
    const breadcrumbItems = [
        {
            title: 'Progress',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Progress', breadcrumbItems)
    })
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Ui" />
                        : null}
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <CardTitle className="mb-2">Default Examples</CardTitle>
                                    <CardSubtitle className="mb-2">Progress components are built with two
                                            HTML elements, some CSS to set the width, and a few attributes.</CardSubtitle>

                                    <div>
                                        <div className="mb-30">
                                            <Progress color="primary" value={25} />
                                        </div>
                                        {" "}
                                        <br />
                                        <div className="mb-30">
                                            <Progress color="primary" value={50} />
                                        </div>
                                        <br />
                                        <div className="mb-30">
                                            <Progress color="primary" value={75} />
                                        </div>
                                        <br />
                                        <div>
                                            <Progress color="primary" value={100} />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <CardTitle className="mb-2">Backgrounds</CardTitle>
                                    <CardSubtitle className="mb-3">Use background utility classes to
                                            change the appearance of individual progress bars.</CardSubtitle>

                                    <div>
                                        <div className="mb-30">
                                            <Progress color="success" value={25} />
                                        </div>
                                        <br />
                                        <div className="mb-30">
                                            <Progress color="info" value={50} />
                                        </div>
                                        <br />
                                        <div className="mb-30">
                                            <Progress color="warning" value={75} />
                                        </div>
                                        <br />
                                        <div>
                                            <Progress color="danger" value={100} />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <CardTitle className="mb-2">Labels Example</CardTitle>
                                    <CardSubtitle className="mb-3">Add labels to your progress bars by placing text within the <code className="highlighter-rouge">.progress-bar</code>.</CardSubtitle>

                                    <div className="">
                                        <Progress color="primary" value={25}>
                                            25%
                                        </Progress>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <CardTitle className="mb-2">Multiple bars</CardTitle>
                                    <CardSubtitle className="mb-3">Include multiple progress bars in a progress component if you need.</CardSubtitle>

                                    <div className="">
                                        <Progress multi>
                                            <Progress bar color="primary" value={15} />
                                            <Progress bar color="success" value={30} />
                                            <Progress bar color="info" value={20} />
                                        </Progress>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <CardTitle className="mb-2">Height</CardTitle>
                                    <CardSubtitle className="mb-3">We only set a <code
                                        className="highlighter-rouge">height</code> value on the <code
                                            className="highlighter-rouge">.progress-bar</code>, so if you change
                                            that value the outer <code className="highlighter-rouge">.progress</code>
                                            will automatically resize accordingly.</CardSubtitle>

                                    <div className="">
                                        <div className="progress mb-2" style={{ height: '2px' }}>
                                            <Progress color="primary" value={100} style={{ width: '25%' }} />
                                        </div>
                                        <div className="progress" style={{ height: '20px' }}>
                                            <div className="progress-bar" role="progressbar" style={{ width: '25%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <CardTitle className="mb-2">Animated stripes</CardTitle>
                                    <CardSubtitle className="mb-3">The striped gradient can also be
                                            animated. Add <code
                                            className="highlighter-rouge">.progress-bar-animated</code> to <code
                                                className="highlighter-rouge">.progress-bar</code> to animate the
                                            stripes right to left via CSS3 animations.</CardSubtitle>

                                    <div className="">
                                        <Progress striped animated color="primary" value="80" />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <CardTitle className="mb-2">Striped</CardTitle>
                                    <CardSubtitle className="mb-3">Add <code className="highlighter-rouge">.progress-bar-striped </code>
                                            to any <code className="highlighter-rouge">.progress-bar</code> to apply a
                                            stripe via CSS gradient over the progress bar’s background color.</CardSubtitle>

                                    <div className="">
                                        <div className="mb-30">
                                            <Progress striped color="primary" value={10} />
                                        </div>
                                        <br />
                                        <div className="mb-30">
                                            <Progress striped color="success" value={25} />
                                        </div>
                                        <br />
                                        <div className="mb-30">
                                            <Progress striped color="info" value={50} />
                                        </div>
                                        <br />
                                        <div className="mb-30">
                                            <Progress striped color="warning" value={75} />
                                        </div>
                                        <br />
                                        <div>
                                            <Progress striped color="danger" value={100} />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>




                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(UiProgressBar);