import React , {useEffect} from "react"
import { connect } from "react-redux";

import { setBreadcrumbItems } from '../../store/actions';

//logo
import logo from '../../assets/images/logo.png';

const PageMaintenance = props => {
     ///breadcrumbItems

   const breadcrumbItems = [
    {
      title: 'Maintenance',
      link: '#'
    }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Maintenance', breadcrumbItems)
  })

    return (
        <React.Fragment>
            <section className="my-5 pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="home-wrapper">
                                <img src={logo} alt="logo" height="30" />
                                <h3 className="mt-4">Site is Under Maintenance</h3>
                                <p>Please check back in sometime.</p>

                                <div className="row">
                                    <div className="text-center col-md-4">
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <i className="mdi mdi-airplane-takeoff font-size-32 mb-3"></i>
                                                <h6 className="text-uppercase mt-3">Why is the Site Down?</h6>
                                                <p className="text-muted mt-3">There are many variations of passages of
                                                Lorem Ipsum available, but the majority have suffered alteration.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center col-md-4">
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <i className="mdi mdi-clock-alert font-size-32 mb-3"></i>
                                                <h6 className="text-uppercase mt-3">
                                                    What is the Downtime?</h6>
                                                <p className="text-muted mt-3">Contrary to popular belief, Lorem Ipsum is not
                                                simply random text. It has roots in a piece of classical.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center col-md-4">
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <i className="mdi mdi-email font-size-32 mb-3"></i>
                                                <h6 className="text-uppercase mt-3">
                                                    Do you need Support?</h6>
                                                <p className="text-muted mt-3">If you are going to use a passage of Lorem
                                                Ipsum, you need to be sure there isn't anything embar.. <a
                                                        href="mailto:no-reply@domain.com"
                                                        className="text-decoration-underline">no-reply@domain.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(PageMaintenance);