import React from "react"
import { Redirect } from "react-router-dom"

//Dashboard Pages
import Dashboard from "../Pages/Dashboard/index"
import Dashboard2 from '../Pages/Dashboard2/index';

// Profile
import UserProfile from "../Pages/Authentication/user-profile"


//widgets 
import Widgets from "../Pages/Widgets";

//Email
import EmailInbox from '../Pages/Email/EmailInbox';
import EmailRead from '../Pages/Email/EmailRead';
import EmailCompose from '../Pages/Email/EmailCompose';

//Calender
import Calendar from '../Pages/Calendar/index';

// UI pages
import UiButton from '../Pages/Ui/UiButton';
import UiColor from '../Pages/Ui/UiColor';
import UiCard from '../Pages/Ui/UiCard';
import UiTabs from '../Pages/Ui/UiTabs';
import UiModal from "../Pages/Ui/UiModal";
import UiImages from "../Pages/Ui/UiImages";
import UiAlerts from "../Pages/Ui/UiAlerts";
import UiProgressBar from "../Pages/Ui/UIProgressBar";
import UiDropdowns from "../Pages/Ui/UiDropdowns";
import UiLightbox from "../Pages/Ui/UiLightbox";
import UiNavs from "../Pages/Ui/UiNavs";
import UiPagination from '../Pages/Ui/UiPagination';
import UiPopoverTooltips from "../Pages/Ui/UiPopoverTooltips";
import UiBadge from "../Pages/Ui/UiBadge";
import UiCarousel from "../Pages/Ui/UiCarousel";
import UiVideo from '../Pages/Ui/UiVideo';
import UiTypography from '../Pages/Ui/UiTypography';
import UiSweetAlert from "../Pages/Ui/UiSweetAlert";
import UiGrid from "../Pages/Ui/UiGrid";
import UiAnimation from "../Pages/Ui/UiAnimation";
import UiHighlight from "../Pages/Ui/UiHighlight";
import UiRating from "../Pages/Ui/UiRating";
import UiNestable from "../Pages/Ui/UiNestable";
import UiRangeSlider from "../Pages/Ui/UiRangeSlider";
import UiSessionTimeout from "../Pages/Ui/UiSessionTimeout";

//Form pages
import FormElements from "../Pages/Forms/FormElements";
import FormValidations from "../Pages/Forms/FormValidation";
import FormAdvanced from "../Pages/Forms/FormAdvanced";
import FormWizard from "../Pages/Forms/FormWizard";
import FormEditors from "../Pages/Forms/FormEditor";
import FormUpload from "../Pages/Forms/FileUploads";
import FormMask from "../Pages/Forms/FormMask";
import FormXeditable from "../Pages/Forms/FormXeditable";

// Charts
import ChartApex from "../Pages/Charts/Apexcharts"
import ChartistChart from "../Pages/Charts/ChartistChart"
import ChartjsChart from "../Pages/Charts/ChartjsChart"
import EChart from "../Pages/Charts/EChart"
import SparklineChart from "../Pages/Charts/SparklineChart"
import ToastUIChart from "../Pages/Charts/ToastUIChart"
import ChartsKnob from "../Pages/Charts/charts-knob"

//Tables
import BasicTables from "../Pages/Tables/BasicTables"
import DatatableTables from "../Pages/Tables/DatatableTables"
import ResponsiveTables from "../Pages/Tables/ResponsiveTables"
import EditableTables from "../Pages/Tables/EditableTables"

//Icons
import IconIon from "../Pages/Icons/IconIon";
import IconMobirise from "../Pages/Icons/IconMobirise";
import IconThemify from "../Pages/Icons/IconThemify";
import IconDripicons from "../Pages/Icons/IconDripicons"
import IconTypicons from "../Pages/Icons/IconTypicons";
import IconMaterialdesign from "../Pages/Icons/IconMaterialdesign"
import IconWeather from "../Pages/Icons/IconWeather";
import IconFontawesome from "../Pages/Icons/IconFontawesome"

// Maps
import MapsGoogle from "../Pages/Maps/MapsGoogle"
import MapsVector from "../Pages/Maps/MapsVector"

//Extra pages
import Timeline from '../Pages/ExtraPages/Timeline';
import Invoice from "../Pages/ExtraPages/Invoice";
import Users from "../Pages/ExtraPages/User";
import Starter from "../Pages/ExtraPages/Starter";
import Pages404 from "../Pages/ExtraPages/Pages404";
import Page500 from "../Pages/ExtraPages/Page500";
import PagePricing from "../Pages/ExtraPages/PagePricing";
import Gallery from "../Pages/ExtraPages/Gallery";
import PageMaintenance from "../Pages/ExtraPages/PageMaintenance";
import PageComingSoon from "../Pages/ExtraPages/PageComingSoon";

//Email Template pages
import BasicAction from "../Pages/EmailTemplate/BasicAction";
import AlertEmail from "../Pages/EmailTemplate/AlertEmail";
import BillingEmail from "../Pages/EmailTemplate/BillingEmail";

//FAQ
import Faq from "../Pages/Faq";

//Contact
import Contact from "../Pages/Contact";

//login pages
import Login1 from "../Pages/AuthenticationInner/Login";
import Register1 from "../Pages/AuthenticationInner/Register";
import Recoverpw1 from "../Pages/AuthenticationInner/Recoverpw";
import LockScreen1 from "../Pages/AuthenticationInner/LockScreen";
import Login2 from "../Pages/AuthenticationInner/Login2";
import Recoverpw2 from "../Pages/AuthenticationInner/Recoverpw2";
import Register2 from "../Pages/AuthenticationInner/Register2";
import LockScreen2 from "../Pages/AuthenticationInner/LockScreen2";

// Authentication related pages
import Login from "../Pages/Authentication/Login"
import Logout from "../Pages/Authentication/Logout"
import Register from "../Pages/Authentication/Register"
import ForgetPwd from "../Pages/Authentication/ForgetPassword"

// //Ecommerce Pages
import ProductList from "../Pages/Ecommerce/ProductList"
import ProductGrid from "../Pages/Ecommerce/ProductGrid"
import EcommerceOrders from "../Pages/Ecommerce/EcommerceOrders/index"
import EcommerceCustomers from "../Pages/Ecommerce/EcommerceCustomers/index"
import EcommerceAddProduct from "../Pages/Ecommerce/EcommerceAddProduct"

const userRoutes = [

  // this route should be at the end of all other routes
  { path: "/dashboard", component: Dashboard },
  { path: '/dashboard-2', component: Dashboard2 },

  //widgets
  { path: '/widgets', component: Widgets },

  ///Email 
  { path: '/email-inbox', component: EmailInbox },
  { path: '/email-read', component: EmailRead },
  { path: '/email-compose', component: EmailCompose },

  //Calender
  { path: '/calendar', component: Calendar },

  // //profile
  { path: "/profile", component: UserProfile },

  // Ui pages
  { path: '/ui-button', component: UiButton },
  { path: '/ui-color', component: UiColor },
  { path: '/ui-card', component: UiCard },
  { path: '/ui-tabs-accordions', component: UiTabs },
  { path: '/ui-modal', component: UiModal },
  { path: '/ui-images', component: UiImages },
  { path: '/ui-alert', component: UiAlerts },
  { path: '/ui-progressbars', component: UiProgressBar },
  { path: '/ui-dropdowns', component: UiDropdowns },
  { path: '/ui-lightbox', component: UiLightbox },
  { path: '/ui-navs', component: UiNavs },
  { path: '/ui-pagination', component: UiPagination },
  { path: '/ui-popover-tooltips', component: UiPopoverTooltips },
  { path: '/ui-badge', component: UiBadge },
  { path: '/ui-carousel', component: UiCarousel },
  { path: '/ui-video', component: UiVideo },
  { path: '/ui-typography', component: UiTypography },
  { path: '/ui-sweet-alert', component: UiSweetAlert },
  { path: '/ui-grid', component: UiGrid },
  { path: '/ui-animation', component: UiAnimation },
  { path: '/ui-highlight', component: UiHighlight },
  { path: '/ui-rating', component: UiRating },
  { path: '/ui-nestable', component: UiNestable },
  { path: '/ui-rangeslider', component: UiRangeSlider },
  { path: '/ui-sessiontimeout', component: UiSessionTimeout },

  //Form pages
  { path: '/form-elements', component: FormElements },
  { path: '/form-validation', component: FormValidations },
  { path: '/form-advanced', component: FormAdvanced },
  { path: '/form-wizard', component: FormWizard },
  { path: '/form-editor', component: FormEditors },
  { path: '/form-uploads', component: FormUpload },
  { path: '/form-mask', component: FormMask },
  { path: '/form-xeditable', component: FormXeditable },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/tui-charts", component: ToastUIChart },
  { path: "/charts-knob", component: ChartsKnob },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Icons
  { path: '/icons-ion', component: IconIon },
  { path: '/icon-themify', component: IconThemify },
  { path: '/icon-typicons', component: IconTypicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: '/icon-weather', component: IconWeather },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: '/icon-mobirise', component: IconMobirise },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },

  //Extra pages
  { path: '/page-timeline', component: Timeline },
  { path: '/page-invoie', component: Invoice },
  // { page: '/page-shweta', component: PageDirectory },
  { path: '/page-directory', component: Users },
  { path: '/page-blank', component: Starter },
  { path: '/page-404', component: Pages404 },
  { path: '/page-500', component: Page500 },
  { path: '/page-pricing', component: PagePricing },
  { path: '/page-gallery', component: Gallery },


  //Ecommerce Page
  { path: '/product-list', component: ProductList },
  { path: '/product-grid', component: ProductGrid },
  { path: "/ecommerce-orders", component: EcommerceOrders },
  { path: "/ecommerce-customers", component: EcommerceCustomers },
  { path: "/ecommerce-add-product", component: EcommerceAddProduct },

  //Email Template pages
  { path: '/email-templates-basic', component: BasicAction },
  { path: '/email-templates-alert', component: AlertEmail },
  { path: '/email-templates-billing', component: BillingEmail },

  //FAQ
  { path: '/faq', component: Faq },

  //Contact
  { path: '/contact', component: Contact },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  //Authentication pages
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  //Extra pages
  { path: '/page-maintenance', component: PageMaintenance },
  { path: '/page-coming-soon', component: PageComingSoon },

  //Authentication Inner pages
  { path: '/page-login', component: Login1 },
  { path: '/page-register', component: Register1 },
  { path: '/page-recoverpw', component: Recoverpw1 },
  { path: '/page-lock-screen', component: LockScreen1 },
  { path: '/page-login-2', component: Login2 },
  { path: '/page-register-2', component: Register2 },
  { path: '/page-recoverpw-2', component: Recoverpw2 },
  { path: '/page-lock-screen-2', component: LockScreen2 }
]

export { userRoutes, authRoutes }