import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

import Activity from './Activity';

//import Compoenent
import EmailSent from './EmailSent';
import LatestMessages from './LatestMessages';
import MonthlyEarning from './MonthlyEarning';
import MonthlyEarning2 from './MonthlyEarning2';
import SocialList from './SocialList';
import Widgets from './Widgets';

const Dashboard2 = props => {

    const breadcrumbItems = [
        {
            title: 'Dashboard 2',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Dashboard 2', breadcrumbItems)
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Dashboards" />
                        : null}

                    <Widgets />
                    <Row>
                        <MonthlyEarning />
                        <EmailSent />
                        <MonthlyEarning2 />
                    </Row>

                    <Row>
                        <LatestMessages />
                        <Activity />
                        <SocialList />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(Dashboard2);
