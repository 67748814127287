import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Form, Label, Input } from 'reactstrap';

import { connect } from "react-redux";
import { setBreadcrumbItems } from '../../store/actions';

import BackGround from '../../assets/images/bg.jpg';
import logo from '../../assets/images/logo.png';

function Login(props) {
    ///breadcrumbItems

    const breadcrumbItems = [
        {
            title: 'Login',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Login', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="accountbg" style={{ background: `url(${BackGround}) center` }}></div>

            <div className="account-pages mt-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={5} xl={4}>
                            <Card>
                                <CardBody>
                                    <div className="text-center mt-4">
                                        <div className="mb-3">
                                            <a href="index.html"><img
                                                src={logo}
                                                height="30" alt="logo" /></a>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <h4 className="font-size-18 mt-2 text-center">Welcome
                                        Back !</h4>
                                        <p className="text-muted text-center mb-4">Sign
                                        in to continue to Admiria.</p>

                                        <Form className="form-horizontal"
                                            action="index.html">

                                            <div className="mb-3">
                                                <Label className="form-label"
                                                    htmlFor="username">Username</Label>
                                                <Input type="text"
                                                    className="form-control"
                                                    id="username" placeholder="Enter
                                                username" />
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label"
                                                    htmlFor="userpassword">Password</Label>
                                                <Input type="password"
                                                    className="form-control"
                                                    id="userpassword"
                                                    placeholder="Enter password" />
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-sm-6">
                                                    <div className="form-check">
                                                        <Input
                                                            className="form-check-input"
                                                            type="checkbox" value=""
                                                            id="customControlInline" />
                                                        <Label
                                                            className="form-check-label"
                                                            htmlFor="customControlInline">
                                                            Remember me
                                                    </Label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 text-end">
                                                    <button className="btn btn-primary
                                                    w-md waves-effect
                                                    waves-light" type="submit">Log
                                                    In</button>
                                                </div>
                                            </div>

                                            <div className="mb-0 row">
                                                <div className="col-12 mt-4">
                                                    <a href="pages-recoverpw.html"
                                                        className="text-muted"><i
                                                            className="mdi mdi-lock"></i>
                                                    Forgot your password?</a>
                                                </div>
                                            </div>
                                        </Form>

                                    </div>

                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center position-relative">
                                <p className="text-white">Don't have an account ? <a
                                    href="pages-register.html" className="fw-bold
                                    text-primary"> Signup Now </a> </p>
                                <p className="text-white">2017 - {new Date().getFullYear()}
                                 © Admiria. Crafted with <i className="mdi mdi-heart
                                    text-danger"></i> by Themesbrand</p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(Login);