import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { setBreadcrumbItems } from '../../store/actions';

import BackGround from '../../assets/images/bg-2.jpg';
import logo from '../../assets/images/logo.png';

const Register2 = props => {
    ///breadcrumbItems

    const breadcrumbItems = [
        {
            title: 'Register 2',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Register 2', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="accountbg" style={{ background: `url(${BackGround}) center`, backgroundPosition: 'cover' }}></div>

            <div className="wrapper-page account-page-full">

                <div className="card shadow-none">
                    <div className="card-block">

                        <div className="account-box">

                            <div className="card-box shadow-none p-4">
                                <div className="p-2">
                                    <div className="text-center mt-4">
                                        <Link to="index.html"><img src={logo} height="30" alt="logo" /></Link>
                                    </div>

                                    <h4 className="font-size-18 mt-5 text-center">Free Register</h4>
                                    <p className="text-muted text-center">Get your free Admiria account now.</p>

                                    <form className="mt-4" action="#">

                                        <div className="mb-3">
                                            <label className="form-label" for="useremail">Email</label>
                                            <input type="email" className="form-control" id="useremail" placeholder="Enter email" />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" for="username">Username</label>
                                            <input type="text" className="form-control" id="username" placeholder="Enter username" />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" for="userpassword">Password</label>
                                            <input type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                        </div>

                                        <div className="mb-3">
                                            <div className="text-end">
                                                <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Register</button>
                                            </div>
                                        </div>

                                        <div className="mt-2 mb-0 row">
                                            <div className="col-12 mt-3">
                                                <p className="font-size-14 text-muted mb-0">By registering you agree to the Admiria <Link to="#">Terms of Use</Link></p>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="mt-5 pt-4 text-center position-relative">
                                        <p>Already have an account ? <Link to="/pages-login-2" className="fw-medium text-primary"> Login </Link> </p>
                                        <p>2017 - {new Date().getFullYear()} © Admiria. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(Register2);