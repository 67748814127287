import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';

import { connect } from "react-redux";
import { setBreadcrumbItems } from '../../store/actions';

import BackGround from '../../assets/images/bg-2.jpg';
import logo from '../../assets/images/logo.png';

const Recoverpw2 = props => {
    ///breadcrumbItems

    const breadcrumbItems = [
        {
            title: 'Recover Password 2',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Recover Password 2', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="accountbg" style={{ background: `url(${BackGround}) center`, backgroundPosition: 'cover' }}></div>

            <div className="wrapper-page account-page-full">

                <Card className="shadow-none">
                    <div className="card-block">

                        <div className="account-box">

                            <div className="card-box shadow-none p-4">
                                <div className="p-2">
                                    <div className="text-center mt-4">
                                        <Link to="dashboard"><img src={logo} height="30" alt="logo" /></Link>
                                    </div>

                                    <h4 className="font-size-18 mt-5 text-center">Reset Password</h4>
                                    <p className="text-muted text-center">Enter your Email and instructions will be sent to you!</p>

                                    <form className="mt-4" action="#">

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="useremail">Email</label>
                                            <input type="email" className="form-control" id="useremail" placeholder="Enter email" />
                                        </div>

                                        <div className="mb-3">
                                            <div className="text-end">
                                                <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Reset</button>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="mt-5 pt-4 text-center">
                                        <p>Remember It ?<Link to="/pagea-login-2" className="fw-medium text-primary"> Sign In Here </Link> </p>
                                        <p>2017 - {new Date().getFullYear()} © Admiria. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </Card>

            </div>

        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(Recoverpw2);