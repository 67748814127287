import React, { useEffect } from "react"
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const Page500 = props => {
    ///breadcrumbItems

    const breadcrumbItems = [
        {
            title: 'Error 500',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Error 500', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Extra Pages" />
                        : null}

                    <Row>
                        <Col md={6} className="offset-md-3 mx-auto">
                            <Card>
                                <CardBody>
                                    <div className="ex-page-content text-center">
                                        <h1 className="">500</h1>
                                        <h3 className="">Internal Server Error</h3><br />
                                        <Link className="btn btn-primary mb-5 waves-effect waves-light" to="/dashboard">Back to Dashboard</Link>
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(Page500);