import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

//import images
import user1 from '../../assets/images/users/avatar-2.jpg';
import user2 from '../../assets/images/users/avatar-3.jpg';

const SocialList = props => {
    return (
        <React.Fragment>
            <Col xl={4}>
                <Card>
                    <CardBody>
                        <div className="d-flex">
                            <img className="me-3 rounded-circle avatar-lg img-thumbnail thumb-lg" src={user1} alt="" />
                            <div className="">
                                <h5 className="mt-0 font-size-18 mb-1">Pauline I. Bird</h5>
                                <p className="text-muted font-size-14">Webdeveloper</p>

                                <ul className="social-links list-inline mb-0" id="tooltip-container">
                                    <li className="list-inline-item">
                                        <Link to="#" title="Facebook"><i className="fab fa-facebook-f"></i></Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#" title="Twitter"><i className="fab fa-twitter"></i></Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#" title="1234567890"><i className="fas fa-phone"></i></Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#" title="@skypename"><i className="fab fa-skype"></i></Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </CardBody>
                </Card>

                <Card>
                    <CardBody className="text-center">
                        <div className="text-danger"><span className="mdi mdi-heart mdi-36px"></span></div>
                        <h5 className="font-light">You have a live Order !!</h5>
                        <p className="text-muted font-size-14 mb-3">Align terms and descriptions horizontally by using our
                        grid system’s predefined classes (or semantic mixins).
                                            an ellipsis.</p>
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>

                        <div className="d-flex">
                            <img className="me-3 rounded-circle avatar-lg img-thumbnail thumb-lg" src={user2} alt="" />
                            <div className="">
                                <h5 className="mt-0 font-size-18 mb-1">Ralph L. Alva</h5>
                                <p className="text-muted font-size-14">Webdeveloper</p>

                                <ul className="social-links list-inline mb-0" id="tooltip-container2">
                                    <li className="list-inline-item">
                                        <Link to="#" title="Facebook"><i className="fab fa-facebook-f"></i></Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#" title="Twitter"><i className="fab fa-twitter"></i></Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#" title="1234567890"><i className="fas fa-phone"></i></Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="#" title="@skypename"><i className="fab fa-skype"></i></Link>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default SocialList;