import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import DonutChart2 from './AllCharts/DonutChart2';
import Knob from 'react-canvas-knob';
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../store/actions';
import Breadcrumb from '../../src/common/Breadcrumb';

///import component
import WidgetsDashboard from './Dashboard2/Widgets';

//import images
import avtar from '../assets/images/users/avatar-2.jpg';
import { Link } from 'react-router-dom';

const Widgets = props => {

    const breadcrumbItems = [
        {
            title: 'Widgets',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Widgets', breadcrumbItems)
    });

    const widgets1 = [
        {
            sales: "Total Sales",
            iconClass: "mdi mdi-cart-outline",
            iconColor: 'primary',
            description: "15852",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
        {
            sales: "New Orders",
            iconClass: "mdi mdi-currency-usd",
            iconColor: 'success',
            description: "956",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
        {
            sales: "New Users",
            iconClass: "mdi mdi-cube-outline",
            iconColor: 'warning',
            description: "5210",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
        {
            sales: "Unique Visitors",
            iconClass: "mdi mdi-currency-btc",
            iconColor: 'pink',
            description: "20544",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
    ]
    const widgets2 = [
        {
            sales: "Total Sales",
            iconClass: "mdi mdi-basket",
            iconColor: 'bg-purple',
            description: "25140",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
        {
            sales: "New Orders",
            iconClass: "mdi mdi-black-mesa",
            iconColor: 'bg-blue-grey',
            description: "65241",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
        {
            sales: "New Users",
            iconClass: "mdi mdi-buffer",
            iconColor: 'bg-brown',
            description: "85412",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
        {
            sales: "Unique Visitors",
            iconClass: "mdi mdi-coffee",
            iconColor: 'bg-teal',
            description: "20544",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
    ]
    const widgets3 = [
        {
            sales: "362410",
            iconClass: "ti-truck",
            iconColor: 'pink',
        },
        {
            sales: "89510",
            iconClass: "ti-comments",
            iconColor: 'orange',
        },
        {
            sales: "55620",
            iconClass: "ti-briefcase",
            iconColor: 'indigo',
        },
        {
            sales: "320101",
            iconClass: "ti-wallet",
            iconColor: 'brown',
        },
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid className="container-fluid">

                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Component" />
                        : null}

                    <Row>
                        {widgets1.map((widget, key) => (
                            <Col md={6} xl={3} key={key}>
                                <Card>
                                    <CardBody>
                                        <div className="mini-stat clearfix">
                                            <span className={"mini-stat-icon bg-" + widget.iconColor + " float-start"}><i className={widget.iconClass}></i></span>
                                            <div className="mini-stat-info text-end">
                                                <span className={"counter text-" + widget.iconColor}>{widget.description}</span>
                                                {widget.sales}
                                            </div>
                                            <div className="clearfix"></div>
                                            <p className="text-muted mb-0 mt-4">Total income: $22506 <span className="float-end"><i className="fa fa-caret-up me-1"></i>10.25%</span></p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        {widgets2.map((widget, key) => (
                            <Col md={6} xl={3} key={key}>
                                <Card>
                                    <CardBody>
                                        <div className="mini-stat">
                                            <span className={"mini-stat-icon " + widget.iconColor + " me-0 float-end"}><i className={widget.iconClass}></i></span>
                                            <div className="mini-stat-info">
                                                <span className="counter text-purple">{widget.description}</span>
                                                {widget.sales}
                                            </div>
                                            <p className=" mb-0 mt-4 text-muted">Total income: {widget.totalIncome} <span className="float-end"><i className="fa fa-caret-up me-1"></i>{widget.percentage}</span></p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}


                    </Row>
                    {/* {render the widgets dashboard 2} */}
                    <WidgetsDashboard />

                    <Row>
                        {widgets3.map((widget, key) => (
                            <Col md={6} xl={3} key={key}>
                                <Card>
                                    <CardBody>
                                        <div className="mini-stat clearfix">
                                            <span className={"font-size-40 text-" + widget.iconColor + " me-0 float-end"}><i className={widget.iconClass}></i></span>
                                            <div className="mini-stat-info">
                                                <h3 className="counter font-light mt-0">{widget.sales}</h3>
                                            </div>
                                            <div className="clearfix"></div>
                                            <p className="mb-0 mt-4 text-muted">Total income: $56210 <span className="float-end"><i className="fa fa-caret-down me-1"></i>3.25%</span></p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}

                    </Row>
                    <Row>
                        <Col xl={5}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Revenue (This Month)</h4>

                                    <Row>
                                        <Col sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <div className="mini-stat clearfix">
                                                        <div className="mini-stat-info">
                                                            <span className="counter text-purple">25140</span>
                                                            Total Sales
                                                        </div>
                                                        <div className="clearfix"></div>
                                                        <p className=" mb-0 mt-4 text-muted">Total income: $22506 <span className="float-end"><i className="fa fa-caret-up me-1"></i>10.25%</span></p>
                                                    </div>
                                                </CardBody>
                                            </Card>

                                            <Card className="mb-0">
                                                <CardBody>
                                                    <div className="mini-stat clearfix">
                                                        <div className="mini-stat-info">
                                                            <span className="counter text-purple">25140</span>
                                                            Total Sales
                                                        </div>
                                                        <div className="clearfix"></div>
                                                        <p className=" mb-0 mt-4 text-muted">Total income: $22506 <span className="float-end"><i className="fa fa-caret-up me-1"></i>10.25%</span></p>
                                                    </div>
                                                </CardBody>
                                            </Card>

                                        </Col>
                                        <Col sm={6}>
                                            <DonutChart2 />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={4}>
                            <Card>
                                <CardBody>
                                    <div className="profile-widget text-center">
                                        <img src={avtar} className="avatar-lg rounded-circle img-thumbnail" alt="img" />
                                        <h5 className="mt-3">Jonathan Doe</h5>
                                        <p><i className="fa fa-map-marker"></i> London</p>

                                        <p className="text-muted mb-2">Lorem ipsum dolor sit ametetur adipisicing elit,
                                                sed do eiusmod tempor incididunt adipisicing elit.</p>
                                        <Link to="#" className="btn btn-sm btn-purple mt-4">Follow</Link>
                                        <ul className="list-inline row mt-4 clearfix mb-0">
                                            <li className="col-4">
                                                <p className="mb-1 font-size-18 fw-bolder">23514</p>
                                                <p className="text-muted mb-0">Followers</p>
                                            </li>
                                            <li className="col-4">
                                                <p className="mb-1 font-size-18 fw-bolder">2510</p>
                                                <p className="text-muted mb-0">Photos</p>
                                            </li>
                                            <li className="col-4">
                                                <p className="mb-1 font-size-18 fw-bolder">68541</p>
                                                <p className="text-muted mb-0">Like</p>
                                            </li>
                                        </ul>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={3}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Revenue (This Month)</h4>

                                    <div className="text-center" dir="ltr">
                                        <Knob
                                            width={150} height={150} linecap="round"
                                            fgColor="#ffbb44" value={80} skin="tron" angleOffset={180}
                                            readOnly={true} thickness={.1}
                                            onChange={() => null}
                                        />

                                        <div className="clearfix"></div>
                                        <Link to="#" className="btn btn-success mt-4">View All Data</Link>
                                        <ul className="list-inline row mt-5 clearfix mb-0">
                                            <li className="col-6">
                                                <p className="mb-1 font-size-18 fw-bolder">95412</p>
                                                <p className="text-muted mb-0">Followers</p>
                                            </li>
                                            <li className="col-6">
                                                <p className="mb-1 font-size-18 fw-bolder">12030</p>
                                                <p className="text-muted mb-0">Like</p>
                                            </li>
                                        </ul>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
}


const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(Widgets);
