import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ReactApexChart from "react-apexcharts"

const MonthlyEarning = props => {
    const series =  [12, 42, 20]

    const chartOptions = {
        chart: {
          type: "donut",
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#f0f1f4', '#6d60b0', '#009688'],
        labels: ['Marketing', 'Online', 'Offline'],
        legend: {
            show: false
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
    }
  
    return (
        <React.Fragment>
            <Col xl={3}>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Monthly Earnings</h4>

                        <Row className="text-center mt-4">
                            <Col xs={6}>
                                <h5 className="">56241</h5>
                                <p className="text-muted font-size-14">Marketplace</p>
                            </Col>
                            <Col xs={6}>
                                <h5 className="">23651</h5>
                                <p className="text-muted font-size-14">Total Income</p>
                            </Col>
                        </Row>
                        <ReactApexChart options={chartOptions} series={series} type="donut" height={300} />
                      
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default MonthlyEarning;