import React from 'react';
import { Card, Col, CardBody, Table } from 'reactstrap';

//import images
import user1 from '../../assets/images/users/avatar-2.jpg';
import user2 from '../../assets/images/users/avatar-3.jpg';
import user3 from '../../assets/images/users/avatar-4.jpg';
import user4 from '../../assets/images/users/avatar-5.jpg';
import user5 from '../../assets/images/users/avatar-6.jpg';


function LatestTransactions(props) {

    const LatestTransactions = [
        {
            id: 1,
            img: user1,
            name: 'Herbert C. Patton',
            color: 'success',
            confirmMessage: 'Confirm',
            price: '$14,584',
            date: '5/12/2016',
        },
        {
            id: 2,
            img: user2,
            name: 'Mathias N. Klausen',
            color: 'warning',
            confirmMessage: 'Waiting payment',
            price: '$8,541',
            date: '10/11/2016',
        },
        {
            id: 3,
            img: user3,
            name: 'Nikolaj S. Henriksen',
            color: 'success',
            confirmMessage: 'Confirm',
            price: '$954',
            date: '8/11/2016',
        },
        {
            id: 4,
            img: user4,
            name: 'Lasse C. Overgaard',
            color: 'danger',
            confirmMessage: 'Payment expired',
            price: '$44,584',
            date: '7/11/2016',
        },
        {
            id: 5,
            img: user5,
            name: 'Kasper S. Jessen',
            color: 'success',
            confirmMessage: 'Confirm',
            price: '$8,844',
            date: '1/11/2016',
        },
    ]
    return (
        <React.Fragment>
            <Col xl={6}>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Latest Transactions</h4>

                        <div className="table-responsive">
                            <Table className="table-centered table-vertical table-nowrap mb-0">

                                <tbody>
                                    {LatestTransactions.map((latest, key) => (
                                        <tr key={key}>
                                            <td>
                                                <img src={latest.img} alt=""
                                                    className="avatar-sm rounded-circle me-2" />
                                                            {latest.name}
                                            </td>
                                            <td><i className={"mdi mdi-checkbox-blank-circle text-"+ latest.color }></i> Confirm
                                            </td>
                                            <td>
                                                {latest.price}
                                            <p className="m-0 text-muted font-size-14">Amount</p>
                                            </td>
                                            <td>
                                                {latest.date}
                                            <p className="m-0 text-muted font-size-14">Date</p>
                                            </td>
                                            <td>
                                                <button type="button"
                                                    className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                            </td>
                                        </tr>

                                    ))}

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default LatestTransactions;