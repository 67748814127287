import React from 'react';
import c3 from "c3";

function DonutChart(props) {
    React.useEffect(() => {
        c3.generate({
            bindto: '#donut-chart',
            data: {
                columns: [
                    ['Desktops', 78],
                    ['Mobiles', 40],
                    ['Tablets', 25]
                ],
                type: 'donut'
            },
            donut: {
                title: "Sales Analytics",
                width: 30,
                label: {
                    show: false
                }
            },
            color: {
                pattern: ["#5468da", "#4ac18e", "#6d60b0"]
            }
        });
    }, []);
    return (
        <React.Fragment>
            <div id="donut-chart" dir="ltr"></div>
        </React.Fragment>
    );
}

export default DonutChart;