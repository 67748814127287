import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Form, Row } from 'reactstrap';

import { connect } from "react-redux";
import { setBreadcrumbItems } from '../../store/actions';

import BackGround from '../../assets/images/bg.jpg';
import logo from '../../assets/images/logo.png';

const Register = props => {
    ///breadcrumbItems

    const breadcrumbItems = [
        {
            title: 'Register',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Register', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="accountbg" style={{ background: `url(${BackGround}) center`, backgroundPosition: 'cover' }}></div>

            <div className="account-pages mt-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={4}>
                            <Card>
                                <CardBody>
                                    <div className="text-center mt-4">
                                        <div className="mb-3">
                                            <Link to="/dashboard"><img src={logo} height="30" alt="logo" /></Link>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <h4 className="font-size-18 mt-2 text-center">Free Register</h4>
                                        <p className="text-muted text-center mb-4">Get your free Admiria account now.</p>

                                        <Form className="form-horizontal" action="/dashboard">

                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="useremail">Email</label>
                                                <input type="email" className="form-control" id="useremail" placeholder="Enter email" />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="username">Username</label>
                                                <input type="text" className="form-control" id="username" placeholder="Enter username" />
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="userpassword">Password</label>
                                                <input type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                            </div>

                                            <div className="mb-3">
                                                <div className="text-end">
                                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Register</button>
                                                </div>
                                            </div>

                                            <div className="mb-0 row">
                                                <div className="col-12 mt-4">
                                                    <p className=" text-muted mb-0">By registering you agree to the Admiria <Link to="#">Terms of Use</Link></p>
                                                </div>
                                            </div>
                                        </Form>

                                    </div>

                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center position-relative">
                                <p className="text-white">Already have an account ?  <Link to="pages-login.html" className="font-weight-bold text-primary"> Login </Link> </p>
                                <p className="text-white">2017 - {new Date().getFullYear()} © Admiria. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(Register);