import product1 from '../../assets/images/products/1.jpg'
import product2 from '../../assets/images/products/2.jpg'
import product3 from '../../assets/images/products/3.jpg'
import product4 from '../../assets/images/products/4.jpg'
import product5 from '../../assets/images/products/5.jpg'
import product6 from '../../assets/images/products/6.jpg'
import product7 from '../../assets/images/products/7.jpg'
import product8 from '../../assets/images/products/8.jpg'

const productsData = [
  {
    id: 1,
    image: product1,
    name: "Riverston Glass Chair",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "22/05/2017",
    price: 521,
    units: 584,
    progress: 25,
    color: 'danger'
  },
  {
    id: 2,
    image: product2,
    name: "Shine Company Catalina",
    isSale: true,
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "28/03/2017",
    price: 210,
    units: 841,
    progress: 85,
    color: 'success'
  },
  {
    id: 3,
    image: product3,
    name: "Trex Outdoor Furniture Cape",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "	22/05/2017",
    price: 652,
    units: 120,
    progress: 85,
    color: 'danger'
  },
  {
    id: 4,
    image: product4,
    name: "Oasis Bathroom Teak Corner",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "11/05/2017",
    price: 85,
    units: 320,
    progress: 65,
    color: 'info'
  },
  {

    id: 5,
    image: product5,
    name: "Condimentum Posuere",
    isPopular: true,
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "18/04/2017",
    price: 8512,
    units: 201,
    progress: 79,
    color: 'success'
  },
  {
    id: 6,
    image: product6,
    name: "BeoPlay Speaker",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "12/03/2017",
    price: 22,
    units: 5140,
    progress: 40,
    color: 'warning'
  },
  {
    id: 7,
    image: product7,
    name: "Micro Board",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "02/03/2017",
    price: 521,
    units: 321,
    progress: 40,
    color: 'warning'
  },
  {
    id: 8,
    image: product8,
    name: "Riverston Glass Chair",
    isDiscount: true,
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "02/03/2017",
    price: 551,
    units: 421,
    progress: 40,
    color: 'warning'
  },
  {
    id: 9,
    image: product1,
    name: "Travelers Leather Bag",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "22/05/2017",
    price: 521,
    units: 5841,
    progress: 25,
    color: 'warning'
  },
  {
    id: 10,
    image: product2,
    name: "Shine Company Catalina",
    description: "Lorem ipsum dolor sit consec te imperdiet iaculis ipsum..",
    date: "28/03/2017",
    price: 210,
    units: 841,
    progress: 85,
    color: 'warning'
  },
]

const recentProducts = [
  {
    id: 1,
    img: "img7",
    name: "Wirless Headphone",
    link: "",
    rating: 4,
    oldPrice: 240,
    newPrice: 225,
  },
  {
    id: 2,
    img: "img4",
    name: "Phone patterned cases",
    link: "",
    rating: 3,
    oldPrice: 150,
    newPrice: 145,
  },
  {
    id: 3,
    img: "img6",
    name: "Phone Dark Patterned cases",
    link: "",
    rating: 4,
    oldPrice: 138,
    newPrice: 135,
  },
]

const comments = [
  {
    id: 1,
    img: "avatar2",
    name: "Brian",
    description:
      "If several languages coalesce, the grammar of the resulting language.",
    date: "5 hrs ago",
  },
  {
    id: 2,
    img: "avatar4",
    name: "Denver",
    description:
      "To an English person, it will seem like simplified English, as a skeptical Cambridge",
    date: "07 Oct, 2019",
    childComment: [
      {
        id: 1,
        img: "avatar5",
        name: "Henry",
        description:
          "Their separate existence is a myth. For science, music, sport, etc.",
        date: "08 Oct, 2019",
      },
    ],
  },
  {
    id: 3,
    img: "Null",
    name: "Neal",
    description:
      "Everyone realizes why a new common language would be desirable.",
    date: "05 Oct, 2019",
  },
]

const discountData = [
  { label: "Less than 10%", value: 0 },
  { label: "10% or more", value: 10 },
  { label: "20% or more", value: 20 },
  { label: "30% or more", value: 30 },
  { label: "40% or more", value: 40 },
  { label: "50% or more", value: 50 },
]

const orders = [
  {
    orderid: "#12354781",
    date: "Jul 11, 2017",
    billingname: "Lasse C. Overgaard",
    paymenticon: "fa-cc-amex",
    amount: "$22",
    badge: "success",
    paymentstatus: "Paid",
  },
  {
    orderid: "#12365474",
    date: "Jul 10, 2017",
    billingname: "Johan E. Knudsen",
    paymenticon: "fa-cc-mastercard",
    amount: "$1,541",
    badge: "danger",
    paymentstatus: "Chargeback",
  },
  {
    orderid: "#23145216",
    date: "Jul 11, 2017",
    billingname: "Lasse C. Overgaard",
    paymenticon: "fa-cc-amex",
    amount: "$22",
    badge: "success",
    paymentstatus: "Paid",
  },
  {
    orderid: "#32147851",
    date: "Jul 08, 2017",
    billingname: "Nikolaj S. Henriksen",
    paymenticon: "fa-cc-paypal",
    amount: "$421",
    badge: "success",
    paymentstatus: "Paid",
  },
  {
    orderid: "#32147852",
    date: "Jul 08, 2017",
    billingname: "Nikolaj S. Henriksen",
    paymenticon: "fa-cc-paypal",
    amount: "$421",
    badge: "success",
    paymentstatus: "Paid",
  },
  {
    orderid: "#32569874",
    date: "Jul 09, 2017",
    billingname: "Herbert C. Patton",
    paymenticon: "fa-cc-visa",
    amount: "$54",
    badge: "success",
    paymentstatus: "Paid",
  },
  {
    orderid: "#45123698",
    date: "July 14, 2017",
    billingname: "Nikolaj S. Henriksen",
    paymenticon: "fa-cc-paypal",
    amount: "$241",
    badge: "warning",
    paymentstatus: "Refund",
  },
  {
    orderid: "#50025441",
    date: "Jul 08, 2017",
    billingname: "Mathias N. Klausen",
    paymenticon: "fa-cc-discover",
    amount: "$845",
    badge: "warning",
    paymentstatus: "Refund",
  },
  {
    orderid: "#52140300",
    date: "Jul 20, 2017",
    billingname: "Herbert C. Patton",
    paymenticon: "fa-cc-mastercard",
    amount: "$45",
    badge: "success",
    paymentstatus: "Paid",
  },
  {
    orderid: "#52146321",
    date: "Jul 02, 2017",
    billingname: "Lasse C. Overgaard",
    paymenticon: "fa-cc-mastercard",
    amount: "$652",
    badge: "success",
    paymentstatus: "Paid",
  },
  {
    orderid: "#NZ1573",
    date: "Jul 06, 2017",
    billingname: "James Hamilton",
    paymenticon: "fa-cc-discover",
    amount: "$152",
    badge: "success",
    paymentstatus: "Paid",
  },
]

const cartData = {
  products: [
    {
      id: 1,
      img: "img1",
      name: "Half sleeve T-shirt",
      color: "Maroon",
      price: "450",
      data_attr: 2,
      total: 900,
    },
    {
      id: 2,
      img: "img2",
      name: "Light blue T-shirt",
      color: "Light blue",
      price: "225",
      data_attr: 6,
      total: 225,
    },
    {
      id: 3,
      img: "img3",
      name: "Black Color T-shirt",
      color: "Black",
      price: "152",
      data_attr: 2,
      total: 304,
    },
    {
      id: 4,
      img: "img4",
      name: "Hoodie (Blue)",
      color: "Blue",
      price: "145",
      data_attr: 2,
      total: 290,
    },
    {
      id: 5,
      img: "img5",
      name: "Half sleeve T-Shirt",
      color: "Light orange",
      price: "138",
      data_attr: 8,
      total: 138,
    },
    {
      id: 6,
      img: "img6",
      name: "Green color T-shirt",
      color: "Green",
      price: "152",
      data_attr: 2,
      total: 304,
    },
  ],
  orderSummary: {
    grandTotal: "$ 1,857",
    discount: "$ 157",
    shippingCharge: "$ 25",
    estimatedTax: "$ 19.22",
    total: "$ 1744.22",
  },
}

const customerData = [
  {
    id: "1",
    username: "Stephen Rash",
    phone: "325-250-1106",
    email: "StephenRash@teleworm.us",
    address: "2470 Grove Street Bethpage, NY 11714",
    rating: "4.2",
    walletBalance: "$5,412",
    joiningDate: "07 Oct, 2019",
  },
  {
    id: "2",
    username: "Juan Mays",
    phone: "443-523-4726",
    email: "JuanMays@armyspy.com",
    address: "3755 Harron Drive Salisbury, MD 21875",
    rating: "4.0",
    walletBalance: "$5,632",
    joiningDate: "06 Oct, 2019",
  },
  {
    id: "3",
    username: "Scott Henry",
    phone: "704-629-9535",
    email: "ScottHenry@jourrapide.com",
    address: "3632 Snyder Avenue Bessemer City, NC 2801",
    rating: "4.4",
    walletBalance: "$7,523",
    joiningDate: "06 Oct, 2019",
  },
  {
    id: "4",
    username: "Cody Menendez",
    phone: "701-832-5838",
    email: "CodyMenendez@armyspy.com",
    address: "4401 Findley Avenue Minot, ND 58701",
    rating: "4.1",
    walletBalance: "$6,325",
    joiningDate: "05 Oct, 2019",
  },
  {
    id: "5",
    username: "Jason Merino",
    phone: "706-219-4095",
    email: "JasonMerino@dayrep.com",
    address: "3159 Holly Street Cleveland, GA 30528",
    rating: "3.8",
    walletBalance: "$4,523",
    joiningDate: "04 Oct, 2019",
  },
  {
    id: "6",
    username: "Kyle Aquino",
    phone: "415-232-5443",
    email: "KyleAquino@teleworm.us",
    address: "4861 Delaware Avenue San Francisco, CA 94143",
    rating: "4.0",
    walletBalance: "$5,412",
    joiningDate: "03 Oct, 2019",
  },
  {
    id: "7",
    username: "David Gaul",
    phone: "314-483-4679",
    email: "DavidGaul@teleworm.us",
    address: "1207 Cottrill Lane Stlouis, MO 63101",
    rating: "4.2",
    walletBalance: "$6,180",
    joiningDate: "02 Oct, 2019",
  },
  {
    id: "8",
    username: "John McCray",
    phone: "253-661-7551",
    email: "JohnMcCray@armyspy.com",
    address: "3309 Horizon Circle Tacoma, WA 98423",
    rating: "4.1",
    walletBalance: "$5,2870",
    joiningDate: "02 Oct, 2019",
  },
]

const shops = [
  {
    id: 1,
    color: "primary",
    name: "Brendle's",
    product: 112,
    balance: "13,575",
    profileLink: "#",
  },
  {
    id: 2,
    color: "warning",
    name: "Tech Hifi",
    product: 104,
    balance: "11,145",
    profileLink: "#",
  },
  {
    id: 3,
    color: "danger",
    name: "Lafayette",
    product: 126,
    balance: "12,356",
    profileLink: "#",
  },
  {
    id: 4,
    color: "success",
    name: "Packer",
    product: 102,
    balance: "11,228",
    profileLink: "#",
  },
  {
    id: 5,
    color: "info",
    name: "Nedick's",
    product: 96,
    balance: "9,235",
    profileLink: "#",
  },
  {
    id: 6,
    color: "dark",
    name: "Hudson's",
    product: 120,
    balance: "14,794",
    profileLink: "#",
  },
  {
    id: 7,
    color: "dark",
    name: "Tech Hifi",
    product: 104,
    balance: "11,145",
    profileLink: "#",
  },
  {
    id: 8,
    color: "primary",
    name: "Brendle's",
    product: 112,
    balance: "13,575",
    profileLink: "#",
  },
  {
    id: 9,
    color: "success",
    name: "Lafayette",
    product: 120,
    balance: "12,356",
    profileLink: "#",
  },
]

export {
  productsData,
  recentProducts,
  comments,
  discountData,
  orders,
  shops,
  customerData,
  cartData,
}
