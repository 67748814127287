import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { PieChart } from 'react-easy-chart';

function Widgets(props) {
    return (
        <React.Fragment>
            <Row>
                <Col md={6} xl={3}>
                    <Card>
                        <CardBody>
                            <div className="mini-stat widget-chart-sm clearfix">
                                <div className="float-start">
                                    <PieChart
                                        style={{ fill: '#3bc3e9 , #f2f2f2', radius: '32' }}
                                        innerRadius={23}
                                        size={70}
                                        innerHoleSize={55}
                                        data={[
                                            { key: 'A', value: 100, color: '#f2f2f2' },
                                            { key: 'B', value: 200, color: '#3bc3e9' },
                                        ]}
                                    />
                                </div>
                                <div className="mini-stat-info text-end">
                                    <span className="counter text-info">9541</span>
                                            Total Sales
                                        </div>
                                <p className=" mb-0 mt-4 text-muted">Total income: $22506 <span className="float-end"><i className="fa fa-caret-up me-1"></i>10.25%</span></p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} xl={3}>
                    <Card>
                        <CardBody>
                            <div className="mini-stat widget-chart-sm clearfix">
                                <div className="float-start">
                                    <PieChart
                                        size={70}
                                        data={[
                                            { key: 'A', value: 100, color: '#f2f2f2' },
                                            { key: 'B', value: 200, color: '#4ac18e' },
                                        ]}
                                    />
                                </div>
                                <div className="mini-stat-info text-end">
                                    <span className="counter text-lime">36521</span>
                                    New Orders
                                </div>

                                <p className="text-muted mb-0 mt-4">Total income: $22506 <span className="float-end"><i className="fa fa-caret-up m-r-5"></i>10.25%</span></p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} xl={3}>
                    <Card>
                        <CardBody>
                            <div className="mini-stat widget-chart-sm clearfix">
                                <div className="float-start">
                                    <PieChart
                                        size={70}
                                        innerHoleSize={50}
                                        data={[
                                            { key: 'A', value: 100, color: '#f2f2f2' },
                                            { key: 'B', value: 200, color: '#ea553d' },
                                        ]}
                                    />
                                </div>
                                <div className="mini-stat-info text-end">
                                    <span className="counter text-danger">6521</span>
                                    New Users
                                </div>

                                <p className="mb-0 mt-4 text-muted">Total income: $22506 <span className="float-end"><i className="fa fa-caret-up m-r-5"></i>10.25%</span></p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6} xl={3}>
                    <Card>
                        <CardBody>
                            <div className="mini-stat widget-chart-sm clearfix">
                                <div className="float-start">
                                    <PieChart
                                        size={70}
                                        data={[
                                            { key: 'A', value: 60, color: '#f2f2f2' },
                                            { key: 'B', value: 200, color: '#1d1e3a' },
                                        ]}
                                    />
                                </div>
                                <div className="mini-stat-info text-end">
                                    <span className="counter text-dark">1002</span>
                                    Unique Visitors
                                </div>

                                <p className="mb-0 mt-4 text-muted">Total income: $22506 <span className="float-end"><i className="fa fa-caret-up m-r-5"></i>10.25%</span></p>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Widgets;