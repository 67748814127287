import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Collapse, Container, Row, UncontrolledCollapse } from 'reactstrap';
import { connect } from "react-redux";
//Breadcrumb
import { setBreadcrumbItems } from '../store/actions';
import Breadcrumb from '../../src/common/Breadcrumb';


const Faq = props => {

    const [isOpen, setIsOpen] = useState(true);

    const toggle = () => setIsOpen(!isOpen);

    const breadcrumbItems = [
        {
            title: 'FAQ',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('FAQ', breadcrumbItems)
    })
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Pages" />
                        : null}

                    <Row>
                        <Col lg={8}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-3">Frequently Asked Questions</h4>
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item border rounded">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button fw-bold" onClick={toggle} type="button" id="collapseOne">
                                                    Q.1 What is Lorem Ipsum?
                                                </button>
                                            </h2>
                                            <Collapse isOpen={isOpen} toggler="#collapseOne" className="accordion-collapse">
                                                <div className="accordion-body">
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                                                    accusamus terry richardson ad squid. 3 wolf moon officia
                                                    aute, non cupidatat skateboard dolor brunch. Food truck
                                                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                                                    sunt aliqua put a bird on it squid single-origin coffee
                                                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                                                    helvetica, craft beer labore wes anderson cred nesciunt
                                                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                                    Leggings occaecat craft beer farm-to-table, raw denim
                                                    aesthetic synth nesciunt you probably haven't heard of them
                                                    accusamus labore sustainable VHS.
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="accordion-item border rounded mt-2">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed fw-bold" id="collapseTwo" type="button">
                                                    Q.2 Is safe use Lorem Ipsum?
                                                </button>
                                            </h2>
                                            <UncontrolledCollapse toggler="#collapseTwo" className="accordion-collapse">
                                                <div className="accordion-body">
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                                                    accusamus terry richardson ad squid. 3 wolf moon officia
                                                    aute, non cupidatat skateboard dolor brunch. Food truck
                                                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                                                    sunt aliqua put a bird on it squid single-origin coffee
                                                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                                                    helvetica, craft beer labore wes anderson cred nesciunt
                                                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                                    Leggings occaecat craft beer farm-to-table, raw denim
                                                    aesthetic synth nesciunt you probably haven't heard of them
                                                    accusamus labore sustainable VHS.
                                                </div>
                                            </UncontrolledCollapse>
                                        </div>
                                        <div className="accordion-item border rounded mt-2">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed fw-bold" type="button" id="collapseThree">
                                                    Q.3 Why use Lorem Ipsum?
                                                </button>
                                            </h2>
                                            <UncontrolledCollapse toggler="#collapseThree" className="accordion-collapse">
                                                <div className="accordion-body">
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                                                    accusamus terry richardson ad squid. 3 wolf moon officia
                                                    aute, non cupidatat skateboard dolor brunch. Food truck
                                                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                                                    sunt aliqua put a bird on it squid single-origin coffee
                                                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                                                    helvetica, craft beer labore wes anderson cred nesciunt
                                                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                                    Leggings occaecat craft beer farm-to-table, raw denim
                                                    aesthetic synth nesciunt you probably haven't heard of them
                                                    accusamus labore sustainable VHS.
                                                </div>
                                            </UncontrolledCollapse>
                                        </div>
                                        <div className="accordion-item border rounded mt-2">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed fw-bold" type="button" id="collapseFour">
                                                    Q.4 When can be used?
                                                </button>
                                            </h2>
                                            <UncontrolledCollapse toggler="#collapseFour" className="accordion-collapse">
                                                <div className="accordion-body">
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                                                    accusamus terry richardson ad squid. 3 wolf moon officia
                                                    aute, non cupidatat skateboard dolor brunch. Food truck
                                                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                                                    sunt aliqua put a bird on it squid single-origin coffee
                                                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                                                    helvetica, craft beer labore wes anderson cred nesciunt
                                                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                                    Leggings occaecat craft beer farm-to-table, raw denim
                                                    aesthetic synth nesciunt you probably haven't heard of them
                                                    accusamus labore sustainable VHS.
                                                </div>
                                            </UncontrolledCollapse>
                                        </div>
                                        <div className="accordion-item border rounded mt-2">
                                            <h2 className="accordion-header" id="headingFive">
                                                <button className="accordion-button collapsed fw-bold" type="button" id="collapseFive">
                                                    Q.5 How many variations exist?
                                                </button>
                                            </h2>
                                            <UncontrolledCollapse toggler="#collapseFive" className="accordion-collapse">
                                                <div className="accordion-body">
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                                                    accusamus terry richardson ad squid. 3 wolf moon officia
                                                    aute, non cupidatat skateboard dolor brunch. Food truck
                                                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                                                    sunt aliqua put a bird on it squid single-origin coffee
                                                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                                                    helvetica, craft beer labore wes anderson cred nesciunt
                                                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                                    Leggings occaecat craft beer farm-to-table, raw denim
                                                    aesthetic synth nesciunt you probably haven't heard of them
                                                    accusamus labore sustainable VHS.
                                                </div>
                                            </UncontrolledCollapse>
                                        </div>
                                        <div className="accordion-item border rounded mt-2">
                                            <h2 className="accordion-header" id="headingSix">
                                                <button className="accordion-button collapsed fw-bold" type="button" id="collapseSix">
                                                    Q.6 License & Copyright
                                                </button>
                                            </h2>
                                            <UncontrolledCollapse toggler="#collapseSix" className="accordion-collapse">
                                                <div className="accordion-body">
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life
                                                    accusamus terry richardson ad squid. 3 wolf moon officia
                                                    aute, non cupidatat skateboard dolor brunch. Food truck
                                                    quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                                                    sunt aliqua put a bird on it squid single-origin coffee
                                                    nulla assumenda shoreditch et. Nihil anim keffiyeh
                                                    helvetica, craft beer labore wes anderson cred nesciunt
                                                    sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                                    Leggings occaecat craft beer farm-to-table, raw denim
                                                    aesthetic synth nesciunt you probably haven't heard of them
                                                    accusamus labore sustainable VHS.
                                                </div>
                                            </UncontrolledCollapse>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <div className="col-lg-4">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h5>Can't find what you are looking for?</h5>
                                        <button type="button" className="btn btn-primary mt-4 waves-effect waves-light">Email
                                            Us</button>
                                        <h6 className="text-muted mt-4">OR</h6>

                                        <button type="button" className="btn btn-success mt-3 waves-effect waves-light">Send us
                                        a
                                            tweet</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(Faq);
