import PropTypes from 'prop-types'
import React from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"
import BackGround from '../../assets/images/bg.jpg';
import logo from '../../assets/images/logo.png';


// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"

// import images
// import profile from "../../assets/images/profile-img.png"
// import logo from "../../assets/images/logo.svg"

const ForgetPasswordPage = props => {
  function handleValidSubmit(event, values) {
    props.userForgetPassword(values, props.history)
  }

  return (
    <React.Fragment>
      <div className="accountbg" style={{ background: `url(${BackGround}) center`, backgroundPosition: 'cover' }}></div>

      <div className="account-pages mt-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card>
                <CardBody>
                  <div className="text-center mt-4">
                    <div className="mb-3">
                      <Link to="index.html"><img src={logo} height="30" alt="logo" /></Link>
                    </div>
                  </div>
                  <div className="p-3">
                    <h4 className="font-size-18 mt-2 text-center">Reset Password</h4>
                    <p className="text-muted text-center mb-4">Enter your Email and instructions will be sent to you!</p>

                    {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>

                  </div>

                </CardBody>
              </Card>
              <div className="mt-5 text-center position-relative">
                <p className="text-white">Remember It ? <Link to="/login" className="font-weight-bold text-primary"> Sign In Here </Link> </p>
                <p className="text-white">2017 - {new Date().getFullYear()}© Admiria. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
              </div>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
