import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import DonutChart from '../AllCharts/DonutChart';

function SalesAnalytics(props) {
    return (
        <React.Fragment>
            <Col xl={3}>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">Sales Analytics</CardTitle>

                        <Row className="text-center mt-4 pt-3">
                            <Col xs={6}>
                                <h5 className="mb-0 font-size-18">25610</h5>
                                <p className="text-muted">Activated</p>
                            </Col>
                            <Col xs={6}>
                                <h5 className="mb-0 font-size-18">56210</h5>
                                <p className="text-muted">Pending</p>
                            </Col>
                        </Row>


                       <DonutChart />

                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default SalesAnalytics;