import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// //E-commerce
import ecommerce from "./e-commerce/reducer";

// //Calendar
import calendar from "./calendar/reducer";

//Breadcrumb
import Breadcrumb from './Breadcrumb/reducer'

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  Breadcrumb
})

export default rootReducer
