import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import c3 from "c3";

//import css
import "./c3.min.css";

const MonthlyEarning = props => {

    React.useEffect(() => {
        c3.generate({
            bindto: '#combine-chart',
            data: {
                columns: [
                    ['SonyVaio', 30, 20, 50, 40, 60, 50],
                    ['iMacs', 200, 130, 90, 240, 130, 220],
                    ['Tablets', 300, 200, 160, 400, 250, 250],
                    ['iPhones', 200, 130, 90, 240, 130, 220],
                    ['Macbooks', 130, 120, 150, 140, 160, 150]
                ],
                types: {
                    SonyVaio: 'bar',
                    iMacs: 'bar',
                    Tablets: 'spline',
                    iPhones: 'line',
                    Macbooks: 'bar'
                },
                colors: {
                    SonyVaio: '#5468da',
                    iMacs: '#4ac18e',
                    Tablets: '#ffbb44',
                    iPhones: '#ea553d',
                    Macbooks: '#6d60b0'
                },
                groups: [
                    ['SonyVaio', 'iMacs']
                ]
            },
            axis: {
                x: {
                    type: 'categorized'
                }
            }
        });
    }, []);

    return (
        <React.Fragment>
            <Row>
                <Col md={9} className="col-md-9 monthly-earning-wid">
                    <Card style={{ height: '480px' }}>
                        <CardBody>
                            <CardTitle className="mb-4">Monthly Earnings</CardTitle>

                            <div className="text-center">
                                <div className="btn-group mt-4" role="group" aria-label="Basic example">
                                    <button type="button" className="btn btn-secondary">Day</button>
                                    <button type="button" className="btn btn-secondary">Month</button>
                                    <button type="button" className="btn btn-secondary">Year</button>
                                </div>
                            </div>

                            <div id="combine-chart" dir="ltr"></div>

                        </CardBody>
                    </Card>
                </Col>
                <Col md={3} className="earning-wid">
                    <Card style={{ height: '480px' }}>
                        <CardBody>
                            <div className="mb-4">
                                <p>Weekly Earnings</p>
                                <h5>$1,542</h5>
                                <Sparklines className="peity-line" height={60} data={[6, 2, 8, 4, 3, 8, 1, 3, 6, 5, 9, 2, 8, 1, 4, 8, 9, 8, 2, 1]}>
                                    <SparklinesLine style={{ fill: "rgba(103,168,228,0.3)", stroke: "rgba(103, 168, 228, 0.8)", fillOpacity: 1 }} />
                                </Sparklines>
                            </div>
                            <div className="mb-4">
                                <p>Monthly Earnings</p>
                                <h5>$6,451</h5>
                                <Sparklines className="peity-line" height={60} data={[6, 2, 8, 4, -3, 8, 1, -3, 6, -5, 9, 2, -8, 1, 4, 8, 9, 8, 2, 1]}>
                                    <SparklinesLine style={{ fill: "rgba(74,193,142,0.3)", stroke: 'rgba(74,193,142,0.8)', fillOpacity: 1 }} />
                                </Sparklines>
                            </div>
                            <div className="mb-4">
                                <p>Yearly Earnings</p>
                                <h5>$84,574</h5>
                                <Sparklines className="peity-line" height={60} data={[6, 2, 8, 4, 3, 8, 1, 3, 6, 5, 9, 2, 8, 1, 4, 8, 9, 8, 2, 1]}>
                                    <SparklinesLine style={{ fill: "rgba(232, 65, 38,0.3)", stroke: 'rgba(232, 65, 38,0.8)', fillOpacity: 1 }} />
                                </Sparklines>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default MonthlyEarning;