import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';

import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

function UiPagination(props) {
    const breadcrumbItems = [
        {
            title: 'Pagination',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Pagination', breadcrumbItems)
    })
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Ui" />
                        : null}
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Default Example</h4>
                                    <p className="card-title-desc">Pagination links indicate a series of related content exists across multiple pages.</p>

                                    <nav aria-label="Page navigation example">
                                        <Pagination>
                                            <PaginationItem><PaginationLink href="#">Previous</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">1</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">2</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">3</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">Next</PaginationLink></PaginationItem>
                                        </Pagination>
                                    </nav>

                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination mb-0">
                                            <PaginationItem>
                                                <PaginationLink href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                    <span className="sr-only">Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem><PaginationLink href="#">1</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">2</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">3</PaginationLink></PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                    <span className="sr-only">Next</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </ul>
                                    </nav>
                                </CardBody>
                            </Card>
                        </Col>

                        <div className="col-lg-6">
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Disabled and active states</h4>
                                    <p className="card-title-desc">Pagination links are customizable for
                                            different circumstances. Use <code
                                            className="highlighter-rouge">.disabled</code> for links that appear
                                            un-clickable and <code className="highlighter-rouge">.active</code> to
                                            indicate the current page.</p>

                                    <nav aria-label="...">
                                        <Pagination>
                                            <li className="page-item disabled">
                                                <PaginationLink href="#" tabindex="-1">Previous</PaginationLink>
                                            </li>
                                            <PaginationItem><PaginationLink href="#">1</PaginationLink></PaginationItem>
                                            <li className="page-item active">
                                                <PaginationLink href="#">2 <span className="sr-only">(current)</span></PaginationLink>
                                            </li>
                                            <PaginationItem><PaginationLink href="#">3</PaginationLink></PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">Next</PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </nav>

                                    <nav aria-label="...">
                                        <ul className="pagination mb-0">
                                            <li className="page-item disabled">
                                                <span className="page-link">Previous</span>
                                            </li>
                                            <PaginationItem><PaginationLink href="#">1</PaginationLink></PaginationItem>
                                            <li className="page-item active">
                                                <span className="page-link">
                                                    2
                                                        <span className="sr-only">(current)</span>
                                                </span>
                                            </li>
                                            <PaginationItem><PaginationLink href="#">3</PaginationLink></PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">Next</PaginationLink>
                                            </PaginationItem>
                                        </ul>
                                    </nav>
                                </CardBody>
                            </Card>
                        </div>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Sizing</h4>
                                    <p className="card-title-desc">Fancy larger or smaller pagination? Add
                                            <code className="highlighter-rouge">.pagination-lg</code> or <code
                                            className="highlighter-rouge">.pagination-sm</code> for additional
                                            sizes.</p>

                                    <nav aria-label="...">
                                        <ul className="pagination pagination-lg">
                                            <li className="page-item disabled">
                                                <PaginationLink href="#" tabindex="-1">Previous</PaginationLink>
                                            </li>
                                            <PaginationItem><PaginationLink href="#">1</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">2</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">3</PaginationLink></PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">Next</PaginationLink>
                                            </PaginationItem>
                                        </ul>
                                    </nav>

                                    <nav aria-label="...">
                                        <ul className="pagination pagination-sm mb-0">
                                            <li className="page-item disabled">
                                                <PaginationLink href="#" tabindex="-1">Previous</PaginationLink>
                                            </li>
                                            <PaginationItem><PaginationLink href="#">1</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">2</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">3</PaginationLink></PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">Next</PaginationLink>
                                            </PaginationItem>
                                        </ul>
                                    </nav>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Alignment</h4>
                                    <p className="card-title-desc">Change the alignment of pagination
                                            components with flexbox utilities.</p>

                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-center">
                                            <li className="page-item disabled">
                                                <PaginationLink href="#" tabindex="-1">Previous</PaginationLink>
                                            </li>
                                            <PaginationItem><PaginationLink href="#">1</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">2</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">3</PaginationLink></PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">Next</PaginationLink>
                                            </PaginationItem>
                                        </ul>
                                    </nav>

                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-end mb-0">
                                            <li className="page-item disabled">
                                                <PaginationLink href="#" tabindex="-1">Previous</PaginationLink>
                                            </li>
                                            <PaginationItem><PaginationLink href="#">1</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">2</PaginationLink></PaginationItem>
                                            <PaginationItem><PaginationLink href="#">3</PaginationLink></PaginationItem>
                                            <PaginationItem>
                                                <PaginationLink href="#">Next</PaginationLink>
                                            </PaginationItem>
                                        </ul>
                                    </nav>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(UiPagination);