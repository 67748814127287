import React, { useState, useEffect } from "react"

import {
    Row,
    Col,
    Container,
} from "reactstrap"
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

// import image
import img1 from "../../assets/images/gallery/work-1.jpg"
import img2 from "../../assets/images/gallery/work-2.jpg"
import img3 from "../../assets/images/gallery/work-3.jpg"
import img4 from "../../assets/images/gallery/work-4.jpg"
import img5 from "../../assets/images/gallery/work-5.jpg"
import img6 from "../../assets/images/gallery/work-6.jpg"
import img7 from "../../assets/images/gallery/work-7.jpg"
import img8 from "../../assets/images/gallery/work-8.jpg"
import img9 from "../../assets/images/gallery/work-9.jpg"
import img10 from "../../assets/images/gallery/work-10.jpg"
import img11 from "../../assets/images/gallery/work-11.jpg"
import img12 from "../../assets/images/gallery/work-12.jpg"

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12]

const UiLightbox = (props) => {
    const [photoIndex, setphotoIndex] = useState(0)
    const [isFits, setisFits] = useState(false)
    const [isEffects, setisEffects] = useState(false)
    const [isGallery, setisGallery] = useState(false)
    const [isGalleryZoom, setisGalleryZoom] = useState(false)

    const breadcrumbItems = [
        {
            title: 'Gallery',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Gallery', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>

                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Pages" />
                        : null}
                    {isFits ? (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            enableZoom={false}
                            imageCaption={
                                "Caption. Can be aligned it to any side and contain any HTML."
                            }
                            onCloseRequest={() => {
                                setisFits(!isFits)
                            }}
                        />
                    ) : null}

                    {isEffects ? (
                        <Lightbox
                            mainSrc={images[3]}
                            enableZoom={false}
                            onCloseRequest={() => {
                                setisEffects(!isEffects)
                            }}
                        />
                    ) : null}

                    {isGallery ? (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            enableZoom={true}
                            onCloseRequest={() => {
                                setisGallery(false)
                            }}
                            onMovePrevRequest={() => {
                                setphotoIndex((photoIndex + images.length - 1) % images.length)
                            }}
                            onMoveNextRequest={() => {
                                setphotoIndex((photoIndex + 1) % images.length)
                            }}
                            imageCaption={"Project " + parseFloat(photoIndex + 1)}
                        />
                    ) : null}

                    {isGalleryZoom ? (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => {
                                setisGalleryZoom(false)
                            }}
                            onMovePrevRequest={() => {
                                setphotoIndex((photoIndex + images.length - 1) % images.length)
                            }}
                            onMoveNextRequest={() => {
                                setphotoIndex((photoIndex + 1) % images.length)
                            }}
                        />
                    ) : null}

                    <Row>

                        <Col lg={3} md={6}>
                            {/* <div className="project-item"> */}
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img1}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img2}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img3}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img4}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img5}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img6}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img7}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img8}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img9}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img10}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img11}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6}>
                            <div className="project-item">
                                <div className="overlay-container">
                                    <img
                                        src={img12}
                                        onClick={() => {
                                            setisGallery(true)
                                            setphotoIndex(0)
                                        }}
                                        className="gallery-thumb-img"
                                        alt=""
                                        width="120"
                                    />

                                </div>
                            </div>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
  };
  
  export default connect(mapStatetoProps, { setBreadcrumbItems })(UiLightbox);
