import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

//import images
import user1 from '../../assets/images/small/img-4.jpg';
import user2 from '../../assets/images/users/avatar-4.jpg';
import user3 from '../../assets/images/small/img-3.jpg';
import user4 from '../../assets/images/users/avatar-3.jpg';
import user5 from '../../assets/images/users/avatar-3.jpg';
import user6 from '../../assets/images/users/avatar-5.jpg';
import user7 from '../../assets/images/small/img-2.jpg';

const UiImages = props => {
    const breadcrumbItems = [
        {
            title: 'Image',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Image', breadcrumbItems)
    })
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Ui" />
                        : null}
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Image Rounded & Circle</h4>
                                    <p className="card-title-desc">Use classes
                                            <code>.rounded</code> and <code>.rounded-circle</code>.
                                        </p>

                                    <Row>
                                        <Col md={6}>
                                            <img className="rounded me-2" alt="200x200" width="200"
                                                src={user1} data-holder-rendered="true" />
                                        </Col>
                                        <Col md={6}>
                                            <div className="mt-4 mt-md-0">
                                                <img className="rounded-circle avatar-xl" alt="200x200"
                                                    src={user2} data-holder-rendered="true" />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Image thumbnails</h4>
                                    <p className="card-title-desc">In addition to our border-radius utilities, you can use
                                            <code className="highlighter-rouge">.img-thumbnail</code> to give an image a rounded
                                            1px border appearance.
                                        </p>

                                    <Row>
                                        <Col md={6}>
                                            <img className="img-thumbnail" alt="200x200" width="200"
                                                src={user3} data-holder-rendered="true" />
                                        </Col>
                                        <Col md={6}>
                                            <div className="mt-4 mt-md-0">
                                                <img className="img-thumbnail rounded-circle avatar-xl" alt="200x200"
                                                    src={user4} data-holder-rendered="true" />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Responsive images</h4>
                                    <p className="card-title-desc">Images in Bootstrap are made responsive
                                            with <code className="highlighter-rouge">.img-fluid</code>. <code
                                            className="highlighter-rouge">max-width: 100%;</code> and <code
                                                className="highlighter-rouge">height: auto;</code> are applied to
                                            the image so that it scales with the parent element.</p>

                                    <div className="">
                                        <img src={user7} className="img-fluid" alt="" />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Image Sizes</h4>
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                <Col lg={4}>
                                                    <div>
                                                        <img src={user5} alt=""
                                                            className="rounded avatar-sm" />
                                                        <p className="mt-2 mb-lg-0"><code>.avatar-sm</code></p>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div>
                                                        <img src={user2} alt=""
                                                            className="rounded avatar-md" />
                                                        <p className="mt-2  mb-lg-0"><code>.avatar-md</code></p>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div>
                                                        <img src={user6} alt=""
                                                            className="rounded avatar-lg" />
                                                        <p className="mt-2 mb-lg-0"><code>.avatar-lg</code></p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col lg={4}>
                                                    <div>
                                                        <img src={user5} alt=""
                                                            className="rounded-circle avatar-sm" />
                                                        <p className="mt-2 mb-lg-0"><code>.avatar-sm</code></p>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div>
                                                        <img src={user2} alt=""
                                                            className="rounded-circle avatar-md" />
                                                        <p className="mt-2  mb-lg-0"><code>.avatar-md</code></p>
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div>
                                                        <img src={user6} alt=""
                                                            className="rounded-circle avatar-lg" />
                                                        <p className="mt-2 mb-lg-0"><code>.avatar-lg</code></p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(UiImages);