import React, { useEffect } from "react"
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const PagePricing = props => {

    ///breadcrumbItems

    const breadcrumbItems = [
        {
            title: 'Pricing',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Pricing', breadcrumbItems)
    })

    const pricing = [
        {
            id: 1,
            icon: 'ion ion-md-airplane plan-icon bg-primary h1',
            title: 'STARTER PACK',
            pricing: '19',
            color: 'primary'
        },
        {
            id: 2,
            icon: 'ion ion-md-trophy plan-icon bg-dark h1 text-light',
            title: 'PROFESSIONAL PACK',
            pricing: '29',
            color: 'dark'
        },
        {
            id: 3,
            icon: 'ion ion-ios-umbrella plan-icon bg-pink h1',
            title: 'ENTERPRISE PACK',
            pricing: '39',
            color: 'pink'
        },
        {
            id: 4,
            icon: 'ion ion-md-cube plan-icon bg-teal h1',
            title: 'UNLIMITED PACK',
            pricing: '49',
            color: 'teal'
        },
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Pages" />
                        : null}

                    <Row className="text-center">
                        {pricing.map((pricing, key) => (
                            <Col lg={3} key={key}>
                                <Card className="plan-card mt-4">
                                    <CardBody>
                                        <div className="pt-3 pb-3">
                                            <i className={pricing.icon}></i>
                                            <h6 className={"text-uppercase text-" + pricing.color}>{pricing.title}</h6>
                                        </div>
                                        <div>
                                            <h1 className="plan-price">${pricing.pricing}<span className="text-muted ms-2"><sup>Per Month</sup></span></h1>
                                            <div className="plan-div-border"></div>
                                        </div>
                                        <div className="plan-features pb-3 mt-3 pt-3 text-muted">
                                            <p>Free Live Support</p>
                                            <p>Unlimited User</p>
                                            <p>No Time Tracking</p>
                                            <p>Free Setup</p>
                                            <Link to="#" className={"btn btn-" + pricing.color}>Sign Up Now</Link>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
  };
  
  export default connect(mapStatetoProps, { setBreadcrumbItems })(PagePricing);