import React, { useEffect } from "react"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap"

// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';


const FormEditors = (props) => {
  const breadcrumbItems = [
    {
      title: 'Form Editors',
      link: '#'
    }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Form Editors', breadcrumbItems)
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Breadcrumb */}
          {props.layoutType === 'horizontal' ?
            <Breadcrumb breadcrumbItem="Forms" />
            : null}
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle>react-draft-wysiwyg</CardTitle>
                  <CardSubtitle className="mb-3">
                    Bootstrap-wysihtml5 is a javascript plugin that makes it
                    easy to create simple, beautiful wysiwyg editors with the
                    help of wysihtml5 and Twitter Bootstrap.
                  </CardSubtitle>

                  <Form method="post">
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const Layout = state.Layout;
  return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(FormEditors);


