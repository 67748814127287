import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import { connect } from "react-redux";
import { setBreadcrumbItems } from '../../store/actions';

import BackGround from '../../assets/images/bg.jpg';
import logo from '../../assets/images/logo.png';

const Recoverpw = props => {
    ///breadcrumbItems

    const breadcrumbItems = [
        {
            title: 'Recover Password',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Recover Password', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="accountbg" style={{ background: `url(${BackGround}) center`, backgroundPosition: 'cover' }}></div>

            <div className="account-pages mt-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={4}>
                            <Card>
                                <CardBody>
                                    <div className="text-center mt-4">
                                        <div className="mb-3">
                                            <Link to="index.html"><img src={logo} height="30" alt="logo" /></Link>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <h4 className="font-size-18 mt-2 text-center">Reset Password</h4>
                                        <p className="text-muted text-center mb-4">Enter your Email and instructions will be sent to you!</p>

                                        <form className="form-horizontal" action="index.html">

                                            <div className="mb-3">
                                                <label for="useremail" className="form-label">Email</label>
                                                <input type="email" className="form-control" id="useremail" placeholder="Enter email" />
                                            </div>

                                            <div className="mb-3">
                                                <div className="text-end">
                                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Reset</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>

                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center position-relative">
                                <p className="text-white">Remember It ? <a href="pages-login.html" className="font-weight-bold text-primary"> Sign In Here </a> </p>
                                <p className="text-white">2017 - {new Date().getFullYear()}© Admiria. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(Recoverpw);