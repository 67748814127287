import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ReactApexChart from "react-apexcharts"

const MonthlyEarning2 = props => {
    const series = [
        {
          data: [45, 75, 100, 75, 100, 75, 50, 75, 50, 75, 100, 80],
        },
        {
          data: [180, 65, 90, 65, 90, 65, 40, 65, 40, 65, 90, 65],
        },
      ]
      const chartOptions =  {
        dataLabels: {
          enabled: false,
        },
        chart: {
          type: "bar",
          height: 300,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions : {
          bar: {
            horizontal: false,
          },
        },
        colors: ['#47b8c6','#e8e7e7'],
        xaxis: {
          categories: [
            "2005",
            "2006",
            "2007",
            "2008",
            "2009",
            "2010",
            "2011",
            "2012",
            "2013",
            "2014",
            "2015",
            "2016",
          ],
          labels: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
      }
    return (
        <React.Fragment>
            <Col xl={3}>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Monthly Earnings</h4>

                        <Row className="text-center mt-4">
                            <Col xs={6}>
                                <h5 className="">56241</h5>
                                <p className="text-muted font-size-14">Marketplace</p>
                            </Col>
                            <Col xs={6}>
                                <h5 className="">23651</h5>
                                <p className="text-muted font-size-14">Total Income</p>
                            </Col>
                        </Row>

                        <ReactApexChart options={chartOptions} series={series} type="bar" height={300} />
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default MonthlyEarning2;