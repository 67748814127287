import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { connect } from "react-redux";
//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';


const UiColor = props => {
    const breadcrumbItems = [
        {
            title: 'Colors',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Colors', breadcrumbItems)
    });
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Ui" />
                        : null}
                    <Row className="row-cols-xl-4 row-cols-md-3">
                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-primary">
                                        <h5 className="my-2 text-white">#67a8e4</h5>
                                    </div>
                                    <h5 className="mt-4 text-primary text-center font-size-18">Primary</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-success">
                                        <h5 className="my-2 text-white">#4ac18e</h5>
                                    </div>
                                    <h5 className="mt-4 text-success text-center font-size-18">Success</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-info">
                                        <h5 className="my-2 text-white">#3bc3e9</h5>
                                    </div>
                                    <h5 className="mt-4 text-info text-center font-size-18">Info</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-warning">
                                        <h5 className="my-2 text-white">#ffbb44</h5>
                                    </div>
                                    <h5 className="mt-4 text-warning text-center font-size-18">Warning</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-danger">
                                        <h5 className="my-2 text-white">#ea553d</h5>
                                    </div>
                                    <h5 className="mt-4 text-danger text-center font-size-18">Danger</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-dark">
                                        <h5 className="my-2 text-light">#343a40</h5>
                                    </div>
                                    <h5 className="mt-4 text-dark text-center font-size-18">Dark</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-pink">
                                        <h5 className="my-2 text-white">#f06292</h5>
                                    </div>
                                    <h5 className="mt-4 text-pink text-center font-size-18">Pink</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-purple">
                                        <h5 className="my-2 text-white">#6d60b0</h5>
                                    </div>
                                    <h5 className="mt-4 text-purple text-center font-size-18">Purple</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-indigo">
                                        <h5 className="my-2 text-white">#5468da</h5>
                                    </div>
                                    <h5 className="mt-4 text-indigo text-center font-size-18">Indigo</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-teal">
                                        <h5 className="my-2 text-white">#009688</h5>
                                    </div>
                                    <h5 className="mt-4 text-teal text-center font-size-18">Teal</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-lime">
                                        <h5 className="my-2 text-white">#afb42b</h5>
                                    </div>
                                    <h5 className="mt-4 text-lime text-center font-size-18">Lime</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-orange">
                                        <h5 className="my-2 text-white">#fb8c00</h5>
                                    </div>
                                    <h5 className="mt-4 text-orange text-center font-size-18">Orange</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-brown">
                                        <h5 className="my-2 text-white">#8d6e63</h5>
                                    </div>
                                    <h5 className="mt-4 text-brown text-center font-size-18">Brown</h5>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <Card>
                                <CardBody className="text-center">
                                    <div className="color-box p-4 rounded bg-blue-grey">
                                        <h5 className="my-2 text-white">#90a4ae</h5>
                                    </div>
                                    <h5 className="mt-4 text-blue-grey text-center font-size-18">Blue Grey</h5>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(UiColor);
