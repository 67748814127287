import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { connect } from "react-redux";

import EmailSideBar from './EmailSideBar';
import EmailToolbar from './EmailToolbar';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

const EmailCompose = props => {

    const breadcrumbItems = [
        {
            title: 'Email Compose',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Email Compose', breadcrumbItems)
    });

    return (
        <div className="page-content">
            <Container fluid>

                {/* Breadcrumb */}
                {props.layoutType === 'horizontal' ?
                    <Breadcrumb breadcrumbItem="Email" />
                    : null}
                <Row>
                    <Col xs={12}>
                        <EmailSideBar />
                        <div className="email-rightbar">
                            <EmailToolbar />
                            <div className="card mt-4">
                                <div className="card-body">

                                    <div>
                                        <div className="mb-3">
                                            <input type="email" className="form-control" placeholder="To" />
                                        </div>

                                        <div className="mb-3">
                                            <input type="text" className="form-control" placeholder="Subject" />
                                        </div>
                                        <div className="mb-3">
                                            <Editor
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                            />
                                        </div>

                                        <div className="btn-toolbar mb-0">
                                            <div className="">
                                                <button type="button" className="btn btn-success waves-effect waves-light me-1"><i className="far fa-save"></i></button>
                                                <button type="button" className="btn btn-success waves-effect waves-light me-1"><i className="far fa-trash-alt"></i></button>
                                                <button className="btn btn-purple waves-effect waves-light"> <span>Send</span> <i className="fab fa-telegram-plane ms-2"></i> </button>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(EmailCompose);
