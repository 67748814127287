import React from 'react';

// import images
import product1 from '../../assets/images/products/1.jpg';
import product2 from '../../assets/images/products/2.jpg';
import product3 from '../../assets/images/products/3.jpg';
import product4 from '../../assets/images/products/4.jpg';
import product5 from '../../assets/images/products/5.jpg';

const LatestOrders = props => {

    const order = [
        {
            id: '#12354781',
            img: product1,
            name: ' Riverston Glass Chair',
            color: 'success',
            budgetName: 'Delivered',
            price: '$185',
        },
        {
            id: '#52140300',
            img: product2,
            name: '  Shine Company Catalina',
            color: 'success',
            budgetName: 'Delivered',
            price: '$1,024',
        },
        {
            id: '#96254137',
            img: product3,
            name: 'Trex Outdoor Furniture Cape',
            color: 'danger',
            budgetName: 'cancle',
            price: '$657',
        },
        {
            id: '#12365474',
            img: product4,
            name: 'Oasis Bathroom Teak Corner',
            color: 'warning',
            budgetName: 'Shipped',
            price: '$8451',
        },
        {
            id: '#85214796',
            img: product5,
            name: ' BeoPlay Speaker',
            color: 'success',
            budgetName: 'Delivered',
            price: '$584',
        },
    ]

    return (
        <React.Fragment>
            <div className="col-xl-6">
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title mb-4">Latest Orders</h4>

                        <div className="table-responsive">
                            <table className="table table-centered table-vertical table-nowrap mb-0">

                                <tbody>
                                    {order.map((order, key) => (
                                        <tr key={key}>
                                            <td>#12354781</td>
                                            <td>
                                                <img src={order.img} alt=""
                                                    style={{ height: '48px' }} className="rounded me-2" />
                                                {order.name}
                                            </td>
                                            <td><span className={"badge rounded-pill bg-" + order.color}>{order.budgetName}</span></td>
                                            <td>
                                                {order.price}
                                            </td>
                                            <td>
                                                5/12/2016
                                                        </td>
                                            <td>
                                                <button type="button"
                                                    className="btn btn-secondary btn-sm waves-effect">Edit</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default LatestOrders;