import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import EmailSideBar from './EmailSideBar';
import EmailToolbar from './EmailToolbar';
import { connect } from "react-redux";

//import images
import user1 from '../../assets/images/users/avatar-1.jpg';
import user2 from '../../assets/images/small/img-4.jpg';
import user3 from '../../assets/images/small/img-5.jpg';
import { Link } from 'react-router-dom';

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

const EmailRead = props => {

    const breadcrumbItems = [
        {
            title: 'Email Read',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Email Read', breadcrumbItems)
    })
    return (
        <div className="page-content">
            <Container fluid>
                {/* Breadcrumb */}
                {props.layoutType === 'horizontal' ?
                    <Breadcrumb breadcrumbItem="Email" />
                    : null}
                <Row>
                    <Col xs={12}>
                        <EmailSideBar />
                        <div className="email-rightbar">
                            <EmailToolbar />

                            <Card className="mt-4">
                                <CardBody>

                                    <div className="d-flex mb-5">
                                        <img className="me-3 rounded-circle avatar-sm" src={user1} alt="" />
                                        <div className="">
                                            <h4 className="font-size-14 m-0">Humberto D. Champion</h4>
                                            <small className="text-muted">support@domain.com</small>
                                        </div>
                                    </div>

                                    <h4 className="mt-0 font-size-18">This Week's Top Stories</h4>

                                    <p>Dear Lorem Ipsum,</p>
                                    <p>Praesent dui ex, dapibus eget mauris ut, finibus vestibulum enim. Quisque arcu leo, facilisis in fringilla id, luctus in tortor. Nunc vestibulum est quis orci varius viverra. Curabitur dictum volutpat massa vulputate molestie. In at felis ac velit maximus
                                                convallis.</p>
                                    <p>Sed elementum turpis eu lorem interdum, sed porttitor eros commodo. Nam eu venenatis tortor, id lacinia diam. Sed aliquam in dui et porta. Sed bibendum orci non tincidunt ultrices. Vivamus fringilla, mi lacinia dapibus condimentum, ipsum urna lacinia
                                                lacus, vel tincidunt mi nibh sit amet lorem.</p>
                                    <p>Sincerly,</p>
                                    <hr />

                                    <Row>
                                        <Col lg={2} md={4}>
                                            <Card className="mb-4">
                                                <img className="card-img-top img-fluid" src={user2} alt="" />
                                                <div className="py-2 text-center">
                                                    <Link to="" className="text-muted font-600">Download</Link>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col lg={2} md={4}>
                                            <Card className="mb-4">
                                                <img className="card-img-top img-fluid" src={user3} alt="" />
                                                <div className="py-2 text-center">
                                                    <Link to="" className="text-muted font-600">Download</Link>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Link to="email-compose" className="btn btn-secondary waves-effect mt-4"><i className="mdi mdi-reply"></i> Reply</Link>
                                </CardBody>

                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(EmailRead);