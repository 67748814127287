import React , {useEffect} from "react"
import { connect } from "react-redux";

import { setBreadcrumbItems } from '../../store/actions';

//import images
import logo from '../../assets/images/logo.png';

//Import Countdown
import Countdown from "react-countdown"

const PageComingSoon = props => {
   ///breadcrumbItems

   const breadcrumbItems = [
    {
      title: 'Coming Soon',
      link: '#'
    }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Coming Soon', breadcrumbItems)
  })

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <span>You are good to go!</span>
        } else {
          return (
            <>
              <div className="coming-box">
                {days} <span>Days</span>
              </div>{" "}
              <div className="coming-box">
                {hours} <span>Hours</span>
              </div>{" "}
              <div className="coming-box">
                {minutes} <span>Minutes</span>
              </div>{" "}
              <div className="coming-box">
                {seconds} <span>Seconds</span>
              </div>
            </>
          )
        }
      }
    return (
       <React.Fragment>
           <div className="my-5 pt-sm-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <a href="index.html">
                                <img src={logo} alt="logo" height="30" />
                            </a>
                            
                            <h3 className="mt-5">Coming Soon</h3>
                            <p>We are working hard to launch a new website with new features. </p>

                            <div className="row justify-content-center mt-5">
                                <div className="col-md-7 counter-number">
                                <Countdown date="2021/12/31" renderer={renderer} />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(PageComingSoon);