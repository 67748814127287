import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

//import images
import user1 from '../../assets/images/small/img-1.jpg';
import user2 from '../../assets/images/small/img-2.jpg';
import user3 from '../../assets/images/small/img-3.jpg';
import user4 from '../../assets/images/small/img-4.jpg';
import user5 from '../../assets/images/small/img-5.jpg';
import user6 from '../../assets/images/small/img-6.jpg';
import user7 from '../../assets/images/small/img-7.jpg';

const UiCard = props => {
    const breadcrumbItems = [
        {
            title: 'Cards',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Cards', breadcrumbItems)
    });
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Ui" />
                        : null}
                    <Row>
                        <Col md={6} lg={6} xl={3}>


                            <Card>
                                <img className="card-img-top img-fluid" src={user1} alt="" />
                                <CardBody>
                                    <h5 className="card-title">Card title</h5>
                                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    <Link to="#" className="btn btn-primary waves-effect waves-light">Button</Link>
                                </CardBody>
                            </Card>

                        </Col>

                        <Col md={6} lg={6} xl={3}>

                            <Card>
                                <img className="card-img-top img-fluid" src={user2} alt="" />
                                <CardBody>
                                    <h4 className="card-title">Card title</h4>
                                    <p className="card-text">Some quick example text to build on the card title and make
                                            up the bulk of the card's content.</p>
                                </CardBody>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">Cras justo odio</li>
                                    <li className="list-group-item">Dapibus ac facilisis in</li>
                                </ul>
                                <CardBody>
                                    <Link to="#" className="card-link">Card link</Link>
                                    <Link to="#" className="card-link">Another link</Link>
                                </CardBody>
                            </Card>

                        </Col>

                        <Col md={6} lg={6} xl={3}>

                            <Card>
                                <img className="card-img-top img-fluid" src={user3} alt="" />
                                <CardBody>
                                    <p className="card-text">Some quick example text to build on the card title and make
                                            up the bulk of the card's content.</p>
                                </CardBody>
                            </Card>

                        </Col>


                        <Col md={6} lg={6} xl={3}>

                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Card title</h4>
                                    <h6 className="card-subtitle text-muted">Support card subtitle</h6>
                                </CardBody>
                                <img className="img-fluid" src={user4} alt="" />
                                <CardBody>
                                    <p className="card-text">Some quick example text to build on the card title and make
                                            up the bulk of the card's content.</p>
                                    <Link to="#" className="card-link">Card link</Link>
                                    <Link to="#" className="card-link">Another link</Link>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>


                    <Row>
                        <div className="col-md-6">
                            <div className="card card-body">
                                <h3 className="card-title">Special title treatment</h3>
                                <p className="card-text">With supporting text below as a natural lead-in to additional
                                        content.</p>
                                <Link to="#" className="btn btn-primary waves-effect waves-light">Go somewhere</Link>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card card-body">
                                <h3 className="card-title">Special title treatment</h3>
                                <p className="card-text">With supporting text below as a natural lead-in to additional
                                        content.</p>
                                <Link to="#" className="btn btn-primary waves-effect waves-light">Go somewhere</Link>
                            </div>
                        </div>
                    </Row>


                    <Row>
                        <div className="col-lg-4">
                            <div className="card card-body">
                                <h4 className="card-title">Special title treatment</h4>
                                <p className="card-text">With supporting text below as a natural lead-in to additional
                                        content.</p>
                                <Link to="#" className="btn btn-primary waves-effect waves-light">Go somewhere</Link>
                            </div>
                        </div>

                        <div className="col-lg-4 ">
                            <div className="card card-body text-center">
                                <h4 className="card-title">Special title treatment</h4>
                                <p className="card-text">With supporting text below as a natural lead-in to additional
                                        content.</p>
                                <Link to="#" className="btn btn-primary waves-effect waves-light">Go somewhere</Link>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card card-body text-end">
                                <h4 className="card-title">Special title treatment</h4>
                                <p className="card-text">With supporting text below as a natural lead-in to additional
                                        content.</p>
                                <Link to="#" className="btn btn-primary waves-effect waves-light">Go somewhere</Link>
                            </div>
                        </div>
                    </Row>



                    <Row>
                        <div className="col-lg-4">
                            <Card>
                                <h5 className="card-header mt-0">Featured</h5>
                                <CardBody>
                                    <h4 className="card-title">Special title treatment</h4>
                                    <p className="card-text">With supporting text below as a natural lead-in to
                                            additional content.</p>
                                    <Link to="#" className="btn btn-primary">Go somewhere</Link>
                                </CardBody>
                            </Card>
                        </div>

                        <div className="col-lg-4">
                            <Card>
                                <div className="card-header">
                                    Quote
                                    </div>
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-muted m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </Card>
                        </div>

                        <div className="col-lg-4">
                            <Card>
                                <div className="card-header">
                                    Featured
                                    </div>
                                <CardBody>
                                    <h4 className="card-title">Special title treatment</h4>
                                    <p className="card-text">With supporting text below as a natural lead-in to
                                            additional content.</p>
                                    <Link to="#" className="btn btn-primary waves-effect waves-light">Go somewhere</Link>
                                </CardBody>
                                <div className="card-footer text-muted">
                                    2 days ago
                                    </div>
                            </Card>
                        </div>
                    </Row>



                    <Row>
                        <div className="col-lg-4">
                            <Card>
                                <img className="card-img-top img-fluid" src={user5} alt="" />
                                <CardBody>
                                    <h4 className="card-title">Card title</h4>
                                    <p className="card-text">This is a wider card with supporting text below as a
                                    natural lead-in to additional content. This content is a little bit
                                            longer.</p>
                                    <p className="card-text">
                                        <small className="text-muted">Last updated 3 mins ago</small>
                                    </p>
                                </CardBody>
                            </Card>
                        </div>

                        <div className="col-lg-4">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Card title</h4>
                                    <p className="card-text">This is a wider card with supporting text below as a
                                    natural lead-in to additional content. This content is a little bit
                                            longer.</p>
                                    <p className="card-text">
                                        <small className="text-muted">Last updated 3 mins ago</small>
                                    </p>
                                </CardBody>
                                <img className="card-img-bottom img-fluid" src={user6} alt="" />
                            </Card>
                        </div>

                        <div className="col-lg-4">
                            <div className="card card-inverse">
                                <img className="card-img img-fluid" src={user7} alt="" />
                                <div className="card-img-overlay">
                                    <h4 className="card-title">Card title</h4>
                                    <p className="card-text">This is a wider card with supporting text below as a
                                    natural lead-in to additional content. This content is a little bit
                                            longer.</p>
                                    <p className="card-text">
                                        <small className="text-muted">Last updated 3 mins ago</small>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </Row>


                    <Row>
                        <div className="col-lg-4">
                            <div className="card text-white" style={{ backgroundColor: '#333', borderColor: '#333' }}>
                                <CardBody>
                                    <h3 className="card-title">Special title treatment</h3>
                                    <p className="card-text">With supporting text below as a natural lead-in to
                                            additional content.</p>
                                    <Link to="#" className="btn btn-primary">Button</Link>
                                </CardBody>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card text-white bg-primary">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card text-white bg-success">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>
                    </Row>



                    <Row>
                        <div className="col-lg-4">
                            <div className="card text-white bg-info">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card text-white bg-warning">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card text-white bg-danger">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>
                    </Row>


                    <Row>
                        <div className="col-lg-4">
                            <div className="card text-light bg-dark">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-light m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card text-white bg-pink">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card text-white bg-purple">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>
                    </Row>


                    <Row>
                        <div className="col-lg-4">
                            <div className="card text-white bg-indigo">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card text-white bg-teal">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card text-white bg-lime">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>
                    </Row>


                    <Row>
                        <div className="col-lg-4">
                            <div className="card text-white bg-orange">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card text-white bg-brown">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="card text-white bg-blue-grey">
                                <CardBody>
                                    <blockquote className="card-blockquote mb-0">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                                erat a ante.</p>
                                        <footer className="blockquote-footer text-white m-0">
                                            Someone famous in <cite title="Source Title">Source Title</cite>
                                        </footer>
                                    </blockquote>
                                </CardBody>
                            </div>
                        </div>
                    </Row>


                    <h4 className="my-4">Card groups</h4>
                    <Row>
                        <div className="col-12">
                            <div className="card-group">
                                <Card>
                                    <img className="card-img-top img-fluid" src={user1} alt="" />
                                    <CardBody>
                                        <h4 className="card-title">Card title</h4>
                                        <p className="card-text">This is a wider card with supporting text below as a
                                        natural lead-in to additional content. This content is a little bit
                                                longer.</p>
                                        <p className="card-text">
                                            <small className="text-muted">Last updated 3 mins ago</small>
                                        </p>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <img className="card-img-top img-fluid" src={user2} alt="" />
                                    <CardBody>
                                        <h4 className="card-title">Card title</h4>
                                        <p className="card-text">This card has supporting text below as a natural
                                                lead-in to additional content.</p>
                                        <p className="card-text">
                                            <small className="text-muted">Last updated 3 mins ago</small>
                                        </p>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <img className="card-img-top img-fluid" src={user3} alt="" />
                                    <CardBody>
                                        <h4 className="card-title">Card title</h4>
                                        <p className="card-text">This is a wider card with supporting text below as a
                                        natural lead-in to additional content. This card has even longer content
                                                than the first to show that equal height action.</p>
                                        <p className="card-text">
                                            <small className="text-muted">Last updated 3 mins ago</small>
                                        </p>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Row>


                    <h4 className="my-4">Grid</h4>
                    <div className="row row-cols-md-3 row-cols-1">
                        <div className="col">
                            <Card>
                                <img className="card-img-top img-fluid" src={user4} alt="" />
                                <CardBody>
                                    <h4 className="card-title">Card title</h4>
                                    <p className="card-text">This is a longer card with supporting text below as
                                    a natural lead-in to additional content. This content is a little
                                            bit longer.</p>
                                    <p className="card-text">
                                        <small className="text-muted">Last updated 3 mins ago</small>
                                    </p>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col">
                            <Card>
                                <img className="card-img-top img-fluid" src={user5} alt="" />
                                <CardBody>
                                    <h4 className="card-title">Card title</h4>
                                    <p className="card-text">This card has supporting text below as a natural
                                            lead-in to additional content.</p>
                                    <p className="card-text">
                                        <small className="text-muted">Last updated 3 mins ago</small>
                                    </p>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col">
                            <Card>
                                <img className="card-img-top img-fluid" src={user6} alt="" />
                                <CardBody>
                                    <h4 className="card-title">Card title</h4>
                                    <p className="card-text">This is a wider card with supporting text below as
                                    a natural lead-in to additional content. This card has even longer
                                            content than the first to show that equal height action.</p>
                                    <p className="card-text">
                                        <small className="text-muted">Last updated 3 mins ago</small>
                                    </p>
                                </CardBody>
                            </Card>
                        </div>
                    </div>

                    <Row>
                        <div className="col-lg-12">
                            <h4 className="my-4">Masonry</h4>
                            <Row data-masonry='{"percentPosition": true }' style={{ position: 'relative' }}>
                                <div className="col-lg-4 col-sm-6">
                                    <Card>
                                        <img className="card-img-top img-fluid" src={user1} alt="" />
                                        <CardBody>
                                            <h4 className="card-title">Card title that wraps to a new line</h4>
                                            <p className="card-text">This is a longer card with supporting text below as a
                                            natural lead-in to additional content. This content is a little bit
                                            longer.</p>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <Card>
                                        <img className="card-img-top img-fluid" src={user2} alt="" />
                                        <CardBody>
                                            <h4 className="card-title">Card title</h4>
                                            <p className="card-text">This card has supporting text below as a natural
                                            lead-in to additional content.</p>
                                            <p className="card-text">
                                                <small className="text-muted">Last updated 3 mins ago</small>
                                            </p>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <Card>
                                        <img className="card-img img-fluid" src={user3} alt="" />
                                    </Card>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card card-body text-xs-right">
                                        <blockquote className="card-blockquote mb-0">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                            erat a ante.</p>
                                            <footer className="blockquote-footer text-muted m-0">
                                                <small>Someone famous in <cite title="Source Title">Source Title</cite></small>
                                            </footer>
                                        </blockquote>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card card-body">
                                        <blockquote className="card-blockquote mb-0">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                            erat a ante.</p>
                                            <footer className="blockquote-footer text-muted m-0">
                                                <small>Someone famous in <cite title="Source Title">Source Title</cite></small>
                                            </footer>
                                        </blockquote>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card card-body">
                                        <h4 className="card-title">Card title</h4>
                                        <p className="card-text">This is a wider card with supporting text below as a
                                        natural lead-in to additional content. This card has even longer content
                                        than the first to show that equal height action.</p>
                                        <p className="card-text">
                                            <small className="text-muted">Last updated 3 mins ago</small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card card-body text-white bg-primary">
                                        <blockquote className="card-blockquote mb-0">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                                            erat a ante.</p>
                                            <footer className="blockquote-footer text-white m-0">
                                                <small>Someone famous in <cite title="Source Title">Source Title</cite></small>
                                            </footer>
                                        </blockquote>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="card card-body">
                                        <h4 className="card-title">Card title</h4>
                                        <p className="card-text">This card has supporting text below as a natural lead-in to additional content</p>
                                        <p className="card-text">
                                            <small className="text-muted">Last updated 3 mins ago</small>
                                        </p>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(UiCard);
