import React, { useEffect } from "react"
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap"
import Vector from "./LeafletMap/Vectormap"

const MapsVector = props => {
  ///breadcrumbItems

  const breadcrumbItems = [
    {
      title: 'Vector Map',
      link: '#'
    }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Vector Map', breadcrumbItems)
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Breadcrumb */}
          {props.layoutType === 'horizontal' ?
            <Breadcrumb breadcrumbItem="Maps" />
            : null}

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>World Map</CardTitle>
                  <CardSubtitle className="mb-3">
                    Example of vector map.
                  </CardSubtitle>

                  <div id="world-map-markers" className="vector-map-height">
                    <Vector
                      value="world_mill"
                      width="500"
                      color="rgb(98, 110, 212)"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>USA Map</CardTitle>
                  <CardSubtitle className="mb-3">
                    Example of vector map.
                  </CardSubtitle>

                  <div id="usa" className="vector-map-height">
                    <Vector
                      value="us_aea"
                      width="500"
                      color="rgb(98, 110, 212)"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Canada Map</CardTitle>
                  <CardSubtitle className="mb-3">
                    Example of vector map.
                  </CardSubtitle>

                  <div id="uk" className="vector-map-height">
                    <Vector
                      value="ca_lcc"
                      width="500"
                      color="rgb(98, 110, 212)"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Asia Vector Map</CardTitle>
                  <CardSubtitle className="mb-3">
                    Example of vector map.
                  </CardSubtitle>

                  <div id="chicago" className="vector-map-height">
                    <Vector
                      value="asia_mill"
                      width="500"
                      color="rgb(98, 110, 212)"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  const Layout = state.Layout;
  return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(MapsVector);