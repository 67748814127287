import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Form, Label, Row, Button, Input } from 'reactstrap';
import { connect } from "react-redux";
import { setBreadcrumbItems } from '../store/actions';
//Breadcrumb
import Breadcrumb from '../../src/common/Breadcrumb';

const Contact = props => {

    const breadcrumbItems = [
        {
            title: 'Contact',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Contact', breadcrumbItems)
    })
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb />
                        : null}

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={12}>
                                            <div className="text-center mt-2">
                                                <h5>Have any questions?</h5>
                                                <p className="text-muted">It is a long established fact that a reader will be of a page when looking at its layout.</p>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="mt-5">
                                        <Col md={4}>
                                            <div>
                                                <h6 className="font-size-14">Email Address</h6>
                                                <p className="text-muted">supportemail@admiria  .com</p>
                                            </div>
                                            <div className="pt-3">
                                                <h6 className="font-size-14">Telephone number</h6>
                                                <p className="text-muted">+123 45 56 879</p>
                                            </div>
                                            <div className="pt-3">
                                                <h6 className="font-size-14">Address</h6>
                                                <p className="text-muted">09 Design Street, Downtown Victoria, Australia</p>
                                            </div>
                                        </Col>
                                        <Col md={8}>
                                            <Form className="form-custom">
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="sr-only form-label" htmlFor="username">Name</Label>
                                                            <Input type="text" className="form-control" id="username" placeholder="Your Name" required="" />
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-3">
                                                            <Label className="sr-only form-label" htmlFor="useremail">Email address</Label>
                                                            <Input type="email" className="form-control" id="useremail" placeholder="Your Email" required="" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <Label className="sr-only form-label" htmlFor="usersubject">Subject</Label>
                                                            <Input type="text" className="form-control" id="usersubject" placeholder="Subject" required="" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <div className="mb-3">
                                                            <textarea className="form-control" rows="5" placeholder="Your Message...."></textarea>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12} className="text-center">
                                                        <Button color="primary" type="button">Send Message</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(Contact);
