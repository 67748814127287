import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Button, Container, Row, DropdownToggle, UncontrolledDropdown, DropdownMenu } from 'reactstrap';
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';


const UiButton = props => {
  const breadcrumbItems = [
    {
      title: 'Buttons',
      link: '#'
    }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Buttons', breadcrumbItems)
  });
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Breadcrumb */}
          {props.layoutType === 'horizontal' ?
            <Breadcrumb breadcrumbItem="Ui" />
            : null}
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Examples</h4>
                  <p className="card-title-desc">Bootstrap includes six predefined button styles, each serving its own semantic purpose.</p>

                  <div className="button-items">
                    <Button color="primary" type="button" className="btn waves-effect waves-light">Primary</Button>

                    <Button color="secondary" type="button" className="btn waves-effect">Secondary</Button>

                    <Button color="success" type="button" className="btn waves-effect waves-light">Success</Button>

                    <Button color="info" type="button" className="btn waves-effect waves-light">Info</Button>

                    <Button color="warning" type="button" className="btn waves-effect waves-light">Warning</Button>

                    <Button color="danger" type="button" className="btn waves-effect waves-light">Danger</Button>

                    <Button color="dark" type="button" className="btn waves-effect waves-light">Dark</Button>

                    <Button color="link" type="button" className="btn waves-effect">Link</Button>

                    <Button color="light" type="button" className="btn waves-effect">Light</Button>

                    <Button color="purple" type="button" className="btn waves-effect waves-light">Purple</Button>

                    <Button color="pink" type="button" className="btn waves-effect waves-light">Pink</Button>

                    <Button color="brown" type="button" className="btn waves-effect waves-light">Brown</Button>

                    <button type="button" className="btn btn-blue-grey waves-effect waves-light">Blue-grey</button>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Outline buttons</h4>
                  <p className="card-title-desc">In need of a button, but not the hefty
                  background colors they bring? Replace the default modifier classes with
                                            the <code className="highlighter-rouge">.btn-outline-*</code> ones to remove
                                            all background images and colors on any button.</p>

                  <div className="button-items">
                    <button type="button" className="btn btn-outline-primary waves-effect waves-light">Primary</button>
                    <button type="button" className="btn btn-outline-secondary waves-effect">Secondary</button>
                    <button type="button" className="btn btn-outline-success waves-effect waves-light">Success</button>
                    <button type="button" className="btn btn-outline-info waves-effect waves-light">Info</button>
                    <button type="button" className="btn btn-outline-warning waves-effect waves-light">Warning</button>
                    <button type="button" className="btn btn-outline-danger waves-effect waves-light">Danger</button>
                    <button type="button" className="btn btn-outline-dark waves-effect waves-light">Dark</button>
                    <button type="button" className="btn btn-outline-pink waves-effect waves-light">Pink</button>
                    <button type="button" className="btn btn-outline-purple waves-effect waves-light">Purple</button>
                    <button type="button" className="btn btn-outline-light waves-effect waves-light">Light</button>
                    <button type="button" className="btn btn-outline-brown waves-effect waves-light">Brown</button>
                    <button type="button" className="btn btn-outline-blue-grey waves-effect waves-light">Blue Grey</button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Button tags</h4>
                  <p className="card-title-desc">The <code className="highlighter-rouge">.btn</code>
                                            classes are designed to be used with the <code
                      className="highlighter-rouge">&lt;button&gt;</code> element.
                                            However, you can also use these classes on <code
                      className="highlighter-rouge">&lt;a&gt;</code> or <code
                        className="highlighter-rouge">&lt;input&gt;</code> elements (though
                                            some browsers may apply a slightly different rendering).</p>

                  <div className="button-items">
                    <Link className="btn btn-primary waves-effect waves-light" to="#" role="button">Link</Link>
                    <button className="btn btn-success waves-effect waves-light" type="submit">Button</button>
                    <input className="btn btn-info" type="button" value="Input" />
                    <input className="btn btn-purple" type="submit" value="Submit" />
                    <input className="btn btn-brown" type="reset" value="Reset" />
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Sizes</h4>
                  <p className="card-title-desc">Fancy larger or smaller buttons? Add
                                            <code className="highlighter-rouge">.btn-lg</code> or <code
                      className="highlighter-rouge">.btn-sm</code> for additional sizes.
                                        </p>

                  <div className="button-items">
                    <button type="button" className="btn btn-primary btn-lg waves-effect waves-light">Large button</button>
                    <button type="button" className="btn btn-secondary btn-lg waves-effect">Large button</button>
                    <button type="button" className="btn btn-pink btn-sm waves-effect waves-light">Small button</button>
                    <button type="button" className="btn btn-secondary btn-sm waves-effect">Small button</button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Block Buttons</h4>
                  <p className="card-title-desc">Create block level buttons—those that
                                            span the full width of a parent—by adding <code
                      className="highlighter-rouge">.d-grid</code>.</p>
                  <div className="button-items">
                    <div className="d-grid">
                      <button type="button" className="btn btn-primary btn-lg w-100 waves-effect waves-light">Block level button</button>
                      <button type="button" className="btn btn-secondary btn-sm w-100 waves-effect mt-2">Block level button</button>
                    </div>

                  </div>

                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Toggle states</h4>
                  <p className="card-title-desc">Add <code className="highlighter-rouge">data-bs-toggle="button"</code>
                                            to toggle a button’s <code className="highlighter-rouge">active</code>
                                            state. If you’re pre-toggling a button, you must manually add the <code
                      className="highlighter-rouge">.active</code> class
                                            <strong>and</strong> <code
                      className="highlighter-rouge">aria-pressed="true"</code> to the
                                            <code className="highlighter-rouge">&lt;button&gt;</code>.
                                        </p>

                  <div className="button-items">
                    <button type="button" className="btn btn-success waves-effect waves-light" data-bs-toggle="button" aria-pressed="false">
                      Single toggle
                                            </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Checkbox buttons</h4>
                  <p className="card-title-desc">Bootstrap’s <code
                    className="highlighter-rouge">.button</code> styles can be applied to
                                            other elements, such as <code className="highlighter-rouge">
                      &lt;label&gt;</code>s, to provide checkbox or radio style button
                                            toggling. Add <code
                      className="highlighter-rouge">data-bs-toggle="buttons"</code> to a
                                            <code className="highlighter-rouge">.btn-group</code> containing those
                                            modified buttons to enable toggling in their respective styles.</p>
                  <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                    <input type="checkbox" className="btn-check" id="btncheck1" />
                    <label className="btn btn-secondary" htmlFor="btncheck1">Checked-1</label>

                    <input type="checkbox" className="btn-check" id="btncheck2" />
                    <label className="btn btn-secondary" htmlFor="btncheck2">Checked-2</label>

                    <input type="checkbox" className="btn-check" id="btncheck3" />
                    <label className="btn btn-secondary" htmlFor="btncheck3">Checked-3</label>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Radio buttons</h4>
                  <p className="card-title-desc">Bootstrap’s <code
                    className="highlighter-rouge">.button</code> styles can be applied to
                                            other elements, such as <code className="highlighter-rouge">
                      &lt;label&gt;</code>s, to provide checkbox or radio style button
                                            toggling. Add <code
                      className="highlighter-rouge">data-bs-toggle="buttons"</code> to a
                                            <code className="highlighter-rouge">.btn-group</code> containing those
                                            modified buttons to enable toggling in their respective styles.</p>
                  <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                    <input type="checkbox" className="btn-check" id="btncheck4" />
                    <label className="btn btn-primary active" htmlFor="btncheck4">Active</label>

                    <input type="checkbox" className="btn-check" id="btncheck5" />
                    <label className="btn btn-primary" htmlFor="btncheck5">Radio</label>

                    <input type="checkbox" className="btn-check" id="btncheck6" />
                    <label className="btn btn-primary" htmlFor="btncheck6">Radio</label>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Button group</h4>
                  <p className="card-title-desc">Wrap a series of buttons with <code
                    className="highlighter-rouge">.btn</code> in <code
                      className="highlighter-rouge">.btn-group</code>.</p>

                  <div className="btn-group" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-secondary">Left</button>
                    <button type="button" className="btn btn-secondary">Middle</button>
                    <button type="button" className="btn btn-secondary">Right</button>
                  </div>

                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Button toolbar</h4>
                  <p className="card-title-desc">Combine sets of button groups into
                  button toolbars for more complex components. Use utility classes as
                                            needed to space out groups, buttons, and more.</p>

                  <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group me-2" role="group" aria-label="First group">
                      <button type="button" className="btn btn-secondary">1</button>
                      <button type="button" className="btn btn-secondary">2</button>
                      <button type="button" className="btn btn-secondary">3</button>
                      <button type="button" className="btn btn-secondary">4</button>
                    </div>
                    <div className="btn-group me-2" role="group" aria-label="Second group">
                      <button type="button" className="btn btn-secondary">5</button>
                      <button type="button" className="btn btn-secondary">6</button>
                      <button type="button" className="btn btn-secondary">7</button>
                    </div>
                    <div className="btn-group" role="group" aria-label="Third group">
                      <button type="button" className="btn btn-secondary">8</button>
                    </div>
                  </div>


                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Sizing</h4>
                  <p className="card-title-desc">Instead of applying button sizing
                                            classes to every button in a group, just add <code
                      className="highlighter-rouge">.btn-group-*</code> to each <code
                        className="highlighter-rouge">.btn-group</code>, including each one
                                            when nesting multiple groups.</p>

                  <div className="btn-group btn-group-lg" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-secondary">Left</button>
                    <button type="button" className="btn btn-secondary">Middle</button>
                    <button type="button" className="btn btn-secondary">Right</button>
                  </div>

                  <br />

                  <div className="btn-group mt-2" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-secondary">Left</button>
                    <button type="button" className="btn btn-secondary">Middle</button>
                    <button type="button" className="btn btn-secondary">Right</button>
                  </div>

                  <br />

                  <div className="btn-group btn-group-sm mt-2" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-secondary">Left</button>
                    <button type="button" className="btn btn-secondary">Middle</button>
                    <button type="button" className="btn btn-secondary">Right</button>
                  </div>

                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>

                  <h4 className="card-title">Vertical variation</h4>
                  <p className="card-title-desc">Make a set of buttons appear vertically
                  stacked rather than horizontally. Split button dropdowns are not
                                            supported here.</p>

                  <div className="btn-group-vertical" role="group" aria-label="Vertical button group">
                    <button type="button" className="btn btn-secondary">Button</button>
                    <UncontrolledDropdown>
                      <DropdownToggle id="btnGroupVerticalDrop1" type="button" className="btn btn-secondary">
                        Dropdown
                        <i className="mdi mdi-chevron-down"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1">
                        <Link className="dropdown-item" to="#">Dropdown link</Link>
                        <Link className="dropdown-item" to="#">Dropdown link</Link>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <button type="button" className="btn btn-secondary">Button</button>
                    <button type="button" className="btn btn-secondary">Button</button>
                  </div>

                </CardBody>
              </Card>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
}
const mapStatetoProps = state => {
  const Layout = state.Layout;
  return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(UiButton);
