import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { Link } from "react-router-dom"

const Breadcrumb = props => {

  return (
    <React.Fragment>

      {props.layout.layoutType === 'vertical' ?
        <div className="d-none d-sm-block ms-2">
          <div className="page-title">
            {props.BreadcrumbData['title']}
          </div>
        </div>
        :
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">{props.BreadcrumbData['title']}</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item"><Link to="#">{props.breadcrumbItem}</Link></li>
                  <li className="breadcrumb-item active">{props.BreadcrumbData['title']}</li>
                </ol>
              </div>

            </div>
          </div>
        </div>
      }


    </React.Fragment>
  );
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  layout: PropTypes.any
}

const mapStatetoProps = state => {
  const BreadcrumbData = state.Breadcrumb;
  return { layout: state.Layout, BreadcrumbData };
};

export default connect(mapStatetoProps, null)(Breadcrumb);