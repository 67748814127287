import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container,Alert, Row } from 'reactstrap';

import BackGround from '../../assets/images/bg.jpg';
import logo from '../../assets/images/logo.png';

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"

// import images
// import profileImg from "../../assets/images/profile-img.png"
// import logoImg from "../../assets/images/logo.svg"

const Register = props => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.registerUser(values)
  }

  useEffect(() => {
    props.apiError("")
  });

  return (
    <React.Fragment>
      <div className="accountbg" style={{ background: `url(${BackGround}) center`, backgroundPosition: 'cover' }}></div>

<div className="account-pages mt-5 pt-5">
    <Container>
        <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
                <Card>
                    <CardBody>
                        <div className="text-center mt-4">
                            <div className="mb-3">
                                <Link to="/dashboard"><img src={logo} height="30" alt="logo" /></Link>
                            </div>
                        </div>
                        <div className="p-3">
                            <h4 className="font-size-18 mt-2 text-center">Free Register</h4>
                            <p className="text-muted text-center mb-4">Get your free Admiria account now.</p>

                            <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.user && props.user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}
            
                      {props.registrationError &&
                      props.registrationError ? (
                        <Alert color="danger">
                          {props.registrationError}
                        </Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="username"
                          label="Username"
                          type="text"
                          required
                          placeholder="Enter username"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Skote{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </AvForm>

                        </div>

                    </CardBody>
                </Card>
                <div className="mt-5 text-center position-relative">
                    <p className="text-white">Already have an account ?  <Link to="/login" className="font-weight-bold text-primary"> Login </Link> </p>
                    <p className="text-white">2017 - {new Date().getFullYear()} © Admiria. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                </div>

            </Col>
        </Row>
    </Container>
</div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
