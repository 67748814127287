import React, { useEffect } from 'react';
import Highlight from 'react-highlight'
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

const UiHighlight = props => {
    const breadcrumbItems = [
        {
            title: 'Highlight',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Highlight', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Ui" />
                        : null}
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">HTML Highlight</h4>
                                    <p className="card-title-desc">Prism is a
                                    lightweight, extensible syntax
                                    highlighter, built with modern web
                                            standards in mind.</p>

                                    <Highlight className="language-markup mb-0">
                                        <code className="language-markup">
                                            {"<html>"}<br />
                                            {"  <!-- this is a comment -->"}<br></br>
                                            {"<head>"}<br></br>
                                            {"   <title>HTML Example</title>"}<br></br>
                                            {"</head>"}<br></br>

                                            {"<body>"}<br></br>
                                            {"    The indentation tries to be <em>somewhat &quot;do what"}<br></br>
                                            {"    I mean&quot;</em>... but might not match your style."}<br></br>
                                            {"</body>"}<br></br>
                                            {"</html>"}<br></br>
                                        </code>
                                    </Highlight>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Css Highlight</h4>
                                    <p className="card-title-desc">Prism is a
                                    lightweight, extensible syntax
                                    highlighter, built with modern web
                                            standards in mind.</p>

                                    <pre className="line-numbers mb-0">
                                        <code className="language-css">
                                            .example {`{
    font - family: Georgia, Times, serif;
    color: #555;
    text-align: center;
}`}
                                        </code>
                                    </pre>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>



                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(UiHighlight);
