import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [ui, setui] = useState(false)
  const [app, setapp] = useState(false)
  const [email, setemail] = useState(false)
  const [ecommerce, setecommerce] = useState(false)
  const [crypto, setcrypto] = useState(false)
  const [component, setcomponent] = useState(false)
  const [table, settable] = useState(false)
  const [chart, setchart] = useState(false)
  const [icon, seticon] = useState(false)
  const [map, setmap] = useState(false)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      setdashboard(!dashboard)
                    }}
                    to="dashboard"
                  >
                    <i className="bx bx-home-circle ms-2"></i>
                    {props.t("Dashboard")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: dashboard })}
                  >
                    <Link to="dashboard" className="dropdown-item">
                      {props.t("Dashboard")}
                    </Link>
                    <Link to="dashboard-2" className="dropdown-item">
                      {props.t("Dashboard 2")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setui(!ui)
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-tone ms-2"></i>
                    {props.t("UI Kit")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname(
                      "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                      { show: ui }
                    )}
                  >
                    <Row>
                      <Col lg={4}>
                        <div>
                          <Link to="ui-button" className="dropdown-item">
                            {props.t("Button")}
                          </Link>
                          <Link to="ui-color" className="dropdown-item">
                            {props.t("Color")}
                          </Link>
                          <Link to="ui-card" className="dropdown-item">
                            {props.t("Cards")}
                          </Link>
                          <Link
                            to="ui-tabs-accordions"
                            className="dropdown-item"
                          >
                            {props.t("Tabs & Accordions")}
                          </Link>
                          <Link to="ui-modal" className="dropdown-item">
                            {props.t("Modals")}
                          </Link>
                          <Link to="ui-images" className="dropdown-item">
                            {props.t("Images")}
                          </Link>
                          <Link to="ui-alert" className="dropdown-item">
                            {props.t("Alerts")}
                          </Link>
                          <Link to="ui-progressbars" className="dropdown-item">
                            {props.t("Progress Bars")}
                          </Link>
                          <Link to="ui-dropdowns" className="dropdown-item">
                            {props.t("Dropdowns")}
                          </Link>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div>
                          <Link to="ui-lightbox" className="dropdown-item">
                            {props.t("Lightbox")}
                          </Link>
                          <Link to="ui-navs" className="dropdown-item">
                            {props.t("Navs")}
                          </Link>
                          <Link to="ui-pagination" className="dropdown-item">
                            {props.t("Pagination")}
                          </Link>
                          <Link to="ui-popover-tooltips" className="dropdown-item">
                            {props.t("Popover & Tooltips")}
                          </Link>
                          <Link
                            to="ui-badge"
                            className="dropdown-item"
                          >
                            {props.t("Badge")}
                          </Link>

                          <Link to="ui-carousel" className="dropdown-item">
                            {props.t("Carousel")}
                          </Link>
                          <Link to="ui-video" className="dropdown-item">
                            {props.t("Video")}
                          </Link>
                          <Link to="ui-typography" className="dropdown-item">
                            {props.t("Typography")}
                          </Link>
                          <Link to="ui-sweet-alert" className="dropdown-item">
                            {props.t("Sweet-Alert")}
                          </Link>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div>
                          <Link to="ui-grid" className="dropdown-item">
                            {props.t("Grid")}
                          </Link>
                          <Link to="ui-animation" className="dropdown-item">
                            {props.t("Animation")}
                          </Link>
                          <Link to="ui-highlight" className="dropdown-item">
                            {props.t("Highlight")}
                          </Link>
                          <Link to="ui-rating" className="dropdown-item">
                            {props.t("Rating")}
                          </Link>
                          <Link to="ui-nestable" className="dropdown-item">
                            {props.t("Nestable")}
                          </Link>
                          <Link to="ui-rangeslider" className="dropdown-item">
                            {props.t("Range Slider")}
                          </Link>
                          <Link to="ui-sessiontimeout" className="dropdown-item">
                            {props.t("Session Timeout")}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setapp(!app)
                    }}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="bx bx-customize ms-2"></i>
                    {props.t("Components")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: app })}>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setemail(!email)
                        }}
                      >
                        {props.t("Email")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: email })}
                      >
                        <Link to="email-inbox" className="dropdown-item">
                          {props.t("Inbox")}
                        </Link>
                        <Link to="email-read" className="dropdown-item">
                          {props.t("Read Email")}
                        </Link>
                        <Link to="email-compose" className="dropdown-item">
                          {props.t("Compose Email")}
                        </Link>
                      </div>
                    </div>
                    <Link to="widgets" className="dropdown-item">
                      {props.t("Widgets")}
                    </Link>
                    <Link to="calendar" className="dropdown-item">
                      {props.t("Calendar")}
                    </Link>


                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setecommerce(!ecommerce)
                        }}
                      >
                        {props.t("Forms")}{" "}
                        <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", {
                          show: ecommerce,
                        })}
                      >
                        <Link to="form-elements" className="dropdown-item">
                          {props.t("Form Elements")}
                        </Link>
                        <Link to="form-validation" className="dropdown-item">
                          {props.t("Form Validation")}
                        </Link>
                        <Link to="form-advanced" className="dropdown-item">
                          {props.t("Form Advanced")}
                        </Link>
                        <Link to="form-wizard" className="dropdown-item">
                          {props.t("Form Wizard")}
                        </Link>
                        <Link to="form-editor" className="dropdown-item">
                          {props.t("Form Editors")}
                        </Link>
                        <Link to="form-uploads" className="dropdown-item">
                          {props.t("Form File Upload")}{" "}
                        </Link>
                        <Link to="form-mask" className="dropdown-item">
                          {props.t("Form Mask")}
                        </Link>
                        <Link to="form-xeditable" className="dropdown-item">
                          {props.t("Form Xeditable")}
                        </Link>
                      </div>
                    </div>
                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setchart(!chart)
                        }}
                      >
                        {props.t("Charts")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: chart })}
                      >
                        <Link to="apex-charts" className="dropdown-item">
                          {" "}
                          {props.t("Apex charts")}
                        </Link>
                        <Link to="chartist-charts" className="dropdown-item">
                          {" "}
                          {props.t("Chartist Chart")}
                        </Link>
                        <Link to="chartjs-charts" className="dropdown-item">
                          {" "}
                          {props.t("Chartjs Chart")}
                        </Link>
                        <Link to="e-charts" className="dropdown-item">
                          {" "}
                          {props.t("E Chart")}
                        </Link>
                        <Link to="tui-charts" className="dropdown-item">
                          {props.t("Toast UI Chart")}
                        </Link>
                        <Link to="sparkline-charts" className="dropdown-item">
                          {" "}
                          {props.t("Sparkline Chart")}
                        </Link>
                        <Link to="charts-knob" className="dropdown-item">
                          {props.t("Knob Chart")}
                        </Link>
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          settable(!table)
                        }}
                      >
                        {props.t("Tables")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: table })}
                      >
                        <Link to="tables-basic" className="dropdown-item">
                          {props.t("Basic Tables")}
                        </Link>
                        <Link to="tables-datatable" className="dropdown-item">
                          {props.t("Data Tables")}
                        </Link>
                        <Link to="tables-responsive" className="dropdown-item">
                          {props.t("Responsive Table")}
                        </Link>
                        <Link to="tables-editable" className="dropdown-item">
                          {props.t("Editable Table")}
                        </Link>
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          seticon(!icon)
                        }}
                      >
                        {props.t("Icons")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: icon })}
                      >
                        <Link
                          to="icons-materialdesign"
                          className="dropdown-item"
                        >
                          {props.t("Material Design")}
                        </Link>
                        <Link to="icons-ion" className="dropdown-item">
                          {props.t("ion Icons")}
                        </Link>
                        <Link to="icons-fontawesome" className="dropdown-item">
                          {props.t("Font awesome")}{" "}
                        </Link>
                        <Link to="icon-themify" className="dropdown-item">
                          {props.t("Themify Icons")}
                        </Link>
                        <Link to="icons-dripicons" className="dropdown-item">
                          {props.t("Dripicons")}
                        </Link>
                        <Link to="icon-typicons" className="dropdown-item">
                          {props.t("Typicons Icons")}
                        </Link>
                        <Link to="icon-weather" className="dropdown-item">
                          {props.t("Weather Icons")}
                        </Link>
                        <Link to="icon-mobirise" className="dropdown-item">
                          {props.t("Mobirise Icons")}
                        </Link>
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setmap(!map)
                        }}
                      >
                        {props.t("Maps")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: map })}
                      >
                        <Link to="maps-google" className="dropdown-item">
                          {props.t("Google Maps")}{" "}
                        </Link>
                        <Link to="maps-vector" className="dropdown-item">
                          {props.t("Vector Maps")}{" "}
                        </Link>
                      </div>
                    </div>

                    <div className="dropdown">
                      <Link
                        to="/#"
                        className="dropdown-item dropdown-toggle arrow-none"
                        onClick={e => {
                          e.preventDefault()
                          setcrypto(!crypto)
                        }}
                      >
                        {props.t("Email Templates")} <div className="arrow-down"></div>
                      </Link>
                      <div
                        className={classname("dropdown-menu", { show: crypto })}
                      >
                        <Link to="email-templates-basic" className="dropdown-item">
                          {props.t("Basic Action Email")}
                        </Link>
                        <Link to="email-templates-alert" className="dropdown-item">
                          {props.t("Alert Email")}
                        </Link>
                        <Link to="email-templates-billing" className="dropdown-item">
                          {props.t("Billing Email")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setcomponent(!component)
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-tone ms-2"></i>
                    {props.t("Pages")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname(
                      "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                      { show: component }
                    )}
                  >
                    <Row>
                      <Col lg={4}>
                        <div>
                          <Link to="page-login" className="dropdown-item">
                            {props.t("Login")}
                          </Link>
                          <Link to="page-register" className="dropdown-item">
                            {props.t("Register")}
                          </Link>
                          <Link to="page-recoverpw" className="dropdown-item">
                            {props.t("Recover Password")}
                          </Link>
                          <Link to="page-lock-screen" className="dropdown-item">
                            {props.t("Lock Screen")}
                          </Link>
                          <Link to="page-login-2" className="dropdown-item">
                            {props.t("Login 2")}
                          </Link>

                          <Link to="page-register-2" className="dropdown-item">
                            {props.t("Register 2")}
                          </Link>
                          <Link to="page-recoverpw-2" className="dropdown-item">
                            {props.t("Recover Password 2")}
                          </Link>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div>
                          <Link to="page-lock-screen-2" className="dropdown-item">
                            {props.t("Lock Screen")}
                          </Link>
                          <Link to="page-timeline" className="dropdown-item">
                            {props.t("Timeline")}
                          </Link>
                          <Link to="page-invoie" className="dropdown-item">
                            {props.t("Invoice")}
                          </Link>
                          <Link to="page-directory" className="dropdown-item">
                            {props.t("Directory")}
                          </Link>
                          <Link to="page-blank" className="dropdown-item">
                            {props.t("Blank Page")}
                          </Link>
                          <Link to="page-404" className="dropdown-item">
                            {props.t("Error 404")}
                          </Link>
                          <Link
                            to="page-500"
                            className="dropdown-item"
                          >
                            {props.t("Error 500")}
                          </Link>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div>
                          <Link to="page-pricing" className="dropdown-item">
                            {props.t("Pricing")}
                          </Link>
                          <Link to="page-gallery" className="dropdown-item">
                            {props.t("Gallery")}
                          </Link>
                          <Link to="page-maintenance" className="dropdown-item">
                            {props.t("Maintenance")}
                          </Link>
                          <Link to="page-coming-soon" className="dropdown-item">
                            {props.t("Coming Soon")}
                          </Link>
                          <Link to="faq" className="dropdown-item">
                            {props.t("FAQ")}
                          </Link>
                          <Link to="contact" className="dropdown-item">
                            {props.t("Contact")}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault()
                      settable(!table)
                    }}
                    to="product-list"
                  >
                    <i className="bx bx-home-circle ms-2"></i>
                    {props.t("Ecommerce")} {props.menuOpen}
                    <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: table })}
                  >
                    <Link to="product-list" className="dropdown-item">
                      {props.t("Product List")}
                    </Link>
                    <Link to="product-grid" className="dropdown-item">
                      {props.t("Product Grid")}
                    </Link>
                    <Link to="ecommerce-orders" className="dropdown-item">
                      {props.t("Order History")}
                    </Link>
                    <Link to="ecommerce-customers" className="dropdown-item">
                      {props.t("Customers")}
                    </Link>
                    <Link to="ecommerce-add-product" className="dropdown-item">
                      {props.t("Product Edit")}
                    </Link>
                  </div>
                </li>

              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
      
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
