import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

const Activity = props => {
    return (
        <React.Fragment>
            <Col xl={4}>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Recent Activity Feed</h4>

                        <ol className="activity-feed mb-0">
                            <li className="feed-item">
                                <span className="date">Sep 25</span>
                                <span className="activity-text">Responded to need “Volunteer Activities”</span>
                            </li>
                            <li className="feed-item">
                                <span className="date">Sep 24</span>
                                <span className="activity-text">Added an interest “Volunteer Activities”</span>
                            </li>
                            <li className="feed-item">
                                <span className="date">Sep 23</span>
                                <span className="activity-text">Joined the group “Boardsmanship Forum”</span>
                            </li>
                            <li className="feed-item">
                                <span className="date">Sep 21</span>
                                <span className="activity-text">Responded to need “In-Kind Opportunity”</span>
                            </li>
                            <li className="feed-item">
                                <span className="date">Sep 18</span>
                                <span className="activity-text">Created need “Volunteer Activities”</span>
                            </li>
                            <li className="feed-item">
                                <span className="date">Sep 17</span>
                                <span className="activity-text">Attending the event “Some New Event”</span>
                            </li>
                        </ol>

                        <div className="text-center mt-3">
                            <Link to="#" className="btn btn-sm btn-primary">Load More</Link>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default Activity;