import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import ReactApexChart from "react-apexcharts"


const EmailSent = props => {


    const series = [
        {
          name: "series1",
          data: [0, 150, 60, 180, 90, 75, 30],
        },
        {
          name: "series2",
          data: [0, 45, 150, 36, 60, 240, 30],
        },
        {
          name: "series2",
          data: [0, 15, 195, 21, 360, 120, 30],
        },
      ]
      const chartOptions = {
        chart :  {
          type: "area",
           toolbar: {
            show: false
        },
        },
        legend: {
            show: false
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 0,
        },
        colors: ["#009688", "#fb8c00", "#6d60b0"],
        xaxis: {
          type: "datetime",
          categories: ["2007", "2008", "2009", "2010", "2011", "2012", "2013"],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
        },
      }
    return (
        <React.Fragment>
            <Col xl={6}>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Email Sent</h4>

                        <Row className="text-center mt-4">
                            <Col xs={4}>
                                <h5 className="">56241</h5>
                                <p className="text-muted font-size-14">Marketplace</p>
                            </Col>
                            <Col xs={4}>
                                <h5 className="">23651</h5>
                                <p className="text-muted font-size-14">Total Income</p>
                            </Col>
                            <Col xs={4}>
                                <h5 className="">23651</h5>
                                <p className="text-muted font-size-14">Last Month</p>
                            </Col>
                        </Row>

                        <ReactApexChart options={chartOptions} series={series} type="area" height="300px" />
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default EmailSent;