import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import { Button, CardBody, Col, Container, Row } from "reactstrap"

import EcommerceListColumns from "./EcommerceListColumns"
import { getProducts } from "../../store/e-commerce/actions"

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';


const EcommerceProducts = props => {
  const { products, onGetProducts } = props
  const [modal, setModal] = useState(false)
  const [productList, setProductList] = useState([])

  const pageOptions = {
    sizePerPage: 10,
    totalSize: 50, // replace later with size(orders),
    custom: true,
  }
  const { SearchBar } = Search

  useEffect(() => {
    onGetProducts()
    setProductList(products)
  }, [onGetProducts, products])

  useEffect(() => {
    if (!isEmpty(products)) setProductList(products)
  }, [products])

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setProductList(
      products.filter(product =>
        Object.keys(product).some(
          key =>
            typeof product[key] === "string" &&
            product[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  const toggleModal = () => {
    setModal(!modal)
  }

  const breadcrumbItems = [
    {
      title: 'Product List',
      link: '#'
    }
  ]

  useEffect(() => {
    props.onSetBreadCrumbs('Product List', breadcrumbItems)
  })


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* Breadcrumb */}
          {props.layoutType === 'horizontal' ?
            <Breadcrumb breadcrumbItem="Ecommerce" />
            : null}

          <Row>
            <Col lg="12">
              {!isEmpty(productList) && (
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={productList || []}
                        columns={EcommerceListColumns(toggleModal)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded waves-effect waves-light mb-2 me-2"
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                      Add New Order
                                    </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <BootstrapTable
                                  responsive
                                  remote
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table table-striped table-centered dt-responsive nowrap table-vertical"
                                  }
                                  style={{ borderCollapse: 'collapse', borderSpacing: 0, width: '100%' }}
                                  headerWrapperClasses={"table-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                  {...paginationTableProps}
                                />
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              )}


            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EcommerceProducts.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
}

const mapStateToProps = state => {
  const Layout = state.Layout;
  return {
    layoutType: Layout.layoutType,
    products: state.ecommerce.products,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetProducts: () => dispatch(getProducts()),
  onSetBreadCrumbs: breadcrumbItems => dispatch(setBreadcrumbItems(breadcrumbItems)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EcommerceProducts))
