import React, { useEffect } from 'react';
import { Card, CardBody, Col, Row, Container, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

const UiNavs = props => {
    const breadcrumbItems = [
        {
            title: 'Navs',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Navs', breadcrumbItems)
    })
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Ui" />
                        : null}
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Base nav</h4>
                                    <p className="card-title-desc">Add class <code>.nav</code>:</p>

                                    <Nav>
                                        <NavItem>
                                            <NavLink className="active" to="#">Active</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="disabled" to="#">Disabled</NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Horizontal alignment</h4>
                                    <p className="card-title-desc">Centered with <code className="highlighter-rouge">.justify-content-center</code>:</p>

                                    <Nav className="justify-content-center">
                                        <NavItem>
                                            <NavLink className="active" to="#">Active</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="disabled" to="#">Disabled</NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Right alignment</h4>
                                    <p className="card-title-desc">Right-aligned with <code className="highlighter-rouge">.justify-content-end</code>:</p>

                                    <Nav className="justify-content-end">
                                        <NavItem>
                                            <NavLink className="active" to="#">Active</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="disabled" to="#">Disabled</NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Vertical</h4>
                                    <p className="card-title-desc">Centered with <code className="highlighter-rouge">.justify-content-center</code>:</p>

                                    <Nav className="flex-column">
                                        <NavItem>
                                            <NavLink className="active" to="#">Active</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="disabled" to="#">Disabled</NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Tabs</h4>
                                    <p className="card-title-desc">Takes the basic nav from above and adds the <code className="highlighter-rouge">.nav-tabs</code> class to generate a tabbed interface.</p>

                                    <ul className="nav nav-tabs">
                                        <NavItem>
                                            <NavLink className="active" to="#">Active</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="disabled" to="#">Disabled</NavLink>
                                        </NavItem>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Pills</h4>
                                    <p className="card-title-desc">Take that same HTML, but use <code className="highlighter-rouge">.nav-pills</code> instead:</p>

                                    <ul className="nav nav-pills">
                                        <NavItem>
                                            <NavLink className="active" to="#">Active</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#">Link</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink className="disabled" to="#">Disabled</NavLink>
                                        </NavItem>
                                    </ul>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(UiNavs);