import React from "react"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"

const EcommerceOrderColumns = toggleModal => [
  {
    dataField: "orderid",
    text: "Order ID",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.orderid}
      </Link>
    ),
  },
  {
    dataField: "amount",
    text: "Amount",
    sort: true,
  },
  {
    dataField: "date",
    text: "Date",
    sort: true,
  },
  {
    isDummyField: true,
    text: "Payment",
    sort: true,
    formatter: (cellContent, row) => (
      <>
        <i className={"fab " + row.paymenticon + " text-muted font-size-20"} />
      </>
    ),
  },
  {
    dataField: "billingname",
    text: "Billing Name",
    sort: true,
  },
  {
    dataField: "paymentstatus",
    text: "Status",
    sort: true,
    formatter: (cellContent, row) => (
      <Badge
        className={"badge bg-" + row.badge}
        color={row.badgeClass}
        pill
      >
        {row.paymentstatus}
      </Badge>
    ),
  },
  {
    isDummyField: true,
    text: "Action",
    formatter: () => (
      <>
        <Link to="#" className="me-3 text-success">
          <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
        </Link>
        <Link to="#" className="text-muted">
          <i className="mdi mdi-close font-size-18"></i>
        </Link>
      </>
    ),
  },
]

export default EcommerceOrderColumns
