import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Badge } from 'reactstrap';

import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

function UiBadge(props) {
    const breadcrumbItems = [
        {
            title: 'Badge',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Badge', breadcrumbItems)
    })
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Ui" />
                        : null}

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Example</h4>
                                    <p className="card-title-desc">Badges scale to match the size of the
                                            immediate parent element by using relative font sizing and <code
                                            className="highlighter-rouge">em</code> units.</p>

                                    <div className="">
                                        <h1>Example heading <Badge className="bg-secondary">New</Badge></h1>
                                        <h2>Example heading <Badge className="bg-secondary">New</Badge></h2>
                                        <h3>Example heading <Badge className="bg-secondary">New</Badge></h3>
                                        <h4>Example heading <Badge className="bg-secondary">New</Badge></h4>
                                        <h5>Example heading <Badge className="bg-secondary">New</Badge></h5>
                                        <h6>Example heading <Badge className="bg-secondary">New</Badge></h6>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Variations</h4>
                                    <p className="card-title-desc">Add any of the below mentioned modifier
                                            classes to change the appearance of a badge.</p>

                                    <div>
                                        <Badge className="bg-secondary me-1">Secondary</Badge>
                                        <Badge className="bg-primary me-1">Primary</Badge>
                                        <Badge className="bg-success me-1">Success</Badge>
                                        <Badge className="bg-info me-1">Info</Badge>
                                        <Badge className="bg-warning me-1">Warning</Badge>
                                        <Badge className="bg-danger me-1">Danger</Badge>
                                        <Badge className="bg-dark me-1">Dark</Badge>
                                        <Badge className="bg-pink me-1">Pink</Badge>
                                        <Badge className="bg-purple me-1">Purple</Badge>
                                        <Badge className="bg-indigo me-1">Indigo</Badge>
                                        <Badge className="bg-teal me-1">Teal</Badge>
                                        <Badge className="bg-lime me-1">Lime</Badge>
                                        <Badge className="bg-orange me-1">Orange</Badge>
                                        <Badge className="bg-brown me-1">Brown</Badge>
                                        <Badge className="bg-blue me-1-grey">Blue Grey</Badge>
                                    </div>

                                    <p className="card-title-desc mt-5">Use the <code
                                        className="highlighter-rouge">.rounded-pill</code> modifier class to make
                                            badges more rounded (with a larger <code className="highlighter-rouge">border-radius</code>
                                            and additional horizontal <code className="highlighter-rouge">padding</code>).
                                            Useful if you miss the badges from v3.</p>

                                    <div>
                                        <Badge className="bg-secondary rounded-pill me-1" >Secondary</Badge>
                                        <Badge className="bg-primary rounded-pill me-1" >Primary</Badge>
                                        <Badge className="bg-success rounded-pill me-1" >Success</Badge>
                                        <Badge className="bg-info rounded-pill me-1" >Info</Badge>
                                        <Badge className="bg-warning rounded-pill me-1" >Warning</Badge>
                                        <Badge className="bg-danger rounded-pill me-1" >Danger</Badge>
                                        <Badge className="bg-dark rounded-pill me-1" >Dark</Badge>
                                        <Badge className="bg-pink rounded-pill me-1" >Pink</Badge>
                                        <Badge className="bg-purple rounded-pill me-1" >Purple</Badge>
                                        <Badge className="bg-indigo rounded-pill me-1" >Indigo</Badge>
                                        <Badge className="bg-teal rounded-pill me-1" >Teal</Badge>
                                        <Badge className="bg-lime rounded-pill me-1" >Lime</Badge>
                                        <Badge className="bg-orange rounded-pill me-1" >Orange</Badge>
                                        <Badge className="bg-brown rounded-pill me-1" >Brown</Badge>
                                        <Badge className="bg-blue rounded-pill me-1- grey">Blue Grey</Badge>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(UiBadge);
