import React from 'react';
import c3 from "c3";

function DonutChart(props) {
    React.useEffect(() => {
        c3.generate({
            bindto: '#donut-chart',
            data: {
                columns: [
                    ['Desktops', 78],
                    ['Mobiles', 40],
                    ['Tablets', 25]
                ],
                type : 'donut'
            },
            donut: {
                title: "Candidates",
                width: 40,
                label: {
                    show:false
                }
            },
            color: {
                pattern: ["#f06292", "#6d60b0","#009688"]
            }
        });
    }, []);
    return (
        <React.Fragment>
            <div id="donut-chart" dir="ltr"></div>
        </React.Fragment>
    );
}

export default DonutChart;