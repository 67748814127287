import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import SimpleBar from "simplebar-react"
//i18n
import { withTranslation } from "react-i18next"

const NotificationDropdown = props => {
  const [socialDrp, setsocialDrp] = useState(false)

  return (
    <>
      <Dropdown
        className="d-none d-lg-inline-block me-2"
        isOpen={socialDrp}
        toggle={() => {
          setsocialDrp(!socialDrp)
        }}
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
        >
          <i className="ion ion-md-notifications" />
          <span className="badge bg-danger rounded-pill">3</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="m-0 font-size-16"> Notification (3) </h5>
              </div>
            </div>
          </div>
          <SimpleBar style={{ maxHeight: '230px' }}>
            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="mdi mdi-cart-outline"></i>
                  </span>
                </div>
                <div className="">
                  <h6 className="mt-0 font-size-15 mb-1">Your order is placed</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">Dummy text of the printing and typesetting industry.</p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-warning rounded-circle font-size-16">
                    <i className="mdi mdi-message-text-outline"></i>
                  </span>
                </div>
                <div className="">
                  <h6 className="mt-0 font-size-15 mb-1">New Message received</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">You have 87 unread messages</p>
                  </div>
                </div>
              </div>
            </Link>

            <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-info rounded-circle font-size-16">
                    <i className="mdi mdi-glass-cocktail"></i>
                  </span>
                </div>
                <div className="">
                  <h6 className="mt-0 font-size-15 mb-1">Your item is shipped</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">It is a long established fact that a reader will</p>
                  </div>
                </div>
              </div>
            </Link>

          </SimpleBar>
          <div className="p-2 border-top">
            <div className="d-grid">
              <Link to="btn btn-sm btn-link font-size-14  text-center">
                View all
                            </Link>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
}