import React from 'react';
import { Link } from 'react-router-dom';
import Knob from 'react-canvas-knob';
import { Card, CardBody, Col, Row } from 'reactstrap';

const Order = props => {

    return (
        <React.Fragment>
            <Row>
                <Col xl={3}>
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">Recent Stock</h4>

                            <div className="text-center">
                                <div dir="ltr">
                                    <Knob
                                        width={120} height={120} linecap="round"
                                        fgColor="#ffbb44" value={80} skin="tron" angleOffset={180}
                                        readOnly={true} thickness={.1}
                                        onChange={() => null}
                                    />
                                </div>

                                <Link to="#" className={"btn btn-sm btn-warning text-white mt-4"}>View All Data</Link>
                                <ul className="list-inline row mt-4 clearfix">
                                    <li className="col-6">
                                        <p className="mb-1 font-size-18 fw-bolder">7,541</p>
                                        <p className="mb-0">Mobile Phones</p>
                                    </li>
                                    <li className="col-6">
                                        <p className="mb-1 font-size-18 fw-bolder">125</p>
                                        <p className="mb-0">Desktops</p>
                                    </li>
                                </ul>

                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3}>
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">Purchase Order</h4>

                            <div className="text-center">
                                <div dir="ltr">
                                    <Knob
                                        width={120} height={120} linecap="round"
                                        fgColor="#4ac18e" value={68} skin="tron" angleOffset={180}
                                        readOnly={true} thickness={.1}
                                        onChange={() => null}
                                    />
                                </div>

                                <Link to="#" className={"btn btn-sm btn-success text-white mt-4"}>View All Data</Link>
                                <ul className="list-inline row mt-4 clearfix">
                                    <li className="col-6">
                                        <p className="mb-1 font-size-18 fw-bolder">2,541</p>
                                        <p className="mb-0">Mobile Phones</p>
                                    </li>
                                    <li className="col-6">
                                        <p className="mb-1 font-size-18 fw-bolder">874</p>
                                        <p className="mb-0">Desktops</p>
                                    </li>
                                </ul>

                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3}>
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">Shipped Orders</h4>

                            <div className="text-center">
                                <div dir="ltr">
                                    <Knob
                                        width={120} height={120} linecap="round"
                                        fgColor="#8d6e63" value={39} skin="tron" angleOffset={180}
                                        readOnly={true} thickness={.1}
                                        onChange={() => null}
                                    />
                                </div>

                                <Link to="#" className={"btn btn-sm btn-brown text-white mt-4"}>View All Data</Link>
                                <ul className="list-inline row mt-4 clearfix">
                                    <li className="col-6">
                                        <p className="mb-1 font-size-18 fw-bolder">1,154</p>
                                        <p className="mb-0">Mobile Phones</p>
                                    </li>
                                    <li className="col-6">
                                        <p className="mb-1 font-size-18 fw-bolder">89</p>
                                        <p className="mb-0">Desktops</p>
                                    </li>
                                </ul>

                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xl={3}>
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">Cancelled Orders</h4>

                            <div className="text-center">
                                <div dir="ltr">
                                    <Knob
                                        width={120} height={120} linecap="round"
                                        fgColor="#90a4ae" value={95} skin="tron" angleOffset={180}
                                        readOnly={true} thickness={.1}
                                        onChange={() => null}
                                    />
                                </div>

                                <Link to="#" className={"btn btn-sm btn-blue text-white mt-4"}>View All Data</Link>
                                <ul className="list-inline row mt-4 clearfix">
                                    <li className="col-6">
                                        <p className="mb-1 font-size-18 fw-bolder">95</p>
                                        <p className="mb-0">Mobile Phones</p>
                                    </li>
                                    <li className="col-6">
                                        <p className="mb-1 font-size-18 fw-bolder">25</p>
                                        <p className="mb-0">Desktops</p>
                                    </li>
                                </ul>

                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Order;