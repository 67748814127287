import React, { useEffect } from 'react';

import { connect } from "react-redux";

import { setBreadcrumbItems } from '../../store/actions';

import BackGround from '../../assets/images/bg-2.jpg';
import logo from '../../assets/images/logo.png';
import avtar from '../../assets/images/users/avatar-1.jpg';

const LockScreen2 = props => {
    ///breadcrumbItems

    const breadcrumbItems = [
        {
            title: 'Lock Screen 2',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Lock Screen 2', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="accountbg" style={{ background: `url(${BackGround}) center`, backgroundPosition: 'cover' }}></div>

            <div className="wrapper-page account-page-full">

                <div className="card shadow-none">
                    <div className="card-block">

                        <div className="account-box">

                            <div className="card-box shadow-none p-4">
                                <div className="p-2">
                                    <div className="text-center mt-4">
                                        <a href="index.html"><img src={logo} height="30" alt="logo" /></a>
                                    </div>

                                    <h4 className="font-size-18 mt-5 text-center">Locked</h4>
                                    <p className="text-muted text-center">Hello Smith, enter your password to unlock the screen!</p>

                                    <form className="mt-4" action="#">

                                        <div className="user-thumb text-center">
                                            <img src={avtar} className="rounded-circle img-thumbnail avatar-lg" alt="thumbnail" />
                                            <h6 className="font-size-16 mt-3">Robert Smith</h6>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" for="userpassword">Password</label>
                                            <input type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                        </div>

                                        <div className="mb-3">
                                            <div className="text-end">
                                                <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Unlock</button>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="mt-5 pt-4 text-center position-relative">
                                        <p>Not you ? return <a href="pages-login-2.html" className="fw-medium text-primary"> Sign In </a> </p>
                                        <p>2017 - <script>document.write(new Date().getFullYear())</script> © Admiria. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(LockScreen2);