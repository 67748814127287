import React, { useEffect } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import LatestOrders from "./LatestOrders";
import LatestTransactions from "./LatestTransactions";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

//import component
import MonthlyEarning from './MonthlyEarning';
import Order from "./Order";
import SalesAnalytics from './SalesAnalytics';

const Dashboard = (props) => {

    const breadcrumbItems = [
        {
            title: 'Dashboard',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Dashboard', breadcrumbItems)
    });

    const widgets = [
        {
            sales: "Total Sales",
            iconClass: "mdi mdi-basket",
            iconColor: 'bg-purple',
            description: "25140",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
        {
            sales: "New Orders",
            iconClass: "mdi mdi-black-mesa",
            iconColor: 'bg-blue-grey',
            description: "65241",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
        {
            sales: "New Users",
            iconClass: "mdi mdi-buffer",
            iconColor: 'bg-brown',
            description: "85412",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
        {
            sales: "Unique Visitors",
            iconClass: "mdi mdi-coffee",
            iconColor: 'bg-teal',
            description: "20544",
            totalIncome: '$22506',
            percentage: '10.25%'
        },
    ]


    return (
        <div className="page-content">
            <Container fluid>

                {/* Breadcrumb */}
                {props.layoutType === 'horizontal' ?
                    <Breadcrumb breadcrumbItem="Dashboards" />
                    : null}
                <Row>
                    {widgets.map((widget, key) => (
                        <Col md={6} xl={3} key={key}>
                            <Card>
                                <CardBody>
                                    <div className="mini-stat">
                                        <span className={"mini-stat-icon " + widget.iconColor + " me-0 float-end"}><i className={widget.iconClass}></i></span>
                                        <div className="mini-stat-info">
                                            <span className="counter text-purple">{widget.description}</span>
                                            {widget.sales}
                                        </div>
                                        <p className=" mb-0 mt-4 text-muted">Total income: {widget.totalIncome} <span className="float-end"><i className="fa fa-caret-up me-1"></i>{widget.percentage}</span></p>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}

                </Row>
                <Row>
                    <Col xl={9}>
                        <MonthlyEarning />
                    </Col>
                    <SalesAnalytics />
                    <Order />
                    <LatestTransactions />
                    <LatestOrders />
                </Row>
            </Container>
        </div>
    )
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(Dashboard);
