import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Row, Button, UncontrolledPopover, PopoverHeader, PopoverBody, UncontrolledTooltip } from 'reactstrap';

import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

const UiPopoverTooltips = props => {
    const breadcrumbItems = [
        {
            title: 'Popover & Tooltips',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Popover & Tooltips', breadcrumbItems)
    })
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Ui" />
                        : null}
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Popovers</h4>
                                    <p className="card-title-desc">Four options are available: top, right, bottom, and left aligned. Directions are mirrored when using Bootstrap in RTL.</p>

                                    <div className="button-items" id="popover-container">
                                        <Button id="UncontrolledPopover" type="button">
                                            Popover on top
                                        </Button>
                                        <UncontrolledPopover placement="top" target="UncontrolledPopover">
                                            <PopoverBody>Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</PopoverBody>
                                        </UncontrolledPopover>

                                        <Button id="UncontrolledPopoverRight" type="button">
                                            Popover on right
                                        </Button>
                                        <UncontrolledPopover placement="right" target="UncontrolledPopoverRight">
                                            <PopoverBody>Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</PopoverBody>
                                        </UncontrolledPopover>

                                        <Button id="UncontrolledPopoverBottom" type="button">
                                            Popover on bottom
                                        </Button>
                                        <UncontrolledPopover placement="bottom" target="UncontrolledPopoverBottom">
                                            <PopoverBody>Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</PopoverBody>
                                        </UncontrolledPopover>

                                        <Button id="UncontrolledPopoverLeft" type="button">
                                            Popover on left
                                        </Button>
                                        <UncontrolledPopover placement="left" target="UncontrolledPopoverLeft">
                                            <PopoverBody>Vivamus sagittis lacus vel augue laoreet rutrum faucibus.</PopoverBody>
                                        </UncontrolledPopover>

                                        <Button id="UncontrolledPopoverDismissible" className="btn btn-success waves-effect waves-light" type="button">
                                            Dismissible popover
                                        </Button>
                                        <UncontrolledPopover placement="right" target="UncontrolledPopoverDismissible">
                                            <PopoverHeader>Dismissible popover</PopoverHeader>
                                            <PopoverBody>And here's some amazing content. It's very engaging. Right?</PopoverBody>
                                        </UncontrolledPopover>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Tooltips</h4>
                                    <p className="card-title-desc">Hover over the links below to see tooltips:</p>

                                    <div className="button-items" id="tooltip-container">
                                        <Button type="button" className="btn btn-primary" id="UncontrolledTooltipExample">
                                            Tooltip on top
                                            </Button>
                                        <UncontrolledTooltip placement="top" className="btn btn-primary" target="UncontrolledTooltipExample">
                                            Tooltip on top
                                        </UncontrolledTooltip>

                                        <Button type="button" className="btn btn-primary" id="UncontrolledTooltipRight">
                                            Tooltip on right
                                            </Button>
                                        <UncontrolledTooltip placement="right" className="btn btn-primary" target="UncontrolledTooltipRight">
                                            Tooltip on right
                                        </UncontrolledTooltip>

                                        <Button type="button" className="btn btn-primary" id="UncontrolledTooltipBottom">
                                            Tooltip on bottom
                                            </Button>
                                        <UncontrolledTooltip placement="bottom" className="btn btn-primary" target="UncontrolledTooltipBottom">
                                            Tooltip on bottom
                                        </UncontrolledTooltip>

                                        <Button type="button" className="btn btn-primary" id="UncontrolledTooltipLeft">
                                            Tooltip on left
                                            </Button>
                                        <UncontrolledTooltip placement="left" className="btn btn-primary" target="UncontrolledTooltipLeft">
                                            Tooltip on left
                                        </UncontrolledTooltip>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(UiPopoverTooltips);