import React, { useEffect } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap';
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

const FormElements = props => {
    const breadcrumbItems = [
        {
            title: 'Form Elements',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Form Elements', breadcrumbItems)
    })
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Forms" />
                        : null}
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Textual inputs</h4>
                                    <p className="card-title-desc">Here are examples of <code
                                        className="highlighter-rouge">.form-control</code> applied to each
                                            textual HTML5 <code className="highlighter-rouge">&lt;input&gt;</code> <code
                                            className="highlighter-rouge">type</code>.</p>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-text-input" className="col-sm-2 col-form-label">Text</Label>
                                        <Col sm={10}>
                                            <Input className="form-control" type="text" placeholder="Artisanal kale" id="example-text-input" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-search-input" className="col-sm-2 col-form-label">Search</Label>
                                        <Col sm={10}>
                                            <Input className="form-control" type="search" placeholder="How do I shoot web" id="example-search-input" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-email-input" className="col-sm-2 col-form-label">Email</Label>
                                        <Col sm={10}>
                                            <Input className="form-control" type="email" placeholder="bootstrap@example.com" id="example-email-input" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-url-input" className="col-sm-2 col-form-label">URL</Label>
                                        <Col sm={10}>
                                            <Input className="form-control" type="url" placeholder="https://getbootstrap.com" id="example-url-input" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-tel-input" className="col-sm-2 col-form-label">Telephone</Label>
                                        <Col sm={10}>
                                            <Input className="form-control" type="tel" placeholder="1-(555)-555-5555" id="example-tel-input" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-password-input" className="col-sm-2 col-form-label">Password</Label>
                                        <Col sm={10}>
                                            <Input className="form-control" type="password" placeholder="hunter2" id="example-password-input" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-number-input" className="col-sm-2 col-form-label">Number</Label>
                                        <Col sm={10}>
                                            <Input className="form-control" type="number" placeholder="42" id="example-number-input" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-datetime-local-input" className="col-sm-2 col-form-label">Date and time</Label>
                                        <Col sm={10}>
                                            <Input
                                                className="form-control"
                                                type="datetime-local"
                                                defaultValue="2019-08-19T13:45:00"
                                                id="example-datetime-local-input"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-date-input" className="col-sm-2 col-form-label">Date</Label>
                                        <Col sm={10}>
                                            <Input
                                                className="form-control"
                                                type="date"
                                                defaultValue="2019-08-19"
                                                id="example-date-input"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-month-input" className="col-sm-2 col-form-label">Month</Label>
                                        <Col sm={10}>
                                            <Input
                                                className="form-control"
                                                type="month"
                                                defaultValue="2019-08"
                                                id="example-month-input"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-week-input" className="col-sm-2 col-form-label">Week</Label>
                                        <Col sm={10}>
                                            <Input
                                                className="form-control"
                                                type="week"
                                                defaultValue="2019-W33"
                                                id="example-week-input"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-time-input" className="col-sm-2 col-form-label">Time</Label>
                                        <Col sm={10}>
                                            <Input
                                                className="form-control"
                                                type="time"
                                                defaultValue="13:45:00"
                                                id="example-time-input"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-color-input" className="col-sm-2 col-form-label">Color</Label>
                                        <Col sm={10}>
                                            <Input type="color" className="form-control form-control-color mw-100" id="example-color-input" defaultValue="#67a8e4" title="Choose your color" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label className="col-md-2 col-form-label">Select</Label>
                                        <Col sm={10}>
                                            <select className="form-control">
                                                <option>Select</option>
                                                <option>Large select</option>
                                                <option>Small select</option>
                                            </select>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Label htmlFor="example-text-input-lg" className="col-sm-2 col-form-label">Large</Label>
                                        <Col sm={10}>
                                            <Input className="form-control form-control-lg" type="text" placeholder=".form-control-lg" id="example-text-input-lg" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Label htmlFor="example-text-input-sm" className="col-sm-2 col-form-label">Small</Label>
                                        <Col sm={10}>
                                            <Input className="form-control form-control-sm" type="text" placeholder=".form-control-sm" id="example-text-input-sm" />
                                        </Col>
                                    </Row>
                                    <Row className="has-success mb-3">
                                        <Label htmlFor="inputHorizontalSuccess" className="col-sm-2 col-form-label">Email</Label>
                                        <Col sm={10}>
                                            <Input type="email" className="form-control form-control-success border-info" id="inputHorizontalSuccess" placeholder="name@example.com" />
                                            <div className="form-control-feedback">Success! You've done it.</div>
                                            <small className="form-text text-muted">Example help text that remains unchanged.</small>
                                        </Col>
                                    </Row>
                                    <Row className="has-warning mb-3">
                                        <Label htmlFor="inputHorizontalWarning" className="col-sm-2 col-form-label">Email</Label>
                                        <Col sm={10}>
                                            <Input type="email" className="form-control form-control-warning border-warning" id="inputHorizontalWarning" placeholder="name@example.com" />
                                            <div className="form-control-feedback">Shucks, check the formatting of that and try again.</div>
                                            <small className="form-text text-muted">Example help text that remains unchanged.</small>
                                        </Col>
                                    </Row>
                                    <Row className="has-danger mb-0">
                                        <Label htmlFor="inputHorizontalDnger" className="col-sm-2 col-form-label">Email</Label>
                                        <Col sm={10}>
                                            <Input type="email" className="form-control form-control-danger" id="inputHorizontalDnger" placeholder="name@example.com" />
                                            <div className="form-control-feedback">Sorry, that username's taken. Try another?</div>
                                            <small className="form-text text-muted">Example help text that remains unchanged.</small>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(FormElements);
