import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

//import images
import user1 from '../../assets/images/small/img-1.jpg';
import user2 from '../../assets/images/small/img-2.jpg';

const Timeline = props => {

    const breadcrumbItems = [
        {
            title: 'Timeline',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Timeline', breadcrumbItems)
    })
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Pages" />
                        : null}
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>

                                    <section id="cd-timeline" className="cd-container">
                                        <div className="cd-timeline-block">
                                            <div className="cd-timeline-img bg-success">
                                                <i className="mdi mdi-adjust"></i>
                                            </div>

                                            <div className="cd-timeline-content">
                                                <h3>Timeline Event One</h3>
                                                <p className="mb-0 text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde? Iste voluptatibus minus veritatis qui ut.</p>
                                                <span className="cd-date">May 23</span>
                                            </div>
                                        </div>

                                        <div className="cd-timeline-block">
                                            <div className="cd-timeline-img bg-danger">
                                                <i className="mdi mdi-adjust"></i>
                                            </div>

                                            <div className="cd-timeline-content">
                                                <h3>Timeline Event Two</h3>
                                                <p className="mb-3 text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde?</p>
                                                <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light m-t-5">See more detail</button>

                                                <span className="cd-date">May 30</span>
                                            </div>
                                        </div>

                                        <div className="cd-timeline-block">
                                            <div className="cd-timeline-img bg-info">
                                                <i className="mdi mdi-adjust"></i>
                                            </div>

                                            <div className="cd-timeline-content">
                                                <h3>Timeline Event Three</h3>
                                                <p className="mb-0 text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi, obcaecati, quisquam id molestias eaque asperiores voluptatibus cupiditate error assumenda delectus odit similique earum voluptatem doloremque dolorem ipsam quae rerum quis. Odit, itaque, deserunt corporis vero ipsum nisi eius odio natus ullam provident pariatur temporibus quia eos repellat ... <Link to="#">Read more</Link></p>
                                                <span className="cd-date">Jun 05</span>
                                            </div>
                                        </div>

                                        <div className="cd-timeline-block">
                                            <div className="cd-timeline-img bg-pink">
                                                <i className="mdi mdi-adjust"></i>
                                            </div>

                                            <div className="cd-timeline-content">
                                                <h3>Timeline Event Four</h3>
                                                <p className="mb-3 text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum aut hic quasi placeat iure tempora laudantium ipsa ad debitis unde? Iste voluptatibus minus veritatis qui ut.</p>
                                                <img src={user1} alt="" className="rounded" style={{ width: '120px' }} />
                                                <img src={user2} alt="" className="rounded" style={{ width: '120px' }} />
                                                <span className="cd-date">Jun 14</span>
                                            </div>
                                        </div>

                                        <div className="cd-timeline-block">
                                            <div className="cd-timeline-img bg-warning">
                                                <i className="mdi mdi-adjust"></i>
                                            </div>

                                            <div className="cd-timeline-content">
                                                <h3>Timeline Event Five</h3>
                                                <p className="mb-3 text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, optio, dolorum provident rerum.</p>
                                                <button type="button" className="btn btn-primary btn-rounded waves-effect waves-light">See more detail</button>
                                                <span className="cd-date">Jun 18</span>
                                            </div>
                                        </div>

                                        <div className="cd-timeline-block">
                                            <div className="cd-timeline-img bg-primary">
                                                <i className="mdi mdi-adjust"></i>
                                            </div>

                                            <div className="cd-timeline-content">
                                                <h3>Timeline Event End</h3>
                                                <p className="mb-0 text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi, obcaecati, quisquam id molestias eaque asperiores voluptatibus cupiditate error assumenda delectus odit similique earum voluptatem doloremque dolorem ipsam quae rerum quis. Odit, itaque, deserunt corporis vero ipsum nisi eius odio natus ullam provident pariatur temporibus quia eos repellat consequuntur perferendis enim amet quae quasi repudiandae sed quod veniam dolore possimus rem voluptatum eveniet eligendi quis fugiat aliquam sunt similique aut adipisci.</p>
                                                <span className="cd-date">Jun 30</span>
                                            </div>
                                        </div>
                                    </section>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
  };
  
  export default connect(mapStatetoProps, { setBreadcrumbItems })(Timeline);
