import React from 'react';
import { Link } from 'react-router-dom';

//import images
import user1 from '../../assets/images/users/avatar-2.jpg';
import user2 from '../../assets/images/users/avatar-3.jpg';
import user3 from '../../assets/images/users/avatar-4.jpg';
import user4 from '../../assets/images/users/avatar-5.jpg';

const EmailSideBar = props => {
    return (
        <React.Fragment>
            <div className="email-leftbar">
                <div className="d-grid">
                    <Link to="email-compose" className="btn btn-danger rounded  waves-effect waves-light">Compose</Link>
                </div>
                <div className="mail-list mt-3">
                    <Link to="#" className="active">Inbox <span className="ms-1">(18)</span></Link>
                    <Link to="#">Starred</Link>
                    <Link to="#">Important</Link>
                    <Link to="#">Draft</Link>
                    <Link to="#">Sent Mail</Link>
                    <Link to="#">Trash</Link>
                </div>


                <h6 className="mt-4">Labels</h6>

                <div className="mail-list mt-3">
                    <Link to="#"><span className="mdi mdi-arrow-right-drop-circle text-info float-end mt-1 ms-2"></span>Theme Support</Link>
                    <Link to="#"><span className="mdi mdi-arrow-right-drop-circle text-warning float-end mt-1 ms-2"></span>Freelance</Link>
                    <Link to="#"><span className="mdi mdi-arrow-right-drop-circle text-purple float-end mt-1 ms-2"></span>Social</Link>
                    <Link to="#"><span className="mdi mdi-arrow-right-drop-circle text-pink float-end mt-1 ms-2"></span>Friends</Link>
                    <Link to="#"><span className="mdi mdi-arrow-right-drop-circle text-success float-end mt-1 ms-2"></span>Family</Link>
                </div>

                <h6 className="mt-4">Chat</h6>

                <div className="mt-4">
                    <Link to="#" className="d-flex">
                        <img className="me-3 rounded-circle" src={user1} alt="" height="36" />
                        <div className="chat-user-box">
                            <p className="user-title m-0">Scott Median</p>
                            <p className="text-muted">Hello</p>
                        </div>
                    </Link>

                    <Link to="#" className="d-flex">
                        <img className="me-3 rounded-circle" src={user2} alt="" height="36" />
                        <div className="chat-user-box">
                            <p className="user-title m-0">Julian Rosa</p>
                            <p className="text-muted">What about our next..</p>
                        </div>
                    </Link>

                    <Link to="#" className="d-flex">
                        <img className="me-3 rounded-circle" src={user3} alt="" height="36" />
                        <div className="chat-user-box">
                            <p className="user-title m-0">David Medina</p>
                            <p className="text-muted">Yeah everything is fine</p>
                        </div>
                    </Link>

                    <Link to="#" className="d-flex">
                        <img className="me-3 rounded-circle" src={user4} alt="" height="36" />
                        <div className="chat-user-box">
                            <p className="user-title m-0">Jay Baker</p>
                            <p className="text-muted">Wow that's great</p>
                        </div>
                    </Link>

                </div>
            </div>
        </React.Fragment>
    );
}

export default EmailSideBar;