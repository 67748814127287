import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"

import { isEmpty } from "lodash"

//Import actions
import { getProducts } from "../../store/e-commerce/actions"

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

const ProductGrid = props => {
  const { products, onGetProducts } = props
  // eslint-disable-next-line no-unused-vars
  const [FilterClothes, setFilterClothes] = useState([
    { id: 1, name: "T-shirts", link: "#" },
    { id: 2, name: "Shirts", link: "#" },
    { id: 3, name: "Jeans", link: "#" },
    { id: 4, name: "Jackets", link: "#" },
  ])
  const [productList, setProductList] = useState([])

  useEffect(() => {
    setProductList(products)
    onGetProducts()
  }, [onGetProducts, products])

  useEffect(() => {
    if (!isEmpty(products)) setProductList(products)
  }, [products])

  const breadcrumbItems = [
    {
      title: 'Product Grid',
      link: '#'
    }
  ]

  useEffect(() => {
    props.onSetBreadCrumbs('Product Grid', breadcrumbItems)
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          {/* Breadcrumb */}
          {props.layoutType === 'horizontal' ?
            <Breadcrumb breadcrumbItem="Ecommerce" />
            : null}

          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    {!isEmpty(productList) &&
                      productList.map((product, key) => (
                        <div className="col-md-6 col-lg-3" key={key}>
                          <div className="product-list-box card p-2">
                            {product.isSale && (
                              <span className="product-tag">sale</span>
                            )}
                            {product.isPopular && (
                              <span className="product-tag bg-primary">Popular</span>
                            )}
                            {product.isDiscount && (
                              <span class="product-tag bg-pink">75% Off</span>
                            )}

                            <Link to="#">
                              <img src={product.image} className="img-fluid" alt="work-thumbnail" />
                            </Link>
                            <div className="detail">
                              <h4 className="font-size-16 mt-2"><Link to="#" className="text-dark">{product.name}</Link> </h4>

                              <Link to="#" className="btn btn-success btn-sm me-1">Edit</Link>
                              <Link to="#" className="btn btn-secondary btn-sm">Delete</Link>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ProductGrid.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
}

const mapStateToProps = state => {
  const Layout = state.Layout;
  return {
    layoutType: Layout.layoutType,
    products: state.ecommerce.products,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetProducts: () => dispatch(getProducts()),
  onSetBreadCrumbs: breadcrumbItems => dispatch(setBreadcrumbItems(breadcrumbItems)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductGrid))
