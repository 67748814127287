import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Modal, Row } from 'reactstrap';
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

const UiModal = props => {

    const [modal_standard, setmodal_standard] = useState(false);
    const [modal_large, setmodal_large] = useState(false);
    const [modal_small, setmodal_small] = useState(false);
    const [modal_fullscreen, setmodal_fullscreen] = useState(false);

    function tog_standard() {
        setmodal_standard(!modal_standard)
    }
    function tog_large() {
        setmodal_large(!modal_large)
    }

    function tog_small() {
        setmodal_small(!modal_small)
    }

    function tog_fullscreen() {
        setmodal_fullscreen(!modal_fullscreen)
    }

    const breadcrumbItems = [
        {
            title: 'Modal',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Modal', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Ui" />
                        : null}
                    <Row>
                        <Col sx={12}>
                            <Card>
                                <CardBody>

                                    <h4 className="card-title">Modals Examples</h4>
                                    <p className="card-title-desc">Modals are streamlined, but flexible
                                    dialog prompts powered by JavaScript. They support a number of use cases
                                    from user notification to completely custom content and feature a
                                            handful of helpful subcomponents, sizes, and more.</p>


                                    <div
                                        className="modal bs-example-modal"
                                        tabIndex="-1"
                                        role="dialog"
                                    >
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Modal title</h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>One fine body&hellip;</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-primary">
                                                        Save changes
                            </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        data-dismiss="modal"
                                                    >
                                                        Close
                            </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <Row>

                                        <Col md={3} className="mt-4">
                                            <div className=" text-center">
                                                <p className="text-muted">Standard modal</p>
                                                <button onClick={() => {
                                                    tog_standard()
                                                }} type="button" className="btn btn-primary waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#myModal">Standard modal</button>
                                            </div>


                                            <Modal isOpen={modal_standard}
                                                toggle={() => {
                                                    tog_standard()
                                                }} tabIndex="-1">
                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0" id="myModalLabel">Modal Heading</h5>
                                                    <button type="button" className="btn-close" onClick={() => {
                                                        setmodal_standard(false)
                                                    }}></button>
                                                </div>
                                                <div className="modal-body">
                                                    <h5>Overflowing text to show scroll behavior</h5>
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                    Cras justo odio, dapibus ac facilisis in,
                                                    egestas eget quam. Morbi leo risus, porta ac
                                                                    consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                    nisl consectetur et. Vivamus sagittis lacus vel
                                                                    augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur.
                                                    Praesent commodo cursus magna, vel scelerisque
                                                    nisl consectetur et. Donec sed odio dui. Donec
                                                    ullamcorper nulla non metus auctor
                                                                    fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                    Cras justo odio, dapibus ac facilisis in,
                                                    egestas eget quam. Morbi leo risus, porta ac
                                                                    consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                    nisl consectetur et. Vivamus sagittis lacus vel
                                                                    augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur.
                                                    Praesent commodo cursus magna, vel scelerisque
                                                    nisl consectetur et. Donec sed odio dui. Donec
                                                    ullamcorper nulla non metus auctor
                                                                    fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                    Cras justo odio, dapibus ac facilisis in,
                                                    egestas eget quam. Morbi leo risus, porta ac
                                                                    consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                    nisl consectetur et. Vivamus sagittis lacus vel
                                                                    augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur.
                                                    Praesent commodo cursus magna, vel scelerisque
                                                    nisl consectetur et. Donec sed odio dui. Donec
                                                    ullamcorper nulla non metus auctor
                                                                    fringilla.</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary waves-effect" data-dismiss="modal">Close</button>
                                                    <button type="button" className="btn btn-primary waves-effect waves-light">Save changes</button>
                                                </div>
                                            </Modal>
                                        </Col>

                                        <Col md={3} className="mt-4">
                                            <div className="text-center">
                                                <p className="text-muted">Large modal</p>

                                                <button type="button" onClick={() => {
                                                    tog_large()
                                                }} className="btn btn-primary waves-effect waves-light" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg">Large modal</button>
                                            </div>



                                            <Modal size="lg"
                                                isOpen={modal_large}
                                                toggle={() => {
                                                    tog_large()
                                                }} tabIndex="-1">
                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0" id="myLargeModalLabel">Large modal</h5>
                                                    <button onClick={() => {
                                                        setmodal_large(false)
                                                    }} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                    Cras justo odio, dapibus ac facilisis in,
                                                    egestas eget quam. Morbi leo risus, porta ac
                                                                    consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                    nisl consectetur et. Vivamus sagittis lacus vel
                                                                    augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p className="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                                                    Praesent commodo cursus magna, vel scelerisque
                                                    nisl consectetur et. Donec sed odio dui. Donec
                                                    ullamcorper nulla non metus auctor
                                                                    fringilla.</p>
                                                </div>
                                            </Modal>
                                        </Col>

                                        <Col md={3} className="mt-4">
                                            <div className="text-center">
                                                <p className="text-muted">Small modal</p>

                                                <button type="button" onClick={() => {
                                                    tog_small()
                                                }} className="btn btn-primary waves-effect waves-light" data-bs-toggle="modal" data-bs-target=".bs-example-modal-sm">Small modal</button>
                                            </div>

                                            <Modal
                                                size="sm"
                                                isOpen={modal_small}
                                                toggle={() => {
                                                    tog_small()
                                                }}>


                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0" id="mySmallModalLabel">Small modal</h5>
                                                    <button type="button" onClick={() => {
                                                        setmodal_small(false)
                                                    }} className="btn-close" ></button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                    Cras justo odio, dapibus ac facilisis in,
                                                    egestas eget quam. Morbi leo risus, porta ac
                                                                    consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                    nisl consectetur et. Vivamus sagittis lacus vel
                                                                    augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p className="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                                                    Praesent commodo cursus magna, vel scelerisque
                                                    nisl consectetur et. Donec sed odio dui. Donec
                                                    ullamcorper nulla non metus auctor
                                                                    fringilla.</p>
                                                </div>
                                            </Modal>
                                        </Col>
                                        <Col md={3} className="mt-4">
                                            <div className="text-center">
                                                <p className="text-muted">Fullscreen modal</p>
                                                <button type="button" onClick={() => {
                                                    tog_fullscreen()
                                                }} className="btn btn-primary waves-effect waves-light">Launch demo modal</button>
                                            </div>

                                            <Modal size="xl"
                                                isOpen={modal_fullscreen}
                                                toggle={() => {
                                                    tog_fullscreen()
                                                }}
                                                className="modal-fullscreen">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Fullscreen Modal</h5>
                                                    <button type="button" onClick={() => {
                                                        setmodal_fullscreen(false)
                                                    }} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                    <button type="button" className="btn btn-primary">Save changes</button>
                                                </div>
                                            </Modal>
                                        </Col>
                                    </Row>


                                </CardBody>
                            </Card>
                        </Col>

                    </Row>



                </Container>
            </div>
        </React.Fragment>

    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(UiModal);