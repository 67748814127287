import PropTypes from "prop-types"
import React, { useEffect, useRef, useCallback } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }, [])

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Main")} </li>
            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-primary float-end">20+</span>
                <i className="mdi mdi-view-dashboard"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/dashboard">{props.t("Dashboard One")}</Link>
                </li>
                <li>
                  <Link to="/dashboard-2">{props.t("Dashboard Two")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/widgets" className=" waves-effect">
                <i className="mdi mdi-cube-outline"></i>
                <span>{props.t("Widgets")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-email-outline"></i>
                <span>{props.t("Email")}</span>
              </Link>
              <ul className="sub-menu">
                <li> <Link to="/email-inbox">{props.t("Inbox")}</Link></li>
                <li> <Link to="/email-read">{props.t("Email Read")}</Link></li>
                <li> <Link to="/email-compose">{props.t("Email Compose")}</Link></li>
              </ul>
            </li>

            <li>
              <Link to="/calendar" className="waves-effect">
                <i className="mdi mdi-calendar-check"></i>
                <span>{props.t("Calendar")}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("Components")} </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-buffer"></i>
                <span>{props.t("User Interface")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ui-button">{props.t("Buttons")}</Link>
                </li>
                <li>
                  <Link to="/ui-color">
                    {props.t("Colors")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-card">{props.t("Cards")}</Link>
                </li>
                <li>
                  <Link to="/ui-tabs-accordions">{props.t("Tabs & Accordions")}</Link>
                </li>
                <li>
                  <Link to="/ui-modal">{props.t("Modals")}</Link>
                </li>
                <li>
                  <Link to="/ui-images">{props.t("Images")}</Link>
                </li>
                <li>
                  <Link to="/ui-alert">{props.t("Alerts")}</Link>
                </li>
                <li>
                  <Link to="/ui-progressbars">
                    {props.t("Progress Bars")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-dropdowns">{props.t("Dropdowns")}</Link>
                </li>
                <li>
                  <Link to="/ui-lightbox">{props.t("Lightbox")}</Link>
                </li>
                <li>
                  <Link to="/ui-navs">{props.t("Navs")}</Link>
                </li>
                <li>
                  <Link to="/ui-pagination">{props.t("Pagination")}</Link>
                </li>
                <li>
                  <Link to="/ui-popover-tooltips">{props.t("Popover & Tooltips")}</Link>
                </li>
                <li>
                  <Link to="/ui-badge">{props.t("Badge")}</Link>
                </li>
                <li>
                  <Link to="/ui-carousel">{props.t("Carousel")}</Link>
                </li>
                <li>
                  <Link to="/ui-video">{props.t("Video")}</Link>
                </li>
                <li>
                  <Link to="/ui-typography">{props.t("Typography")}</Link>
                </li>
                <li>
                  <Link to="/ui-sweet-alert">{props.t("Sweet-Alert")}</Link>
                </li>
                <li>
                  <Link to="/ui-grid">{props.t("Grid")}</Link>
                </li>
                <li>
                  <Link to="/ui-animation">{props.t("Animation")}</Link>
                </li>
                <li>
                  <Link to="/ui-highlight">{props.t("Highlight")}</Link>
                </li>
                <li>
                  <Link to="/ui-rating">{props.t("Rating")}</Link>
                </li>
                <li>
                  <Link to="/ui-nestable">{props.t("Nestable")}</Link>
                </li>
                <li>
                  <Link to="/ui-rangeslider">{props.t("Range Slider")}</Link>
                </li>
                <li>
                  <Link to="/ui-sessiontimeout">{props.t("Session Timeout")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-success float-end">9</span>
                <i className="mdi mdi-clipboard-outline"></i>
                <span>{props.t("Forms")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/form-elements">{props.t("Form Elements")}</Link>
                </li>
                <li>
                  <Link to="/form-validation">{props.t("Form Validation")}</Link>
                </li>
                <li>
                  <Link to="/form-advanced">{props.t("Form Advanced")}</Link>
                </li>
                <li>
                  <Link to="/form-wizard">{props.t("Form Wizard")}</Link>
                </li>
                <li>
                  <Link to="/form-editor">{props.t("Form Editors")}</Link>
                </li>
                <li>
                  <Link to="/form-uploads">
                    {props.t("Form File Upload")}
                  </Link>
                </li>
                <li>
                  <Link to="/form-mask">{props.t("Form Mask")}</Link>
                </li>
                <li>
                  <Link to="/form-xeditable">{props.t("Form Xeditable")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-chart-line"></i>
                <span>{props.t("Charts")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/apex-charts">{props.t("Apex charts")}</Link>
                </li>
                <li>
                  <Link to="/chartist-charts">{props.t("Chartist Chart")}</Link>
                </li>
                <li>
                  <Link to="/chartjs-charts">{props.t("Chartjs Chart")}</Link>
                </li>
                <li>
                  <Link to="/e-charts">{props.t("E Chart")}</Link>
                </li>
                <li>
                  <Link to="/tui-charts">{props.t("Toast UI Chart")}</Link>
                </li>
                <li>
                  <Link to="/sparkline-charts">
                    {props.t("Sparkline Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/charts-knob">{props.t("Knob Chart")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-format-list-bulleted-type"></i>
                <span>{props.t("Tables")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tables-basic">{props.t("Basic Tables")}</Link>
                </li>
                <li>
                  <Link to="/tables-datatable">{props.t("Data Table")}</Link>
                </li>
                <li>
                  <Link to="/tables-responsive">{props.t("Responsive Table")}</Link>
                </li>
                <li>
                  <Link to="/tables-editable">{props.t("Editable Table")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-album"></i>
                <span>{props.t("Icons")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/icons-materialdesign">
                    {props.t("Material Design")}
                  </Link>
                </li>
                <li>
                  <Link to="/icons-ion">{props.t("Ion Icons")}</Link>
                </li>
                <li>
                  <Link to="/icons-fontawesome">{props.t("Font awesome")}</Link>
                </li>
                <li>
                  <Link to="/icon-themify">{props.t("Themify Icons")}</Link>
                </li>
                <li>
                  <Link to="/icons-dripicons">{props.t("Dripicons")}</Link>
                </li>
                <li>
                  <Link to="/icon-typicons">{props.t("Typicons Icon")}</Link>
                </li>
                <li>
                  <Link to="/icon-weather">{props.t("Weather Icons")}</Link>
                </li>
                <li>
                  <Link to="/icon-mobirise">{props.t("Mobirise Icons")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-danger float-end">2</span>
                <i className="mdi mdi-google-maps"></i>
                <span>{props.t("Maps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/maps-google">{props.t("Google Map")}</Link>
                </li>
                <li>
                  <Link to="/maps-vector">{props.t("Vector Map")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("Extras")} </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-account-box"></i>
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/page-login">{props.t("Login")}</Link>
                </li>
                <li>
                  <Link to="/page-register">{props.t("Register")}</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw">{props.t("Recover Password")}</Link>
                </li>
                <li>
                  <Link to="/page-lock-screen">{props.t("Lock Screen")}</Link>
                </li>
                <li>
                  <Link to="/page-login-2">{props.t("Login 2")}</Link>
                </li>
                <li>
                  <Link to="/page-register-2">{props.t("Register 2")}</Link>
                </li>
                <li>
                  <Link to="/page-recoverpw-2">{props.t("Recover Password 2")}</Link>
                </li>
                <li>
                  <Link to="/page-lock-screen-2">{props.t("Lock Screen 2")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-book-multiple-outline" />

                <span>{props.t("Extra Pages")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/page-timeline">{props.t("Timeline")}</Link>
                </li>
                <li>
                  <Link to="/page-invoie">{props.t("Invoice")}</Link>
                </li>
                {/* <li>
                  <Link to="/page-directory">{props.t("Directory")}</Link>
                </li> */}
                <li>
                  <Link to="/page-directory">{props.t("Directory")}</Link>
                </li>
                <li>
                  <Link to="/page-blank">{props.t("Blank Page")}</Link>
                </li>
                <li>
                  <Link to="/page-404">{props.t("Error 404")}</Link>
                </li>
                <li>
                  <Link to="/page-500">{props.t("Error 500")}</Link>
                </li>
                <li>
                  <Link to="/page-pricing">{props.t("Pricing")}</Link>
                </li>
                <li>
                  <Link to="/page-gallery">{props.t("Gallery")}</Link>
                </li>
                <li>
                  <Link to="/page-maintenance">{props.t("Maintenance")}</Link>
                </li>
                <li>
                  <Link to="/page-coming-soon">{props.t("Coming Soon")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-cart-outline"></i>
                <span>{props.t("Ecommerce")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/product-list">{props.t("Product List")}</Link>
                </li>
                <li>
                  <Link to="/product-grid">{props.t("Product Grid")}</Link>
                </li>
                <li>
                  <Link to="/ecommerce-orders">{props.t("Order History")}</Link>
                </li>
                <li>
                  <Link to="/ecommerce-customers">{props.t("Customers")}</Link>
                </li>
                <li>
                  <Link to="/ecommerce-add-product">
                    {props.t("Product Edit")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-flask-outline"></i>
                <span>{props.t("Email Templates")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/email-templates-basic">{props.t("Basic Action Email")}</Link>
                </li>
                <li>
                  <Link to="/email-templates-alert">{props.t("Alert Email")}</Link>
                </li>
                <li>
                  <Link to="/email-templates-billing">{props.t("Billing Email")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("Help & Support")}</li>

            <li>
              <Link to="/faq" className="waves-effect">
                <i className="mdi mdi-help-circle"></i>
                <span>{props.t("FAQ")}</span>
              </Link>
            </li>

            <li>
              <Link to="/contact" className="waves-effect">
                <span className="badge rounded-pill bg-warning float-end">3</span>
                <i className="mdi mdi-face-agent"></i>
                <span>{props.t("Contact")}</span>
              </Link>
            </li>


            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="dripicons-network-1"></i>
                <span>{props.t("Multi Level")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
