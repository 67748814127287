import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';

import { connect } from "react-redux";
import { setBreadcrumbItems } from '../../store/actions';


import BackGround from '../../assets/images/bg-2.jpg';
import logo from '../../assets/images/logo.png';

const Login2 = props => {
    ///breadcrumbItems

    const breadcrumbItems = [
        {
            title: 'Login 2',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Login 2', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="accountbg" style={{ background: `url(${BackGround}) center`, backgroundPosition: 'cover' }}></div>

            <div className="wrapper-page account-page-full">

                <Card className="shadow-none">
                    <div className="card-block">

                        <div className="account-box">

                            <div className="card-box shadow-none p-4">
                                <div className="p-2">
                                    <div className="text-center mt-4">
                                        <Link to="/dashboard"><img src={logo} height="30" alt="logo" /></Link>
                                    </div>

                                    <h4 className="font-size-18 mt-5 text-center">Welcome Back !</h4>
                                    <p className="text-muted text-center">Sign in to continue to Admiria.</p>

                                    <form className="mt-4" action="#">

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="username">Username</label>
                                            <input type="text" className="form-control" id="username" placeholder="Enter username" />
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="userpassword">Password</label>
                                            <input type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                        </div>

                                        <div className="mb-3 row">
                                            <div className="col-sm-6">
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" value="" id="customControlInline" />
                                                    <label className="form-check-label" htmlFor="customControlInline">Remember me</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 text-end">
                                                <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                                            </div>
                                        </div>

                                        <div className="mt-2 mb-0 row">
                                            <div className="col-12 mt-3">
                                                <a href="pages-recoverpw-2.html" className="text-muted"><i className="mdi mdi-lock"></i> Forgot your password?</a>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="mt-5 pt-4 text-center position-relative">
                                        <p>Don't have an account ? <a href="pages-register-2.html" className="fw-medium text-primary"> Signup now </a> </p>
                                        <p>2017 - {new Date().getFullYear()} © Admiria. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </Card>

            </div>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(Login2);