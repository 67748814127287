import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

//import images
import user1 from '../../assets/images/users/avatar-1.jpg';
import user2 from '../../assets/images/users/avatar-2.jpg';
import user3 from '../../assets/images/users/avatar-3.jpg';
import user4 from '../../assets/images/users/avatar-4.jpg';
import user5 from '../../assets/images/users/avatar-5.jpg';
import user6 from '../../assets/images/users/avatar-6.jpg';

const LatestMessages = props => {

    const LatestMessages = [
        {
            id: 1,
            img: user1,
            name: 'Didier Charpentier',
            desc: "Hey! there I'm available...",
            time: '13:40 PM'
        },
        {
            id: 2,
            img: user2,
            name: 'Sacripant Laderoute',
            desc: "I've finished it! See you so...",
            time: '13:34 PM'
        },
        {
            id: 3,
            img: user3,
            name: 'Huon Chalifour',
            desc: "This theme is awesome!",
            time: '13:17 PM'
        },
        {
            id: 4,
            img: user4,
            name: 'Paien Barrientos',
            desc: "Nice to meet you",
            time: '12:20 PM'
        },
        {
            id: 5,
            img: user5,
            name: 'Aubrey St-Jean',
            desc: "Hey! there I'm available...",
            time: '10:15 AM'
        },
        {
            id: 6,
            img: user6,
            name: 'Maurice Monty',
            desc: "This theme is awesome!",
            time: '9:56 AM'
        },
    ]

    return (
        <React.Fragment>
            <Col xl={4}>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Latest Messages</h4>

                        <div className="inbox-widget">
                            {LatestMessages.map((message, key) => (
                                <Link to="#" key={key}>
                                    <div className="inbox-item">
                                        <div className="inbox-item-img"><img src={message.img} className="rounded-circle" alt="avatar-1" /></div>
                                        <p className="inbox-item-author">{message.name}</p>
                                        <p className="inbox-item-text">{message.desc}</p>
                                        <p className="inbox-item-date">{message.time}</p>
                                    </div>
                                </Link>
                            ))}


                            <div className="text-center mt-4 pt-2">
                                <Link to="#" className="btn btn-sm btn-primary waves-light waves-effect">Load More</Link>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default LatestMessages;