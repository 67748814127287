import PropTypes from 'prop-types'
import React from "react"
import { Card, CardBody, Col, Container, Row,Alert } from 'reactstrap';

import BackGround from '../../assets/images/bg.jpg';
import logo from '../../assets/images/logo.png';

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

const Login = props => {
    // handleValidSubmit
    const handleValidSubmit = (event, values) => {
        props.loginUser(values, props.history)
    }

    return (
        <React.Fragment>
            <div className="accountbg" style={{ background: `url(${BackGround}) center` }}></div>

            <div className="account-pages mt-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={5} xl={4}>
                            <Card>
                                <CardBody>
                                    <div className="text-center mt-4">
                                        <div className="mb-3">
                                            <a href="index.html"><img
                                                src={logo}
                                                height="30" alt="logo" /></a>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <h4 className="font-size-18 mt-2 text-center">Welcome
                                        Back !</h4>
                                        <p className="text-muted text-center mb-4">Sign
                                          in to continue to Admiria.</p>

                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={(e, v) => {
                                                handleValidSubmit(e, v)
                                            }}>
                                            {props.error && typeof props.error === "string" ? (
                                                <Alert color="danger">{props.error}</Alert>
                                            ) : null}

                                            <div className="mb-3">
                                                <AvField
                                                    name="email"
                                                    label="Email"
                                                    value="admin@themesbrand.com"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    required
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <AvField
                                                    name="password"
                                                    label="Password"
                                                    value="123456"
                                                    type="password"
                                                    required
                                                    placeholder="Enter Password"
                                                />
                                            </div>

                                            <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customControlInline"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customControlInline"
                                                >
                                                    Remember me
                                                </label>
                                            </div>

                                            <div className="mt-3 d-grid">
                                                <button
                                                    className="btn btn-primary btn-block waves-effect waves-light"
                                                    type="submit"
                                                >
                                                    Log In
                                                </button>
                                            </div>


                                            <div className="mb-0 row">
                                                <div className="col-12 mt-4">
                                                    <a href="pages-recoverpw.html"
                                                        className="text-muted"><i
                                                            className="mdi mdi-lock"></i>
                                        Forgot your password?</a>
                                                </div>
                                            </div>
                                        </AvForm>

                                    </div>

                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center position-relative">
                                <p className="text-white">Don't have an account ? <a
                                    href="pages-register.html" className="fw-bold
                        text-primary"> Signup Now </a> </p>
                                <p className="text-white">2017 - {new Date().getFullYear()}
                     © Admiria. Crafted with <i className="mdi mdi-heart
                        text-danger"></i> by Themesbrand</p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const { error } = state.Login
    return { error }
}

export default withRouter(
    connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
)

Login.propTypes = {
    error: PropTypes.any,
    history: PropTypes.object,
    loginUser: PropTypes.func,
    socialLogin: PropTypes.func
}