import React from "react"
import { Link } from "react-router-dom"

const EcommerceCustomerColumns = () => [
  {
    dataField: "username",
    text: "Username",
    sort: true,
  },
  {
    text: "Email",
    dataField: "phone",
    sort: true,
    formatter: (cellContent, row) => (
      <>
        <p className="mb-0">{row.email}</p>
      </>
    ),
  },
  {
    text: "Phone",
    dataField: "email",
    sort: true,
    formatter: (cellContent, row) => (
      <>
        <p className="mb-1">{row.phone}</p>
      </>
    ),
  },
  {
    dataField: "address",
    text: "Address",
    sort: true,
  },
  {
    dataField: "walletBalance",
    text: "Wallet Balances",
    sort: true,
  },
  {
    dataField: "joiningDate",
    text: "Joining Date",
    sort: true,
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "Action",
    formatter: () => (
      <div>
      <Link to="#" className="me-3 text-muted"><i className="mdi mdi-pencil font-size-18"></i></Link>
      <Link to="#" className="text-muted"><i className="mdi mdi-close font-size-18"></i></Link>
      </div>
      
    ),
  },
]

export default EcommerceCustomerColumns
