import React from "react"
import { Progress } from "reactstrap"
import { Link } from "react-router-dom"

const EcommerceListColumns = toggleModal => [
  {
    text: "Image",
    dataField: "images",
    formatter: (cellContent, row) => (
      <React.Fragment>
        <div className="product-list-img">
          <img src={row.image} className="img-fluid avatar-md rounded" alt="tbl" />
        </div>
      </React.Fragment>
    ),
  },
  {
    text: "Product Name",
    dataField: "productname",
    formatter: (cellContent, row) => (
      <React.Fragment>
        <h6 className="mt-0 mb-1">{row.name}</h6>
        <p className="m-0 font-size-14">{row.description}</p>
      </React.Fragment>
    ),
  },
  {
    text: "Added Date",
    dataField: "date",
  },
  {
    text: "Amount",
    dataField: "price",
  },
  {
    text: "No. of Units",
    dataField: "units",
  },
  {
    text: "Stock",
    dataField: "stock",
    formatter: (cellContent, row) => (
      <React.Fragment>
        <div style={{ height: '5px' }}>
          <Progress className="progress-sm" color={row.color} value={25} />
        </div>
      </React.Fragment>
    ),
  },
  {
    text: "Action",
    dataField: "action",
    formatter: (cellContent, row) => (
      <React.Fragment>
        <Link to="#" className="me-3 text-muted"><i className="mdi mdi-pencil font-size-18"></i></Link>
        <Link to="#" className="text-muted"><i className="mdi mdi-close font-size-18"></i></Link>
      </React.Fragment>
    ),
  },
]

export default EcommerceListColumns
