import React, { useEffect } from "react"
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Sparklines, SparklinesLine } from 'react-sparklines';

//import images
import user1 from '../../assets/images/users/avatar-1.jpg';
import user2 from '../../assets/images/users/avatar-2.jpg';
import user3 from '../../assets/images/users/avatar-3.jpg';
import user4 from '../../assets/images/users/avatar-4.jpg';
import user5 from '../../assets/images/users/avatar-5.jpg';
import user6 from '../../assets/images/users/avatar-6.jpg';

const Users = props => {
    ///breadcrumbItems

    const breadcrumbItems = [
        {
            title: 'Directory',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Directory', breadcrumbItems)
    })

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Breadcrumb */}
                    {props.layoutType === 'horizontal' ?
                        <Breadcrumb breadcrumbItem="Extra Pages" />
                        : null}
                    <Row>
                        <Col lg={4}>
                            <Card>
                                <CardBody>

                                    <div className="d-flex">
                                        <img className="me-3 rounded-circle avatar-lg" src={user2} alt="" />
                                        <div>
                                            <h5 className="mt-2 font-size-18">Herbert C. Patton</h5>
                                            <p className="text-muted mb-0">pauline@admiria.com</p>
                                            <p className="text-muted font-size-14 font-weight-semibold">Creative Director</p>
                                        </div>
                                    </div>

                                    <Row className="text-center mt-4">
                                        <Col xs={6}>
                                            <h5 className="mb-0">235</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                        <Col xs={6}>
                                            <h5 className="mb-0">9512</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                    </Row>

                                    <Sparklines data={[20, 12, 18, 8, 30, 9, 9, 10, 22]}>
                                        <SparklinesLine type="line" width='100%' height='100' chartRangeMax={40} lineColor='#3bc3e9' resize={true} fillColor="rgba(59, 195, 233, 0.3)" highlightSpotColor='rgba(0,0,0,.2)' highlightLineColor="rgba(0,0,0,.1)" color="rgba(59, 195, 233, 0.3)" style={{ fillOpacity: 1 }} />
                                    </Sparklines>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4}>
                            <Card>
                                <CardBody>

                                    <div className="d-flex">
                                        <img className="me-3 rounded-circle avatar-lg" src={user3} alt="" />
                                        <div>
                                            <h5 className="mt-2 font-size-18">Mathias N. Klausen</h5>
                                            <p className="text-muted mb-0">pauline@admiria.com</p>
                                            <p className="text-muted font-size-14 font-weight-semibold">Creative Director</p>
                                        </div>
                                    </div>

                                    <Row className="text-center mt-4">
                                        <Col xs={6}>
                                            <h5 className="mb-0">9652</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                        <Col xs={6}>
                                            <h5 className="mb-0">7451</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                    </Row>

                                    <Sparklines data={[5, 6, 2, 8, 9, 4, 7, 10, 11, 12, 10, 4, 7, 10]}>
                                        <SparklinesLine type="line" width='100%' height='100' chartRangeMax={40} lineColor='#3bc3e9' resize={true} fillColor="rgba(74, 193, 142, 0.3)" highlightSpotColor='rgba(0,0,0,.1)' highlightLineColor="rgba(0,0,0,.2)" color="rgba(74, 193, 142, 0.3)" style={{ fillOpacity: 1 }} />
                                    </Sparklines>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4}>
                            <Card>
                                <CardBody>

                                    <div className="d-flex">
                                        <img className="me-3 rounded-circle avatar-lg" src={user4} alt="" />
                                        <div>
                                            <h5 className="mt-2 font-size-18">Nikolaj S. Henriksen</h5>
                                            <p className="text-muted mb-0">pauline@admiria.com</p>
                                            <p className="text-muted font-size-14 font-weight-semibold">Creative Director</p>
                                        </div>
                                    </div>

                                    <Row className="text-center mt-4">
                                        <Col xs={6}>
                                            <h5 className="mb-0">362</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                        <Col xs={6}>
                                            <h5 className="mb-0">151</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                    </Row>

                                    <Sparklines data={[5, 6, 2, 8, 9, 4, 7, 10, 11, 12, 10, 4, 7, 10]}>
                                        <SparklinesLine type="line" width='100%' height='100' chartRangeMax={40} lineColor='#3bc3e9' resize={true} fillColor="rgba(74, 193, 142, 0.3)" highlightSpotColor='rgba(0,0,0,.1)' highlightLineColor="rgba(0,0,0,.2)" color="rgba(74, 193, 142, 0.3)" style={{ fillOpacity: 1 }} />
                                    </Sparklines>


                                </CardBody>
                            </Card>
                        </Col>

                    </Row>


                    <div className="row">
                        <Col lg={4}>
                            <Card>
                                <CardBody>

                                    <div className="d-flex">
                                        <img className="me-3 rounded-circle avatar-lg" src={user5} alt="" />
                                        <div>
                                            <h5 className="mt-2 font-size-18">Kasper S. Jessen</h5>
                                            <p className="text-muted mb-0">pauline@admiria.com</p>
                                            <p className="text-muted font-size-14 font-weight-semibold">Creative Director</p>
                                        </div>
                                    </div>

                                    <Row className="text-center mt-4">
                                        <Col xs={6}>
                                            <h5 className="mb-0">10205</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                        <Col xs={6}>
                                            <h5 className="mb-0">3201</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                    </Row>

                                    <Sparklines data={[5, 6, 2, 8, 9, 4, 7, 10, 11, 12, 10, 4, 7, 10]}>
                                        <SparklinesLine type="line" width='100%' height='100' chartRangeMax={40} lineColor='#3bc3e9' resize={true} fillColor="rgba(74, 193, 142, 0.3)" highlightSpotColor='rgba(0,0,0,.1)' highlightLineColor="rgba(0,0,0,.2)" color="rgba(74, 193, 142, 0.3)" style={{ fillOpacity: 1 }} />
                                    </Sparklines>


                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4}>
                            <Card>
                                <CardBody>

                                    <div className="d-flex">
                                        <img className="me-3 rounded-circle avatar-lg" src={user6} alt="" />
                                        <div>
                                            <h5 className="mt-2 font-size-18">Lasse C. Overgaard</h5>
                                            <p className="text-muted mb-0">pauline@admiria.com</p>
                                            <p className="text-muted font-size-14 font-weight-semibold">Creative Director</p>
                                        </div>
                                    </div>

                                    <Row className="text-center mt-4">
                                        <Col xs={6}>
                                            <h5 className="mb-0">1254</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                        <Col xs={6}>
                                            <h5 className="mb-0">3625</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                    </Row>

                                    <Sparklines data={[5, 6, 2, 8, 9, 4, 7, 10, 11, 12, 10, 4, 7, 10]}>
                                        <SparklinesLine type="line" width='100%' height='100' chartRangeMax={40} lineColor='#3bc3e9' resize={true} fillColor="rgba(74, 193, 142, 0.3)" highlightSpotColor='rgba(0,0,0,.1)' highlightLineColor="rgba(0,0,0,.2)" color="rgba(74, 193, 142, 0.3)" style={{ fillOpacity: 1 }} />
                                    </Sparklines>


                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4}>
                            <Card>
                                <CardBody>

                                    <div className="d-flex">
                                        <img className="me-3 rounded-circle avatar-lg" src={user1} alt="" />
                                        <div>
                                            <h5 className="mt-2 font-size-18">Johan E. Knudsen</h5>
                                            <p className="text-muted mb-0">pauline@admiria.com</p>
                                            <p className="text-muted font-size-14 font-weight-semibold">Creative Director</p>
                                        </div>
                                    </div>

                                    <Row className="text-center mt-4">
                                        <Col xs={6}>
                                            <h5 className="mb-0">2562</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                        <Col xs={6}>
                                            <h5 className="mb-0">8451</h5>
                                            <p className="text-muted font-size-14">Activated</p>
                                        </Col>
                                    </Row>

                                    <Sparklines data={[5, 6, 2, 8, 9, 4, 7, 10, 11, 12, 10, 4, 7, 10]}>
                                        <SparklinesLine type="line" width='100%' height='100' chartRangeMax={40} lineColor='#3bc3e9' resize={true} fillColor="rgba(74, 193, 142, 0.3)" highlightSpotColor='rgba(0,0,0,.1)' highlightLineColor="rgba(0,0,0,.2)" color="rgba(74, 193, 142, 0.3)" style={{ fillOpacity: 1 }} />
                                    </Sparklines>


                                </CardBody>
                            </Card>
                        </Col>

                    </div>


                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const Layout = state.Layout;
    return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(Users);