import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import { connect } from "react-redux";

import { setBreadcrumbItems } from '../../store/actions';

import BackGround from '../../assets/images/bg-2.jpg';
import logo from '../../assets/images/logo.png';
import avtar from '../../assets/images/users/avatar-1.jpg';

const LockScreen = props => {
    ///breadcrumbItems

    const breadcrumbItems = [
        {
            title: 'Lock Screen',
            link: '#'
        }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Lock Screen', breadcrumbItems)
    })


    return (
        <React.Fragment>
            <div className="accountbg" style={{ background: `url(${BackGround}) center`, backgroundPosition: 'cover' }}></div>

            <div className="account-pages mt-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={4}>
                            <Card>
                                <CardBody>
                                    <div className="text-center mt-4">
                                        <div className="mb-3">
                                            <Link to="/dashboard"><img src={logo} height="30" alt="logo" /></Link>
                                        </div>
                                    </div>
                                    <div className="p-3">
                                        <h4 className="font-size-18 mt-2 text-center">Locked.</h4>
                                        <p className="text-muted text-center mb-4">Hello Smith, enter your password to unlock the screen!</p>

                                        <form className="form-horizontal" action="index.html">

                                            <div className="user-thumb text-center">
                                                <img src={avtar} className="rounded-circle img-thumbnail avatar-lg" alt="thumbnail" />
                                                <h6 className="font-size-16 mt-3">Robert Smith</h6>
                                            </div>

                                            <div className="mb-3">
                                                <label className="form-label" for="userpassword">Password</label>
                                                <input type="password" className="form-control" id="userpassword" placeholder="Enter password" />
                                            </div>

                                            <div className="mb-3">
                                                <div className="text-end">
                                                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Unlock</button>
                                                </div>
                                            </div>
                                        </form>

                                    </div>

                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center position-relative">
                                <p className="text-white">Not you ? return <Link to="/pages-login" className="font-weight-bold text-primary"> Sign In</Link> </p>
                                <p className="text-white">2017 - {new Date().getFullYear()} © Admiria. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(LockScreen);