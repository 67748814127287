import React, { useEffect } from "react"
import { connect } from "react-redux";

//Breadcrumb
import { setBreadcrumbItems } from '../../store/actions';
import Breadcrumb from '../../common/Breadcrumb';

import { Container } from 'reactstrap';

const Starter = props => {
  ///breadcrumbItems

  const breadcrumbItems = [
    {
      title: 'Blank Page',
      link: '#'
    }
  ]

  useEffect(() => {
    props.setBreadcrumbItems('Blank Page', breadcrumbItems)
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Breadcrumb */}
          {props.layoutType === 'horizontal' ?
            <Breadcrumb breadcrumbItem="Pages" />
            : null}

        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStatetoProps = state => {
  const Layout = state.Layout;
  return { layoutType: Layout.layoutType };
};

export default connect(mapStatetoProps, { setBreadcrumbItems })(Starter);